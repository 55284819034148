import tinymce from 'tinymce/tinymce';

const BornetubePlugin = function (ed) {
  const openBt = function () {
    window.addEventListener('message', btreceiveMessage, false);
    ed.windowManager.openUrl({
      title: 'Bornetube import',
      url:
        'https://www.bornetube.dk/app/bluebird/?passtype=complete&fitscale=true&urlref=' + encodeURI(window.location),
      width: 630,
      height: 540,
      buttons: [],
      onClose: () => window.removeEventListener('message', btreceiveMessage),
    });
  };

  ed.ui.registry.addIcon(
    'bticon',
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 232 324"><g data-name="Capa 4"><path class="cls-1" d="M232 79.7l-1.6-0.5c-29.6 18-67.3 33.2-101.9 45.1C155.7 183.5 184.6 254.3 201.3 319.1h0C186.7 252.4 232 79.7 232 79.7Z"/><path class="cls-2" d="M66.8 1.4c10.8 19.6 35.4 65.9 61.7 123 34.6-11.9 72.3-27 101.9-45C126.9 51.1 76.2 9.6 66.8 1.4Z"/><path class="cls-1" d="M128.5 124.4C102.3 67.3 77.6 21 66.8 1.4L65.2 0c0 55.2-58.5 148.7-64.9 158.5C26.6 153.3 77 142.1 128.5 124.4Z"/><path class="cls-3" d="M88.1 24.2l-4.2-2.8 2.1 4.3"/><line class="cls-4" x1="89.9" y1="33.5" x2="127.3" y2="108.6"/><path class="cls-3" d="M129.2 112.5l2.1 4.3 4.9-1.7"/><path class="cls-5" d="M147.2 111c15.9-6 40.4-15.6 58.9-25"/><path class="cls-3" d="M211.4 83.3c1.6-0.8 3-1.7 4.6-2.5 0 0-1.8-0.5-4.9-1.4"/><path class="cls-6" d="M200.8 76.2C177.7 68.6 131.9 52 92.4 26.9"/><path class="cls-3" d="M19.9 164.4c-1.4-1-2.8-1.7-4.4-2.7l5.1-1.3"/><path class="cls-7" d="M31.7 157.7c22.2-5.5 61.9-15.6 82.9-22.8"/><path class="cls-3" d="M119.9 133.1a31.5 31.5 0 0 0 4.8-2.1s0.7 1.6 1.9 4.4" transform="translate(-21.89 0.1)"/><path class="cls-8" d="M130.5 144.6c13.4 31.9 46.2 111.1 55.5 144.5"/><path class="cls-9" d="M178.2 287.5C158.7 267.1 109.8 220.6 24.1 167.1"/></g></svg>'
  );

  ed.ui.registry.addButton('bornetube', {
    icon: 'bticon',
    tooltip: 'Tilføj indhold fra Børnetuben',
    onAction: function () {
      openBt();
    },
  });

  ed.ui.registry.addMenuItem('bornetube', {
    text: 'Bornetube import',
    onAction: function () {
      openBt();
    },
  });

  const btreceiveMessage = function (e) {
    if (event.origin !== 'http://www.bornetube.dk' && event.origin !== 'https://www.bornetube.dk') {
      return false;
    }

    if (!/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(e.data)) {
      return false;
    }

    const content = JSON.parse(e.data);
    ed.insertContent(
      '<iframe src="' +
        content.embed +
        '" width="' +
        content.extra.width +
        '" height="' +
        content.extra.height +
        '" scrolling="no" style="overflow:hidden" frameborder="0" allowfullscreen></iframe>'
    );
    ed.windowManager.close();
  };
};

tinymce.PluginManager.add('bornetube', BornetubePlugin);
