




















































import { mediaTypes } from '../enums/mediaTypes';
import { parentTypes } from '../enums/parentTypes';
import { attachmentStatus } from '../enums/attachmentStatus.js';
import { conversionStatusTypes } from '../enums/conversionStatusTypes';
import VideoThumbnail from '../PageFragments/Media/VideoThumbnail.vue';
import SoundThumbnail from '../PageFragments/Media/SoundThumbnail.vue';
import ImageThumbnail from '../PageFragments/Media/ImageThumbnail.vue';

export default {
  components: { ImageThumbnail, SoundThumbnail, VideoThumbnail },
  props: {
    attachments: Array,
    parent: { type: String, default: null },
  },
  data() {
    return {
      mediaTypes: mediaTypes,
      parentTypes: parentTypes,
      attachmentStatus: attachmentStatus,
      lastThumbnailIndex: 3,
      conversionStatus: conversionStatusTypes,
    };
  },
  computed: {
    getAttachedMedia() {
      let attachedMedia = [];
      const onlyMediaTypes = [mediaTypes.SOUND, mediaTypes.IMAGE, mediaTypes.VIDEO];
      const onlyAttachedStatus = [attachmentStatus.PENDING, attachmentStatus.AVAILABLE];
      if (this.attachments != null && this.attachments.length > 0) {
        attachedMedia = this.attachments.filter(
          a => onlyAttachedStatus.includes(a.status) && a.media != null && onlyMediaTypes.includes(a.media.mediaType)
        );
      }
      return attachedMedia;
    },
  },
  methods: {
    downloadAttachment(attachment) {
      window.location = attachment.media.file.url;
    },
    openMedia(attachment) {
      if (attachment.media.conversionStatus === this.conversionStatus.PROCESSING) {
        return;
      }
      this.$emit('attachmentClicked', attachment.media.id);
    },
  },
};
