const maxTimeout = 2000;
const intervalCount = 50;
const waitForContainer = editor => {
  let waited = 0;
  let returnValue = false;
  return new Promise(resolve => {
    const intervalKey = setInterval(() => {
      returnValue = editor.container != null;

      if (maxTimeout < waited || returnValue) {
        resolve(returnValue);
        clearInterval(intervalKey);
      }

      waited += intervalCount;
    }, intervalCount);
  });
};

export const foldFonts = async editor => {
  const canSeeContainer = await waitForContainer(editor);
  if (canSeeContainer === false) {
    return;
  }
  const toolbarContainer = editor.container.querySelector('div.tox-editor-header > div.tox-toolbar-overlord');
  const foldFontsContainer = editor.container.querySelector('button.fold-fonts');
  const toolbarRows = toolbarContainer.children;
  const visibleValue = 'flex';
  for (let i = 1; i < toolbarRows.length; i++) {
    const toolbarRow = toolbarRows[i];
    const isRowVisible = toolbarRow.style.display === visibleValue;
    let displayValue = visibleValue;

    if (isRowVisible) {
      displayValue = '';
    }

    toolbarRow.style.display = displayValue;
  }

  if (foldFontsContainer != null) {
    if (toolbarRows[1].style.display === visibleValue) {
      foldFontsContainer.classList.add('active');
    } else {
      foldFontsContainer.classList.remove('active');
    }
  }
};
