import { portal } from '../../assets/plugins/axios/axios.js';
import { AxiosError } from 'axios';
import { HttpError } from '../../../src/business/httpError';
import { AddCommentRequest } from '../../models/requests/addComment.request';
import { GetCommentsRequest } from '../../models/requests/getComments.request';
import { CommentListModel } from '../../models/commentList.model';
import { CommentModel } from '../../models/comment.model';
import { UpdateCommentRequest } from '../../models/requests/updateComment.request';
import { DeleteCommentRequest } from '../../models/requests/deleteComment.request';
import { ReportCommentRequest } from '../../models/requests/reportComment.request';

class CommentService {
  public getComments = async (payload: GetCommentsRequest): Promise<CommentListModel> => {
    try {
      const { data } = await portal.get('?method=comments.getComments', { params: payload });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public addComment = async (payload: AddCommentRequest): Promise<CommentModel> => {
    try {
      const { data } = await portal.post('?method=comments.addComment', payload);
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public updateComment = async (payload: UpdateCommentRequest) => {
    try {
      await portal.post('?method=comments.updateComment', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public deleteComment = async (payload: DeleteCommentRequest) => {
    try {
      await portal.post('?method=comments.deleteComment', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public reportComment = async (payload: ReportCommentRequest) => {
    try {
      await portal.post('?method=comments.reportComment', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const commentService = new CommentService();
