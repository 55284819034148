<template>
  <div class="new-document">
    <aula-spinner v-if="isDocumentLoading" />
    <template v-else>
      <div v-if="readonlyMode && profile.role === portalRoles.EMPLOYEE" class="time-info-container">
        <div v-if="showEditedAt">
          <b-row class="time-info">
            {{
              'DOCUMENTS_REVISION_UPDATED_AT'
                | fromTextKey({
                  updatedDate: Vue.filter('shortDate')(workingDocument.createdAt),
                  updatedTime: Vue.filter('time')(workingDocument.createdAt),
                  updatedBy: workingDocument.creator
                    ? Vue.filter('displayProfileNameWithMetadata')(workingDocument.creator)
                    : '',
                })
            }}
          </b-row>
        </div>
        <div v-else>
          <b-row class="time-info">
            {{
              'DOCUMENTS_NEW_CREATED_AT'
                | fromTextKey({
                  createdDate: Vue.filter('shortDate')(workingDocument.createdAt),
                  createdTime: Vue.filter('time')(workingDocument.createdAt),
                  createdBy: workingDocument.creator
                    ? Vue.filter('displayProfileNameWithMetadata')(workingDocument.creator)
                    : '',
                })
            }}
          </b-row>
          <b-row class="time-info">
            {{
              'DOCUMENTS_NEW_UPDATED_AT'
                | fromTextKey({
                  updatedDate: Vue.filter('shortDate')(workingDocument.updatedAt),
                  updatedTime: Vue.filter('time')(workingDocument.updatedAt),
                  updatedBy: workingDocument.creator ? workingDocument.updatedBy : '',
                })
            }}
          </b-row>
        </div>
      </div>
      <b-row v-if="profile.role !== portalRoles.GUARDIAN">
        <b-col v-if="institutionsWithPermission.length > 1" cols="12" sm="6">
          <label>{{
            readonlyMode ? 'CALENDAR_TITLE_SELECTED_INSTITUTION' : 'CALENDAR_TITLE_CHOOSE_INSTITUTION' | fromTextKey
          }}</label
          ><br />
          <aula-select
            v-if="!readonlyMode"
            v-model="institutionCode"
            :disabled="isEditMode"
            @change="changeInstitution"
          >
            <aula-option
              v-for="institution in institutionsWithPermission"
              :id="institution.institutionCode"
              :key="institution.institutionCode"
              :label="institution.name"
              :value="institution.institutionCode"
            />
          </aula-select>
          <span v-else>
            {{
              institutions.find(i => i.institutionCode == institutionCode)
                ? institutions.find(i => i.institutionCode == institutionCode).name
                : ''
            }}</span
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3 mb-3" cols="12" sm="6">
          <label>{{ 'DOCUMENTS_NEW_LABEL_TITLE' | fromTextKey }} <span class="mandatory">*</span></label>
          <div v-if="readonlyMode" class="document-title">
            {{ workingDocument.title }}
          </div>
          <b-form-input
            v-else
            id="title"
            v-model="workingDocument.title"
            :placeholder="'DOCUMENTS_NEW_LABEL_TITLE' | fromTextKey"
            :disabled="readonlyMode"
            :class="{ 'read-only-mode': readonlyMode }"
            @change="emitChange"
            @input="errorSetTitle = false"
          />
          <b-alert variant="danger" :show="errorSetTitle">
            {{ 'DOCUMENTS_ALERT_TITLE_REQUIRED' | fromTextKey }}
          </b-alert>
        </b-col>
        <b-col v-if="showDocumentTemplateSection" class="mt-3" cols="12" sm="6">
          <b-form-group v-if="profile.role !== portalRoles.GUARDIAN" label-for="selectedTemplateId">
            <template #label>
              {{
                isUsingTemplates
                  ? 'DOCUMENTS_NEW_LABEL_CATEGORIES_AND_TEMPLATES'
                  : 'DOCUMENTS_NEW_LABEL_CATEGORY' | fromTextKey
              }}
              <span class="mandatory">*</span>
            </template>
            <span v-if="shouldDisplayCategoryText">
              <div class="mb-2">
                <b>{{ workingDocument.category }}</b>
              </div>
              <div>
                {{ workingDocument.documentTemplateTitle }}
              </div>
            </span>
            <aula-select
              v-else-if="isUsingTemplates"
              v-model="selectedTemplateId"
              :disabled="isLoadingDocumentTemplates"
              @change="onSelectTemplate"
            >
              <el-option-group
                v-for="(categoryDto, categoryKey) in enabledDocumentCategoriesAndTemplates"
                :key="categoryKey"
                :label="categoryDto.documentCategory"
              >
                <template v-for="(documentTemplate, documentKey) in categoryDto.documentTemplates">
                  <aula-option
                    :key="`${categoryKey}-${documentKey}`"
                    :label="documentTemplate.metadata.title"
                    :value="documentTemplate.metadata.id"
                  />
                </template>
              </el-option-group>
            </aula-select>
            <aula-select
              v-else-if="profile.role === portalRoles.EMPLOYEE"
              v-model="selectedCategory"
              :disabled="isLoadingDocumentTemplates"
              @change="onSelectCategory"
            >
              <template v-for="(value, key) in enabledDocumentCategories">
                <aula-option :key="key" :label="value" :value="value" />
              </template>
            </aula-select>
            <b-alert variant="danger" :show="isMissingTemplate">
              {{ 'DOCUMENTS_ALERT_TEMPLATE_REQUIRED' | fromTextKey }}
            </b-alert>
            <b-alert variant="danger" :show="isMissingCategory">
              {{ 'DOCUMENTS_ALERT_DOCUMENT_CATEGORY_REQUIRED' | fromTextKey }}
            </b-alert>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group>
            <label>
              {{ 'DOCUMENTS_NEW_LABEL_GRUPPE' | fromTextKey }}
              <span class="mandatory">*</span>
            </label>
            <help-box
              id="secure-file-association-tooltip"
              :title="$options.filters.fromTextKey('DOCUMENTS_NEW_LABEL_ASSOCIATION')"
              :body="
                $options.filters.fromTextKey('HELP_BOX_ASSOCIATION_1') +
                '<br>' +
                $options.filters.fromTextKey('HELP_BOX_ASSOCIATION_2') +
                '<br>'
              "
              :is-html-content="true"
            /><br />
            <div v-if="readonlyMode && workingDocument.associatedInstitutionProfiles" class="regarding-child-list">
              {{
                workingDocument.associatedInstitutionProfiles
                  .map(child => child.name + ' (' + child.metadata + ')')
                  .join(', ')
              }}
            </div>
            <aula-search-recipients
              v-else
              id="document-regarding-children"
              :reset-input="resetChosenRegardingChildren"
              :include-choosen-in-search="true"
              :existing-participants="associatedChildren"
              :invite-members-when-selecting-group-portal-role="true"
              :invite-group-when-adding-members="true"
              :css-class="errorChildrenFromDifferentInstitutions ? 'red-border' : ''"
              :show-search-icon="true"
              :close-dropdown-after-select="false"
              :only-unique-profiles="true"
              :select-all="false"
              :hide-portal-roles-when-expanding-group="[portalRoles.GUARDIAN, portalRoles.EMPLOYEE]"
              :extra-a-p-i-params="{
                institutionCodes: [workingDocument.institutionCode],
              }"
              :from-module="moduleTypes.SECURE_DOCUMENT"
              url="findProfilesAndGroupsToAssociateDocument"
              @emitSelected="appendChildren"
            />
            <div>
              <b-alert variant="danger" :show="errorChildrenFromDifferentInstitutions">
                {{ 'DOCUMENTS_ALERT_CHILDREN_FROM_DIFFERENT_INSTITUTIONS' | fromTextKey }}
              </b-alert>
            </div>
          </b-form-group>
          <b-alert variant="danger" :show="errorSetAssociation">
            {{ 'DOCUMENTS_ALERT_ASSOCIATION_REQUIRED' | fromTextKey }}
          </b-alert>
        </b-col>
        <b-col cols="12" sm="6">
          <b-row v-if="currentShared.length > 0 || implicitSharings.length > 0 || !readonlyMode">
            <b-col cols="8">
              <label>
                {{ 'DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_SHARED_WITH' | fromTextKey }}
              </label>
              <help-box
                id="secure-file-share-with-tooltip"
                :title="$options.filters.fromTextKey('DOCUMENTS_IMPORT_FILE_LABEL_DOCUMENT_SHARED_WITH')"
                :body="
                  $options.filters.fromTextKey('HELP_BOX_SHARED_WITH_1') +
                  '<br>' +
                  $options.filters.fromTextKey('HELP_BOX_SHARED_WITH_2') +
                  '<br>' +
                  $options.filters.fromTextKey('HELP_BOX_SHARED_WITH_3') +
                  '<br>' +
                  $options.filters.fromTextKey('HELP_BOX_SHARED_WITH_4')
                "
                :is-html-content="true"
              />
            </b-col>
            <b-col v-if="readonlyMode" cols="3" class="text-center text-nowrap can-edit-title">
              {{ 'DOCUMENTS_SHARE_FOLDER_MODAL_CAN_EDIT' | fromTextKey }}
            </b-col>
          </b-row>
          <aula-search-recipients
            v-if="!readonlyMode"
            id="document-shared-with-group"
            :select-all="false"
            url="findProfilesAndGroupsToShareDocument"
            :enable-relatives-for-students="false"
            :close-dropdown-after-select="false"
            :show-search-icon="true"
            :from-module="moduleTypes.SECURE_DOCUMENT"
            :invite-members-when-selecting-group-portal-role="true"
            :disable-portal-roles-members-when-expanding-group="[portalRoles.GUARDIAN, portalRoles.CHILD]"
            :hide-portal-roles-when-expanding-group="[portalRoles.GUARDIAN, portalRoles.CHILD]"
            :reset-input="resetSharedWithInput"
            :existing-participants="appendSharedUsersAndGroupsSet"
            :extra-a-p-i-params="{
              regardingChildren: workingDocument.regardingInstitutionProfileIds,
              institutionCodes: [institutionCode],
            }"
            :include-self="true"
            :selected-profile-ids-for-checkbox="explicitSharingProfileIds"
            @emitSelected="appendSharedUsersAndGroups"
          />
          <b-row v-if="(currentShared.length > 0 || implicitSharings.length > 0) && !readonlyMode">
            <b-col cols="8" />
            <b-col cols="3" class="text-center text-nowrap can-edit-title">
              {{ 'DOCUMENTS_SHARE_FOLDER_MODAL_CAN_EDIT' | fromTextKey }}
            </b-col>
          </b-row>
          <b-row v-if="currentShared.length > 0" class="mb-2">
            <b-col>
              <b>{{ 'DOCUMENTS_SHARE_SHARING' | fromTextKey }}</b>
            </b-col>
          </b-row>
          <template v-for="(sharedItem, index) in currentShared">
            <DocumentSharing
              :key="`explicit-${index}`"
              class="pb-3"
              :name="sharedItem.displayName || sharedItem.groupName"
              :readonly="readonlyMode"
              :checked="sharedItem.canEdit"
              :can-edit="sharedItem.role !== portalRoles.GUARDIAN"
              @change="onExplicitEditPermissionChanged(sharedItem, $event)"
              @remove="onExplicitSharingRemoved(sharedItem)"
            />
          </template>
          <b-row v-if="implicitSharings.length > 0" class="mb-2">
            <b-col>
              <b>{{ 'DOCUMENTS_SHARE_AUTO_SHARING' | fromTextKey }}</b>
            </b-col>
          </b-row>
          <template v-for="implicitSharing in implicitSharings">
            <DocumentSharing
              :key="`implicit-${implicitSharing.simpleInstitutionProfileDto.id}`"
              class="pb-3"
              :name="implicitSharing.simpleInstitutionProfileDto | displayProfileNameWithMetadata"
              :readonly="readonlyMode"
              :checked="implicitSharing.permissionOverrideEnum === permissionOverrideEnum.WRITE"
              @change="onImplicitEditPermissionChanged(implicitSharing.simpleInstitutionProfileDto, $event)"
              @remove="onImplicitSharingRemoved(implicitSharing.simpleInstitutionProfileDto)"
            />
          </template>
        </b-col>
      </b-row>
      <b-row
        v-if="
          isInternalFile || isDocumentNote || (readonlyMode && $route.params.documentType == documentTypes.RICHDOCUMENT)
        "
        class="mt-3"
      >
        <b-col>
          <label>{{ 'DOCUMENTS_NEW_LABEL_TEXT' | fromTextKey }}</label>
          <editor
            v-if="!readonlyMode"
            :key="editorKey"
            v-model="workingDocument.content.html"
            :toolbar-type="isDocumentNote ? 'note' : 'extended'"
            :max-length="400000"
            :aria-label="'ARIA_LABEL_WRITE_DOCUMENT_CONTENT'"
            :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
            :disabled="selectedTemplateId == null && selectedCategory == null"
            :has-emojis="false"
            @input="onEditorInput"
          />
          <div v-else-if="workingDocument.content.html" class="document-content-wrapper">
            <div class="document-content" v-html="workingDocument.content.html" />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col v-if="readonlyMode" cols="12" class="upload-area">
          <attachment-thumbnails :attachments="fileAttachments" :parent="parentTypes.DOCUMENTS" />
          <attachment-list :attachments="fileAttachments" :show-media="true" />
        </b-col>
        <b-col v-else cols="12" class="upload-area">
          <upload
            v-if="isInternalFile || isDocumentNote"
            ref="internalImportFile"
            :selected-institution-code-for-filter="institutionCode"
            :existing-media="attachments"
            :parent="parentTypes.DOCUMENTS"
            :limit-file-upload="200"
            @mediaUpdated="filesChanged"
          />
          <upload
            v-if="isExternalFile"
            ref="externalImportFile"
            :existing-media="attachments"
            :media-removed-outside="mediaToRemove"
            :parent="parentTypes.DOCUMENTS"
            :limit-file-upload="1"
            :upload-from-gallery="false"
            :show-attach-from-google-drive="false"
            :show-attach-from-one-drive="false"
            @mediaUpdated="filesChanged"
          />
          <b-alert variant="danger" :show="errorMissingDoc">
            {{ 'DOCUMENTS_ALERT_FILE_REQUIRED' | fromTextKey }}
          </b-alert>
        </b-col>
      </b-row>
      <aula-form-footer
        :is-loading="isSaving"
        :show-cancel="!readonlyMode"
        :submit-text="readonlyMode ? 'BUTTON_CLOSE' : 'BUTTON_SAVE'"
        @cancelClicked="changeDrawerShow(false)"
        @submitClicked="onSubmitClicked"
      />
    </template>
    <aula-modal
      ref="modalConfirmOverride"
      class="modalConfirmOverride"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.modalConfirmOverride.hide()"
      @okClicked="overrideDocument()"
    >
      <div class="container-scroll">
        {{ 'DOCUMENTS_SECURE_OVERRIDE_TEXT_1' | fromTextKey }} <br />
        {{ 'DOCUMENTS_SECURE_OVERRIDE_TEXT_2' | fromTextKey }}
      </div>
    </aula-modal>
    <aula-modal
      ref="warningShareWithDifferentInstitution"
      :show-cancel="false"
      ok-text="YES"
      @okClicked="$refs.warningShareWithDifferentInstitution.hide()"
    >
      {{ 'DOCUMENTS_ALERT_SHARE_WITH_DIFFERENT_INSTITUTION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningRemovingOwnSharing"
      @okClicked="removeOwnSharing"
      @cancelClicked="$refs.warningRemovingOwnSharing.hide()"
    >
      {{ 'DOCUMENTS_ALERT_REMOVE_OWN_SHARING' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningExceedingMaxChildren"
      :show-cancel="false"
      ok-text="OK"
      @okClicked="$refs.warningExceedingMaxChildren.hide()"
    >
      {{ 'DOCUMENTS_ALERT_EXCEED_MAX_CHILDREN' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningUnshareableGuardians"
      :show-cancel="false"
      ok-text="OK"
      @okClicked="
        $refs.warningUnshareableGuardians.hide();
        unshareableGuardians = [];
      "
    >
      {{ 'DOCUMENTS_ALERT_UNSHAREABLE_GUARDIANS' | fromTextKey }}
      <ul>
        <li v-for="(item, index) in unshareableGuardians" :key="index">
          {{ item.displayName }}
        </li>
      </ul>
      {{ 'DOCUMENTS_ALERT_UNSHAREABLE_GUARDIANS_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningChangingTemplate"
      @okClicked="onConfirmChangingTemplate"
      @cancelClicked="
        selectedTemplateId = previousTemplateId;
        $refs.warningChangingTemplate.hide();
      "
    >
      {{ 'DOCUMENTS_ALERT_CHANGING_TEMPLATE' | fromTextKey }}<br />
      {{ 'DOCUMENTS_ALERT_CHANGING_TEMPLATE_QUESTION' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="warningImplicitSharedRemoved"
      ok-text="YES"
      @okClicked="onConfirmImplicitSharedRemoved"
      @cancelClicked="$refs.warningImplicitSharedRemoved.hide()"
    >
      {{ 'DOCUMENT_REMOVE_IMPLICIT_SHARING_WARNING_1' | fromTextKey }}<br />
      {{ 'DOCUMENT_REMOVE_IMPLICIT_SHARING_WARNING_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import Editor from '../../../shared/components/Editor';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { docTypes } from '../../../shared/enums/docTypes';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { documentCategories } from '../../../shared/enums/documentCategories';
import { documentTypes } from '../../../shared/enums/documentTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import upload from '../../../shared/components/Upload.vue';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import AttachmentList from '../../../shared/components/AttachmentList';
import AttachmentThumbnails from '../../../shared/components/AttachmentThumbnails';
import { sensitivityLevel } from '../../../shared/enums/sensitivityLevel';
import { getDefaultInstitutionCode } from '../../../shared/utils/institutionUtil';
import DocumentSharing from './DocumentSharing';
import { permissionOverrideEnum } from '../../../shared/enums/permissionOverrideEnum';

export default {
  props: {
    documentId: { type: Number, default: null },
    documentType: { type: String, default: null },
    isEditMode: { type: Boolean, default: false },
    includesDeleted: { type: Boolean, default: false },
    showTemplate: { type: Boolean, default: false },
    showEditedAt: { type: Boolean, default: false },
  },
  data: function () {
    return {
      hasDocumentChanged: false,
      isDocumentLoading: false,
      resetChosenRegardingChildren: false,
      attachments: [],
      isLoading: true,
      appendedGroup: [],
      parentTypes: parentTypes,
      permissionEnum: permissionEnum,
      sensitivityLevel: sensitivityLevel,
      portalRoles: portalRoles,
      categoriesList: documentCategories,
      association: '',
      docTypes: docTypes,
      moduleTypes: moduleTypes,
      documentTypes: documentTypes,
      mediaTypes: mediaTypes,
      selectedCategory: null,
      selectedTemplateId: null,
      previousTemplateId: null,
      currentShared: [],
      appendSharedUsersAndGroupsSet: [],
      isSaving: false,
      workingDocument: {
        institutionCode: null,
        regardingInstitutionProfileIds: [],
        content: { html: '' },
      },
      institutionCode: null,
      implicitSharings: [],
      errorMissingDoc: false,
      errorSetAssociation: false,
      errorGroupNotInInstitution: false,
      associatedChildren: [],
      currentChoosenGroupName: null,
      errorSetTitle: false,
      isMissingTemplate: false,
      isMissingCategory: false,
      errorChildrenFromDifferentInstitutions: false,
      mediaToRemove: null,
      singleStatus: null,
      currentChoosenGroup: null,
      resetSharedWithInput: false,
      sharedWithGroup: {},
      limitChildrenNumber: 50,
      unshareableGuardians: [],
      editorKey: 0,
      isEditorContentChanged: false,
      permissionOverrideDict: {},
      permissionOverrideEnum,
      Vue: Vue,
    };
  },
  computed: {
    ...mapGetters({
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      liveDocument: types.GET_DOCUMENTS_LIVE,
      hasPermission: types.HAS_PERMISSION,
      institutions: types.GET_INSTITUTIONS,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      isMobile: types.GET_IS_MOBILE,
      userGroups: types.GET_USER_GROUPS,
      groupMemberships: types.GET_GROUP_MEMBERSHIPS_LIGHT,
      documentCategoriesAndTemplates: types.GET_DOCUMENT_CATEGORIES_AND_TEMPLATES,
      isLoadingDocumentTemplates: types.GET_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING,
    }),
    fileAttachments() {
      if (this.workingDocument.attachment) {
        return [this.workingDocument.attachment];
      }

      return this.workingDocument.attachments;
    },
    showDocumentTemplateSection() {
      return !this.isDocumentNote;
    },
    shouldDisplayCategoryText() {
      if (this.isEditMode && this.isInternalFile) {
        return true;
      }
      return this.readonlyMode === true;
    },
    isUsingTemplates() {
      return (
        this.isExternalFile === false &&
        (this.enabledDocumentCategoriesAndTemplates.length > 0 || this.workingDocument.documentTemplateTitle != null)
      );
    },
    enabledDocumentCategoriesAndTemplates() {
      const categoriesAndTemplates = [];
      for (const documentCategory of this.documentCategoriesAndTemplates) {
        const documentTemplates = [];
        for (const documentTemplate of documentCategory.documentTemplates) {
          if (documentTemplate.metadata.isEnabled) {
            documentTemplates.push(documentTemplate);
          }
        }
        if (documentTemplates.length > 0) {
          categoriesAndTemplates.push({
            ...documentCategory,
            documentTemplates,
          });
        }
      }
      return categoriesAndTemplates;
    },
    enabledDocumentCategories() {
      if (!this.isUsingTemplates || this.enabledDocumentCategoriesAndTemplates.length === 0) {
        const documentCategories = [];
        for (const key in this.categoriesList) {
          const category = this.categoriesList[key];
          if (category !== this.categoriesList.SECURE_NOTE) {
            documentCategories.push(category);
          }
        }
        return documentCategories;
      }
      return this.enabledDocumentCategoriesAndTemplates.map(
        documentCategoryAndTemplate => documentCategoryAndTemplate.documentCategory
      );
    },
    explicitSharingProfileIds() {
      return this.currentShared.map(user => user.profileId);
    },
    institutionsWithPermission() {
      return this.institutions.filter(inst =>
        this.hasPermissionOnInstitution(permissionEnum.HANDLE_SECURE_FILES, inst.institutionCode)
      );
    },
    readonlyMode() {
      return !this.isEditMode && this.documentId != null;
    },
    isInternalFile() {
      return this.documentType === documentTypes.INTERNAL || this.documentType === documentTypes.RICHDOCUMENT;
    },
    isExternalFile() {
      return this.documentType === documentTypes.EXTERNAL;
    },
    isDocumentNote() {
      return this.documentType === documentTypes.NOTE;
    },
    documentTemplates() {
      return this.documentCategoriesAndTemplates.map(item => item.documentTemplates).flat();
    },
  },
  methods: {
    ...mapActions({
      createDocument: types.ACTIONS_DOCUMENTS_SECURE_INTERNAL_CREATE,
      updateDocument: types.ACTIONS_DOCUMENTS_SECURE_INTERNAL_UPDATE,
      createDocumentExternal: types.ACTIONS_DOCUMENTS_SECURE_EXTERNAL_CREATE,
      updateDocumentExternal: types.ACTIONS_DOCUMENTS_SECURE_EXTERNAL_UPDATE,
      editSetupDocument: types.ACTION_LOAD_INTERNAL_DOCUMENT,
      editSetupExternal: types.ACTION_LOAD_EXTERNAL_DOCUMENT,
      setDocument: types.ACTION_DOCUMENTS_SET_LIVE,
      createAttachments: types.CREATE_ATTACHMENTS,
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS_LIGHT,
      loadSingleRevision: types.ACTIONS_GET_DOCUMENT_REVISION,
      loadImplicitSharings: types.LOAD_IMPLICIT_SHARINGS,
      getDocumentCategoriesAndTemplates: types.ACTION_GET_DOCUMENT_CATEGORIES_AND_TEMPLATES,
    }),
    ...mapMutations({
      setErrorText: types.MUTATE_ERROR_TEXT,
    }),
    emitChange() {
      this.$emit('change', this.workingDocument);
    },
    onSubmitClicked() {
      if (this.readonlyMode) {
        this.changeDrawerShow(false);
        return;
      }

      const hasImplicitSharesRemoved = Object.values(this.permissionOverrideDict).includes(
        permissionOverrideEnum.NO_ACCESS
      );
      if (hasImplicitSharesRemoved) {
        this.$refs.warningImplicitSharedRemoved.show();
        return;
      }

      this.prepareDocument();
    },
    onConfirmImplicitSharedRemoved() {
      this.$refs.warningImplicitSharedRemoved.hide();
      this.prepareDocument();
    },
    onExplicitEditPermissionChanged(sharedItem, value) {
      sharedItem.canEdit = value;
    },
    onImplicitEditPermissionChanged(institutionProfile, value) {
      this.permissionOverrideDict[institutionProfile.id] = value
        ? permissionOverrideEnum.WRITE
        : permissionOverrideEnum.READ;
    },
    onEditorInput() {
      this.isEditorContentChanged = true;
      this.emitChange();
    },
    onConfirmChangingTemplate() {
      this.setDocumentCategoryByTemplate();
      this.isEditorContentChanged = false;
      this.$refs.warningChangingTemplate.hide();
    },
    onExplicitSharingRemoved(explicitSharing) {
      if (explicitSharing.profileId === parseInt(this.profile.id)) {
        this.$refs.warningRemovingOwnSharing.show();
      } else {
        const selectedIndex = this.currentShared.findIndex(profile => profile === explicitSharing);
        this.currentShared.splice(selectedIndex, 1);
      }
    },
    onImplicitSharingRemoved(institutionProfile) {
      this.permissionOverrideDict[institutionProfile.id] = permissionOverrideEnum.NO_ACCESS;
      const selectedIndex = this.implicitSharings.findIndex(
        item => item.simpleInstitutionProfileDto.id === institutionProfile.id
      );
      this.implicitSharings.splice(selectedIndex, 1);
    },
    removeOwnSharing() {
      this.currentShared = this.currentShared.filter(sharedUser => sharedUser.profileId != this.profile.id);
      this.$refs.warningRemovingOwnSharing.hide();
    },
    onSelectCategory() {
      this.isMissingCategory = false;
      this.emitChange();
    },
    onSelectTemplate() {
      if (this.isEditorContentChanged) {
        this.$refs.warningChangingTemplate.show();
        return;
      }
      this.setDocumentCategoryByTemplate();
    },
    setDocumentCategoryByTemplate() {
      const selectedTemplate = this.documentTemplates.find(
        template => template.metadata.id === this.selectedTemplateId
      );
      this.isMissingTemplate = false;
      this.$nextTick(() => {
        this.selectedCategory = selectedTemplate.metadata.documentCategory;
        this.workingDocument.content.html = selectedTemplate.content.html;
        this.editorKey = selectedTemplate.metadata.id;
        this.emitChange();
      });
    },
    filesChanged(attachments) {
      let uploadedMedia = false;
      const removeMediaFiles = [];
      if (this.profile.communicationBlock) {
        const deniedMediaTypesForBlockedCommunicationUser = [mediaTypes.SOUND, mediaTypes.VIDEO, mediaTypes.IMAGE];
        let index = 0;
        for (const attachment of attachments) {
          if (
            attachment.media != null &&
            deniedMediaTypesForBlockedCommunicationUser.includes(attachment.media.mediaType)
          ) {
            uploadedMedia = true;
            removeMediaFiles.push(index);
          }
          index++;
        }
      }
      if (uploadedMedia) {
        this.attachments = [];
        if (this.isInternalFile) {
          this.$refs.internalImportFile.removeFiles(removeMediaFiles);
        }
        if (this.isExternalFile) {
          this.$refs.externalImportFile.removeFiles(removeMediaFiles);
        }
        this.$refs.warningblockedCommunication.show();
        this.emitChange();
      } else {
        this.attachments = attachments;
        this.errorMissingDoc = false;
      }
    },
    resetView() {
      this.errorMissingDoc = false;
      this.errorSetAssociation = false;
      this.errorSetTitle = false;
      this.isMissingTemplate = false;
      this.isMissingCategory = false;
      this.isSaving = false;
      this.resetChosenRegardingChildren = !this.resetChosenRegardingChildren;
      this.currentChoosenGroup = null;
      this.workingDocument = {
        content: { html: '' },
        regardingInstitutionProfileIds: [],
      };
      this.implicitSharings = [];
      this.currentShared = [];
      this.attachments = [];
      this.appendedGroup = [];
      this.appendSharedUsersAndGroupsSet = [];
      this.associatedChildren = [];
      this.workingDocument.content = { html: '' };
      this.attachments = [];
      this.setDefaultCategory();
    },
    validateForm() {
      let noerror = true;
      if (this.workingDocument.documentType == this.documentTypes.EXTERNAL) {
        if (this.attachments.length == 0) {
          noerror = false;
          this.errorMissingDoc = true;
        }
      }
      if (!this.workingDocument.title) {
        noerror = false;
        this.errorSetTitle = true;
      }

      if (this.showTemplate && this.isUsingTemplates && !this.selectedTemplateId) {
        noerror = false;
        this.isMissingTemplate = true;
      }

      if (this.selectedCategory == null && !this.isUsingTemplates) {
        noerror = false;
        this.isMissingCategory = true;
      }

      if (this.workingDocument.regardingInstitutionProfileIds.length == 0) {
        noerror = false;
        this.errorSetAssociation = true;
      }
      if (this.workingDocument.regardingInstitutionProfileIds.length > this.limitChildrenNumber) {
        noerror = false;
        this.$refs.warningExceedingMaxChildren.show();
      }

      noerror &&= !this.errorChildrenFromDifferentInstitutions;
      return noerror;
    },
    resetExternalFileIfNoConsent() {
      this.attachments = [];
      this.mediaToRemove = 0;
      this.errorMissingDoc = true;
      this.isSaving = false;
    },
    changeDrawerShow(show) {
      if (!show) {
        this.$emit('closeDocumentDrawer');
      }
    },
    overrideDocument() {
      this.workingDocument.forceUpdate = true;
      this.increaseDocumentVersion();
      this.saveDocument();
      this.$refs.modalConfirmOverride.hide();
    },
    increaseDocumentVersion() {
      if (this.workingDocument != null) {
        const currentVersion = parseInt(this.workingDocument.version, 10);

        if (typeof currentVersion === 'number') {
          this.workingDocument.version = currentVersion + 1;
        }
      }
    },
    setImplicitSharingOverrides() {
      const implicitSharingOverrides = Object.keys(this.permissionOverrideDict).map(permissionKey => ({
        institutionProfileId: parseInt(permissionKey),
        permissionOverrideEnum: this.permissionOverrideDict[permissionKey],
      }));
      this.workingDocument.implicitSharingOverrides = implicitSharingOverrides;
    },
    setDocumentCreator() {
      if (this.workingDocument.creator) {
        this.workingDocument.creatorInstitutionProfileId = this.workingDocument.creator.id;
      } else {
        if (
          this.institutions.find(inst =>
            this.hasPermissionOnInstitution(permissionEnum.HANDLE_SECURE_FILES, inst.institutionCode)
          ) != null
        ) {
          this.workingDocument.creatorInstitutionProfileId = this.institutions.find(inst =>
            this.hasPermissionOnInstitution(permissionEnum.HANDLE_SECURE_FILES, inst.institutionCode)
          ).institutionProfileId;
        }
      }
    },
    prepareDocument() {
      this.isSaving = true;
      this.setImplicitSharingOverrides();
      this.setDocumentCreator();

      let saveDoc = true;
      if (!this.validateForm()) {
        saveDoc = false;
      }

      this.workingDocument.sharedWithInstitutionProfiles = [];
      this.workingDocument.sharedWithGroups = [];

      for (const entry of this.currentShared) {
        if (entry.profileId) {
          this.workingDocument.sharedWithInstitutionProfiles.push({
            institutionProfileId: entry.profileId,
            canEdit: entry.canEdit,
          });
        } else {
          this.workingDocument.sharedWithGroups.push({
            groupId: entry.groupId,
            canEdit: entry.canEdit,
          });
        }
      }

      if (saveDoc) {
        const selectedTemplate = this.documentTemplates.find(
          template => template.metadata.id == this.selectedTemplateId
        );
        this.workingDocument.documentTemplateId = this.selectedTemplateId;
        this.workingDocument.documentTemplateTitle = selectedTemplate == null ? null : selectedTemplate.metadata.title;
        this.workingDocument.category = this.selectedCategory;
        const attachments = Vue.filter('prepareAttachments')(this.attachments);

        if (attachments.media != null || attachments.files != null || attachments.links != null) {
          if (
            this.institutions.find(
              inst => inst.institutionProfileId == this.workingDocument.creatorInstitutionProfileId
            ) != null
          ) {
            attachments.ownerInstitutionProfileId = this.workingDocument.creatorInstitutionProfileId;
            attachments.institutionCode = this.institutions.find(
              inst => inst.institutionProfileId == this.workingDocument.creatorInstitutionProfileId
            ).institutionCode;
          } else {
            attachments.ownerInstitutionProfileId = this.profile.id;
            attachments.institutionCode = this.profile.institutionCode;
          }

          this.createAttachments(attachments)
            .then(rsp => {
              const allAttachments = rsp.files.concat(rsp.links).concat(rsp.media).concat(attachments.existingMedia);

              if (this.workingDocument.documentType == documentTypes.EXTERNAL) {
                if (allAttachments.length > 0) {
                  this.workingDocument.externalFile = {
                    id: allAttachments[0].id,
                  };
                } else {
                  this.resetExternalFileIfNoConsent();
                  saveDoc = false;
                }
              } else {
                this.workingDocument.attachmentIds = allAttachments.map(att => att.id);
              }
              if (saveDoc) {
                this.saveDocument();
              }
            })
            .catch(() => {
              this.isSaving = false;
            });
        } else {
          if (this.workingDocument.documentType == documentTypes.EXTERNAL) {
            if (attachments.existingMedia.length > 0) {
              this.workingDocument.externalFile = {
                id: attachments.existingMedia[0].id,
              };
            } else {
              this.resetExternalFileIfNoConsent();
              saveDoc = false;
            }
          } else {
            this.workingDocument.attachmentIds = attachments.existingMedia.map(att => att.id);
          }
          if (saveDoc) {
            this.saveDocument();
          }
        }
      } else {
        this.isSaving = false;
      }
    },
    checkForUnshareableGuardians(unshareableGuardianIds = []) {
      this.unshareableGuardians = this.currentShared.filter(
        user => unshareableGuardianIds.includes(user.profileId) && user.role === portalRoles.GUARDIAN
      );
      if (this.unshareableGuardians.length > 0) {
        this.$refs.warningUnshareableGuardians.show();
      }
    },
    saveSecureDocument() {
      if (
        (this.workingDocument.documentType === documentTypes.RICHDOCUMENT ||
          this.workingDocument.documentType === documentTypes.NOTE) &&
        this.workingDocument.content.html != null
      ) {
        this.workingDocument.content = this.workingDocument.content.html;
      }
      this.isSaving = true;
      if (this.workingDocument.id == undefined) {
        this.createDocument()
          .then(() => {
            this.isSaving = false;
            this.$emit('save');
            this.$emit('closeDocumentDrawer');
          })
          .catch(unshareableGuardianIds => {
            this.isSaving = false;
            this.checkForUnshareableGuardians(unshareableGuardianIds);
          });
      } else {
        this.updateDocument()
          .then(response => {
            if (!response.data.data) {
              this.$refs.modalConfirmOverride.show();
              this.workingDocument.content = {
                html: this.workingDocument.content,
              };
            } else {
              this.$emit('save');
              this.$emit('closeDocumentDrawer');
            }
            this.isSaving = false;
          })
          .catch(unshareableGuardianIds => {
            this.isSaving = false;
            this.checkForUnshareableGuardians(unshareableGuardianIds);
          });
      }
    },
    saveImportDocument() {
      this.isSaving = true;
      if (this.workingDocument.id == undefined) {
        this.createDocumentExternal()
          .then(() => {
            this.isSaving = false;
            this.$emit('save');
            this.$emit('closeDocumentDrawer');
          })
          .catch(unshareableGuardianIds => {
            this.isSaving = false;
            this.checkForUnshareableGuardians(unshareableGuardianIds);
          });
      } else {
        this.updateDocumentExternal()
          .then(response => {
            if (!response.data.data) {
              this.$refs.modalConfirmOverride.show();
              this.workingDocument.content = {
                html: this.workingDocument.content,
              };
            } else {
              this.$emit('save');
              this.$emit('closeDocumentDrawer');
            }
            this.isSaving = false;
          })
          .catch(unshareableGuardianIds => {
            this.isSaving = false;
            this.checkForUnshareableGuardians(unshareableGuardianIds);
          });
      }
    },
    saveDocument() {
      this.setDocument(this.workingDocument).then(() => {
        if (this.workingDocument.documentType == documentTypes.EXTERNAL) {
          this.saveImportDocument();
        } else {
          this.saveSecureDocument();
        }
      });
    },
    setAssociatedChildren(associatedInstitutionProfiles) {
      for (const item of associatedInstitutionProfiles) {
        const childName = Vue.filter('displayProfileNameWithMetadata')(item);
        const associatedChild = {
          id: item.id,
          value: 'profile' + item.id + this.workingDocument.institutionCode,
          label: childName,
          name: childName,
        };
        this.associatedChildren.push(associatedChild);
      }
      this.workingDocument.regardingInstitutionProfileIds = this.associatedChildren.map(c => c.id);
    },
    setDefaultCategory() {
      if (this.workingDocument.category == null) {
        if (this.isDocumentNote) {
          this.workingDocument.category = documentCategories.SECURE_NOTE;
        }
      }
      this.selectedCategory = this.workingDocument.category;
    },
    setDocumentSharedWith() {
      this.currentShared = [];
      for (const item of this.workingDocument.sharedWithGroups) {
        const groupMetadata = ` (${Vue.filter('fromTextKey')('EMPLOYEES')})`;
        const sharedWithGroup = {
          groupId: item.id,
          displayName: item.name + groupMetadata,
          canEdit: item.canEdit,
          role: item.role,
        };
        this.currentShared.push(sharedWithGroup);
      }
      for (const item of this.workingDocument.sharedWithInstitutionProfiles) {
        const sharedWithProfile = {
          profileId: item.id,
          displayName: Vue.filter('displayProfileNameWithMetadata')(item),
          canEdit: item.canEdit,
          role: item.role,
        };
        this.currentShared.push(sharedWithProfile);
      }
    },
    changeInstitution() {
      if (this.institutionCode != this.workingDocument.institutionCode) {
        this.workingDocument.institutionCode = this.institutionCode;
        this.workingDocument.regardingInstitutionProfileIds = [];
        this.implicitSharings = [];
        this.workingDocument.content.html = '';
        this.editorKey = 0;
        this.resetChosenRegardingChildren = !this.resetChosenRegardingChildren;
      }
      this.initDocumentCategoriesAndTemplates();
    },
    appendChildren(children) {
      this.resetSharedWithInput = false;
      this.workingDocument.regardingInstitutionProfileIds = [];
      this.errorChildrenFromDifferentInstitutions = this.checkForDifferentChildInstitutions(children);

      if (children.length > 0) {
        this.errorSetAssociation = false;
        this.workingDocument.regardingInstitutionProfileIds = children
          .filter(child => child.type !== docTypes.GROUP.toLowerCase())
          .map(child => child.id);
        if (this.workingDocument.regardingInstitutionProfileIds.length > this.limitChildrenNumber) {
          this.$refs.warningExceedingMaxChildren.show();
        } else if (this.workingDocument.regardingInstitutionProfileIds.length > 0) {
          this.loadImplicitSharings({
            childIds: this.workingDocument.regardingInstitutionProfileIds,
            documentId: this.workingDocument.id,
          }).then(data => {
            this.implicitSharings = data.implicitSharings;
          });
        }
      } else {
        this.implicitSharings = [];
      }
      this.emitChange();
    },
    appendSharedUsersAndGroups(users) {
      if (users.length > 0) {
        for (const user of users) {
          if (user.type == docTypes.PROFILE.toLowerCase()) {
            if (this.currentShared.filter(e => e.profileId == user.id).length == 0) {
              this.currentShared.push({
                profileId: user.id,
                canEdit: false,
                displayName: user.label,
                shortName: null,
                role: user.portalRole,
              });
            }
            if (
              !user.fromGroup &&
              !this.institutions.find(institution => institution.institutionCode == user.institutionCode) &&
              !this.institutions.find(institution => institution.municipalityCode == user.municipalityCode)
            ) {
              this.$refs.warningShareWithDifferentInstitution.show();
            }
          }
        }
      }
      this.appendSharedUsersAndGroupsSet = [];
      this.resetSharedWithInput = !this.resetSharedWithInput;
    },
    noPermissionToViewDocument() {
      this.isDocumentLoading = false;
      this.setErrorText('NO_PERMISSION_TO_VIEW_SECURE_SINGLE_DOCUMENT');
      this.$router.push({ name: 'documentsSecureList' });
    },
    populateDocument() {
      this.workingDocument = this.liveDocument;
      this.setAssociatedChildren(this.workingDocument.associatedInstitutionProfiles);
      this.implicitSharings = this.workingDocument.implicitSharings;
      this.setDocumentSharedWith();
      this.setDefaultCategory();
      this.isDocumentLoading = false;
    },
    populateInternalDocument() {
      this.isDocumentLoading = true;
      this.editSetupDocument({
        id: this.documentId ? this.documentId : this.$route.params.documentId,
        deleted: this.includesDeleted,
      })
        .then(() => {
          this.populateDocument();
          this.institutionCode = this.workingDocument.institutionCode;
          this.attachments = this.workingDocument.attachments;
        })
        .catch(() => {
          this.noPermissionToViewDocument();
        });
    },
    populateExternalDocument() {
      this.isDocumentLoading = true;
      this.editSetupExternal({
        id: this.documentId ? this.documentId : this.$route.params.documentId,
        deleted: this.includesDeleted,
      })
        .then(() => {
          this.populateDocument();
          this.institutionCode = this.workingDocument.institutionCode;
          this.attachments = [this.workingDocument.attachment];
        })
        .catch(() => {
          this.noPermissionToViewDocument();
        });
    },
    populateDocumentRevision() {
      this.isDocumentLoading = true;
      this.loadSingleRevision({
        documentRevisionId: this.$route.params.revisionId,
      })
        .then(() => {
          this.populateDocument();
          this.attachments = this.workingDocument.attachments;
        })
        .catch(() => {
          this.noPermissionToViewDocument();
        });
    },
    selectDefaultInstitution() {
      const activeInstitutionCodes = this.activeInstitutionCodes ?? [this.selectedInstitution.institutionCode];
      this.institutionCode = getDefaultInstitutionCode(this.institutions, activeInstitutionCodes);
    },
    populateDefaultData() {
      this.workingDocument.documentType = this.documentType;
      this.workingDocument.institutionCode = this.institutionCode || null;
    },
    initDocumentData() {
      if (this.$route.name === 'revisionHistoryDocument') {
        this.populateDocumentRevision();
        return;
      }

      const documentId = this.$route.params.documentId || this.documentId;
      if (!documentId) {
        this.populateDefaultData();
        return;
      }

      switch (this.documentType) {
        case documentTypes.EXTERNAL:
          this.populateExternalDocument();
          break;
        default:
          this.populateInternalDocument();
      }
    },
    initDocumentCategoriesAndTemplates() {
      if (this.$route.name === 'documentsSecureNew') {
        this.selectedTemplateId = null;
        this.selectedCategory = null;
        this.getDocumentCategoriesAndTemplates({
          institutionCode: this.institutionCode,
        });
      }
    },
    checkForDifferentChildInstitutions(children) {
      return new Set(children.map(child => child.institutionCode || this.institutionCode)).size > 1;
    },
  },
  watch: {
    selectedTemplateId(newValue, oldValue) {
      this.previousTemplateId = oldValue;
    },
  },
  mounted() {
    this.resetView();
    this.selectDefaultInstitution();
    this.initDocumentData();
    this.initDocumentCategoriesAndTemplates();
  },
  components: {
    DocumentSharing,
    AttachmentList,
    AttachmentThumbnails,
    AulaSearchRecipients,
    Editor,
    upload,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_documentDetails.scss';

.read-only-mode {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  margin-top: -10px;
  color: $color-darkblue !important;
}
.time-info {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
}
.can-edit-title {
  margin: 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
</style>
