// GALLERY
export const GALLERY_TAG_PERSONS = 'Tilføj tags';
export const GALLERY_SEARCH_FOR_PROFILES_AND_GROUPS = 'Søg efter personer eller grupper';
export const GALLERY_SHARE_WITH = 'Del med gruppe';
export const GALLERY_SHARED_WITH = 'Delt med';
export const GALLERY_NO_TITLE_WARNING = 'Mangler titel';
export const GALLERY_UPLOAD_PICTURES = 'Upload medier';
export const GALLERY_UPLOAD_MORE_PICTURES = 'Upload flere billeder';
export const GALLERY_SAVE_ALBUM = 'Opret album';
export const GALLERY_UPDATE_ALBUM = 'Opdater album';
export const GALLERY_ADD_MEDIA_TO_ALBUM_TITLE = 'Tilføj medier';
export const GALLERY_SAVE_MEDIA_TO_ALBUM = 'GEM';
export const GALLERY_UPDATE_MEDIA_MARKED_MEDIA = 'Opdater valgte';
export const GALLERY_UPDATE_MEDIA_MARKED_MEDIA_LABEL = 'Opdater markerede medier';
export const GALLERY_TAG_MEDIA = 'OPMÆRK MEDIER';
export const GALLERY_LABEL_TITLE = 'Titel';
export const GALLERY_PLACEHOLDER_TITLE = 'Angiv titel';
export const GALLERY_DESCRIPTION = 'Tilføj evt. beskrivelse';
export const GALLERY_IMAGE_SELECTED = 'medie valgt';
export const MEDIA_SELECTED = '{numberOfSelected} valgt';
export const GALLERY_IMAGES_SELECTED = 'medier valgt';
export const GALLERY_TAG_MEDIA_MOBILE = 'Opmærk følgende medier';
export const GALLERY_TAG_MEDIA_DESKTOP = 'Tilføj information til flere medier';
export const GALLERY_CONFIRM_TO_DELETE_ALBUM_1 =
  'Hvis du sletter albummet, vil det også blive slettet for alle dem, du har delt det med.';
export const GALLERY_CONFIRM_TO_DELETE_ALBUM_2 = 'Alle medier i albummet slettes ligeledes.';
export const GALLERY_CONFIRM_TO_DELETE_ALBUM_3 = 'Er du sikker på, at du vil slette albummet?';
export const GALLERY_ADD_INFORMATION = 'Tilføj information til medierne';
export const GALLERY_TOOLBAR_NEW_ALBUM = 'Opret album';
export const GALLERY_TOOLBAR_DOWNLOAD = 'Download';
export const GALLERY_TOOLBAR_DOWNLOAD_SINGLE_AMOUNT = 'album valgt';
export const GALLERY_TOOLBAR_DOWNLOAD_AMOUNT = 'albums valgt';
export const GALLERY_TOOLBAR_DOWNLOAD_MEDIA_AMOUNT = 'medier valgt';
export const GALLERY_TOOLBAR_DOWNLOAD_ALBUM = 'Download album';
export const GALLERY_TITLE = 'Galleri';
export const GALLERY_SELECT_ALL = 'Vælg alle';
export const SELECT_IMAGE = 'Vælg billede';
export const ROTATE_CLOCKWISE = 'Roter billedet til højre';
export const ROTATE_COUNTER_CLOCKWISE = 'Roter billedet til venstre';
export const REMOVE_IMAGE = 'Slet billedet';
export const ALL_ALBUMS = 'Alle albums';
export const GALLERY_ALBUM = 'ALBUM';
export const GALLERY_EDIT_ALBUM = 'Rediger album';
export const GALLERY_DELETE_ALBUM = 'Slet album';
export const GALLERY_ADD_MEDIA_TO_ALBUM = 'Tilføj medier';
export const GALLERY_MEDIA_VIEW_DETAIL = 'Se stort';
export const GALLERY_DURATION = 'Spilletid:';
export const GALLERY_ALL_PHOTOS = 'ALLE FOTOS';
export const GALLERY_SHOW_INFO = 'Vis info';
export const GALLERY_DELETE_MEDIA = 'Slet medie';
export const GALLERY_DELETE_MULTIPLE_MEDIA = 'Slet';
export const GALLERY_DOWNLOAD_MEDIA = 'Download';
export const GALLERY_DOWNLOAD_MULTIPLE_MEDIA = 'Download';
export const GALLERY_TAGS = 'Tags';
export const GALLERY_CREATOR = 'Oprettet af';
export const GALLERY_CREATED = 'Oprettet';
export const MEDIA_ALBUM_TAGS = 'Tags';
export const GALLERY_ADD_TAGS = 'Tilføj tags';
export const GALLERY_EDIT_MULTIPLE_MEDIA_1 = 'Du er ved at redigere flere billeder på én gang.';
export const GALLERY_EDIT_MULTIPLE_MEDIA_2 =
  'Hvis der er mange billeder, kan der gå et stykke tid, før du kan se ændringerne.';
export const GALLERY_BLOCKED_COMMUNICATION_WARNING =
  'Du kan ikke oprette et album, da din profil er blokeret fra al kommunikation';
export const GALLERY_CREATED_BY = 'Af';
export const MEDIA_CREATED_BY = 'Af';
export const MEDIA_CREATED_DATE = 'Dato';
export const MEDIA_IN_ALBUM_TITLE = 'Album';
export const MEDIA_IN_ALBUM_DESCRIPTION = 'Album beskrivelse';
export const GALLERY_DESCRIPTION_PLACEHOLDER = 'Evt. beskrivelse';
export const GALLERY_MEDIA_LIMIT_EXCEEDED =
  'Albummet indeholder for mange medier. Et album må ikke indeholde mere end 1.000 medier';
export const GALLERY_SOUND = 'Lyd';
export const GALLERY_VIDEO = 'Video';
export const GALLERY_DESELECT_ALL = 'Fravælg alle';
export const GALLERY_DOWNLOAD_SUCCESS = 'Albummerne blev downloadet';
export const GALLERY_ALBUM_DOWNLOADED = 'Albummet blev downloadet';
export const GALLERY_MEDIA_DOWNLOADED = 'Medier blev downloadet';
export const GALLERY_IMAGE_EXCEED_SIZE_MESSAGE =
  'En eller flere af de filer du uploader overskrider pixel dimensioner (1600x1600) og vil blive skaleret ned';
export const GALLERY_MEDIA_DESCRIPTION = 'Evt. beskrivelse';
export const GALLERY_MEDIA_TITLE = 'Evt. titel';
export const ARIA_LABEL_GALLERY_MEDIA_OPTIONS = 'Medieindstillinger';
export const ARIA_LABEL_GALLERY_TITLE = 'Indtast titel';
export const ARIA_LABEL_GALLERY_DESCRIPTION = 'Indtast beskrivelse';
export const ARIA_LABEL_GALLERY_MEDIA_DESCRIPTION = 'Indtast mediebeskrivelse';
export const ARIA_LABEL_GALLERY_MEDIA_TITLE = 'Indtast medietitel';
export const ARIA_LABEL_GALLERY_CHOOSE_MEDIA = 'Vælg medie';
export const ARIA_LABEL_NEXT_MEDIA = 'Næste medie';
export const ARIA_LABEL_PREVIOUS_MEDIA = 'Tidligere medier';

export const TAG_MORE = 'Tag flere';
export const SELECT_MORE = 'Vælg flere';
export const OTHERS = 'andre';
// SORT
export const GALLERY_SORT = 'Sorter efter';
export const SORT_BY_CREATED_DATE = 'Oprettelsesdato';
export const SORT_MEDIA_BY_UPLOADED_AT = 'Nyeste';
export const SORT_BY_NEWEST_MEDIA = 'Nyeste Medier';
export const SORT_BY_ALBUM_NAME = 'Navn';
// FILTER
export const GALLERY_FILTER = 'Filtrer';
export const GALLERY_FILTER_ALL = 'Alle';
export const MEDIA_FILTER_ALL = 'Alle';
export const MEDIA_FILTER_MY_MEDIA_ALBUMS = 'Oprettet af mig';
export const ARIA_GALLERY_FILTER_SELECTED = 'Vælg';
// FAILED CONVERSION
export const GALLERY_CONVERSION_IN_PROGRESS = 'Videoen er ved at blive behandlet, og vil være klar om et øjeblik.';
export const GALLERY_CONVERSION_FAILED_TITLE = 'Mulige afspilningsproblemer';
export const GALLERY_CONVERSION_FAILED_TEXT =
  'Denne videofil bruger ikke det fælles videoformat der benyttes i Aula, hvilket kan betyde at videoen ikke kan afspilles på din enhed';

export const GALLERY_SHOW_MEDIA = 'Vis medie {description}';
