export const mediaFormats = Object.freeze({
  bmp: '.bmp',
  gif: '.gif',
  jpeg: '.jpeg',
  jpg: '.jpg',
  png: '.png',
  aac: '.aac',
  aif: '.aif',
  cda: '.cda',
  flac: '.flac',
  m4a: '.m4a',
  mid: '.mid',
  midi: '.midi',
  mp3: '.mp3',
  mpa: '.mpa',
  ogg: '.ogg',
  wav: '.wav',
  wma: '.wma',
  wpl: '.wpl',
  THREE_G_2: '.3g2',
  THREE_G_P: '.3gp',
  avi: '.avi',
  flv: '.flv',
  h264: '.h264',
  m4v: '.m4v',
  mkv: '.mkv',
  mov: '.mov',
  mp4: '.mp4',
  mpg: '.mpg',
  mpeg: '.mpeg',
  rm: '.rm',
  swf: '.swf',
  vob: '.vob',
  wm: '.wm',
});
