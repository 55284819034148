import tinymce, { Editor } from 'tinymce/tinymce';

const YouTubePlugin = function (editor: Editor) {
  const openDialog = () =>
    editor.windowManager.open({
      title: 'YouTube import',
      body: {
        type: 'panel',
        items: [
          {
            type: 'textarea',
            name: 'source',
            label: 'Kilde',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Annuller',
        },
        {
          type: 'submit',
          text: 'JA',
          primary: true,
        },
      ],
      onSubmit: api => {
        const data: any = api.getData();
        editor.insertContent(data.source);
        api.close();
      },
    });

  editor.ui.registry.addButton('youtube', {
    icon: 'youtube',
    tooltip: 'Tilføj indhold fra YouTube',
    onAction: () => {
      openDialog();
    },
  });
};

tinymce.PluginManager.add('youtube', YouTubePlugin);
