import tinymce from 'tinymce';

const SkoletubePlugin = function (ed) {
  const openSk = function () {
    window.addEventListener('message', skreceiveMessage, false);
    ed.windowManager.openUrl({
      title: 'Skoletube/Studietube import',
      url:
        'https://www.skoletube.dk/app/bluebird/?passtype=complete&fitscale=true&urlref=' + encodeURI(window.location),
      width: 630,
      height: 540,
      buttons: [],
      onClose: () => window.removeEventListener('message', skreceiveMessage),
    });
  };

  ed.ui.registry.addIcon(
    'skicon',
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 281.4 324.5"><path class="st0" d="M196.2 137.4c0 0-8.1-88.4 18.3-137.4 0 0-14.6 2.7-23.2 32.3 0 0-9.7-16.7-9.2-32.3 0 0-7.5 10.8-7 28 0 0-8.6-21-12.4-20.5 -3.8 0.5-3.2 21.6-3.2 21.6S141.2 1.6 146.6 42c0 0-18.3-4.3-11.3 20.5 0 0-9.2-4.3-7.5 14 0 0-8.6-4.3-4.3 14 0 0-10.8 3.2-1.1 17.2 0 0-8.7 6.7 0.5 15.6 0 0 8.8 6.1 9.2 14.3 0 0-85.7-41.8-99.2-96.8 0 0 0.5 8.6 2.7 21 0 0-13.5-16.2-18.3-29.6 0 0 3.2 17.2 8.1 27.5 0 0-10.8-11.3-12.4-14.6 0 0-2.2 7-0.5 9.7 0 0-15.6-8.6 1.1 22.6 0 0-18.3-10.8-3.8 12.9 0 0-23.2 0 2.2 17.2 0 0-14 2.2 2.7 15.1 0 0-8.1 8.6 7.5 15.1 0 0-6.5 10.8 8.1 12.9 0 0-4.9 12.4 7 11.3 0 0 7.5 22.1 17.2 17.8 0 0 14 22.1 23.7 10.2 0 0-1.6 19.4 21 4.9 0 0-8.6 9.7 24.3 6.5 0 0-17.8 29.6-17.8 37.2 0 0-21.2 18.7-27.5 23.7 -7.5 6-26.4 29.6-26.4 29.6s18.3-15.6 23.2-12.9c0 0-37.2 31.8-37.2 45.3 0 0 16.2-10.8 27-20.5 0 0-4.3 13.5-11.9 18.3 0 0 27 6.5 66.8-65.2 0 0 17.2-3.8 21.6-8.6 0 0 4.3 17.2 40.4-4.9 0 0 11.3 15.6 16.2 3.8 0 0 7 1.6 4.9-6.5 0 0 8.1 3.2 4.9-5.4 0 0 15.1 5.9 10.2-2.2 -4.9-8.1-26.4-1.6-37.7 3.2 -11.3 4.9-16.2 7-17.2 2.7 0 0 53.9-30.7 62-47.4 8.1-16.7 4.3-30.7 4.3-30.7s35-17.8 34.5-26.4c0 0 13.5 0.5 16.7-4.9l-12.9-3.2 14-1.6c0 0-2.7-8.6-16.2-12.9 0 0-5-19.8-28-23.7 0 0 3.6-6.5 2.5-12.9 0 0-8.5 14.9-14.4 17.2 -6 2.3-24.7 23.7-25.8 44.9L196.2 137.4z"/></svg>'
  );

  ed.ui.registry.addButton('skoletube', {
    icon: 'skicon',
    tooltip: 'Tilføj indhold fra Skoletuben',
    onAction: function () {
      openSk();
    },
  });

  ed.ui.registry.addMenuItem('skoletube', {
    text: 'Skoletube import',
    onAction: function () {
      openSk();
    },
  });

  const skreceiveMessage = function (e) {
    if (
      event.origin !== 'https://www.studietube.dk' &&
      event.origin !== 'http://www.studietube.dk' &&
      event.origin !== 'http://www.skoletube.dk' &&
      event.origin !== 'https://www.skoletube.dk'
    ) {
      return false;
    }

    if (!/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(e.data)) {
      return false;
    }

    const content = JSON.parse(e.data);
    ed.insertContent(
      '<iframe src="' +
        content.embed +
        '" width="' +
        content.extra.width +
        '" height="' +
        content.extra.height +
        '" scrolling="no" style="overflow:hidden" frameborder="0" allowfullscreen></iframe>'
    );
    ed.windowManager.close();
  };
};

tinymce.PluginManager.add('skoletube', SkoletubePlugin);
