<template>
  <div class="media-data-container" :class="showMediaData || largeThumbnail ? 'col-12' : 'half-width'">
    <b-form-checkbox
      v-if="!isMobile && allowSelected"
      v-model="selected"
      :aria-label="'ARIA_LABEL_GALLERY_CHOOSE_MEDIA' | fromTextKey"
      @input="selectMedia"
    />
    <div class="album-media-data" :class="selected ? 'selected' : ''">
      <div v-if="isMedia(attachment) && showThumbnail" class="media-item">
        <div
          class="media-thumbnail image"
          :style="attachment.media.file == null ? 'height:auto' : ''"
          :class="{
            opacity: isMobile && allowSelected,
            'thumbnail-large': largeThumbnail,
            selected: selected && allowSelected,
            'no-media-data': !showMediaData && !largeThumbnail,
          }"
          @click="selectMedia"
        >
          <i v-if="selected && isMobile && allowSelected" class="icon-Aula_check" />
          <div v-else-if="isMobile" class="unselected-placeholder" />
          <template v-if="attachment.media != null">
            <template v-if="attachment.id == null && mediaIsValidFormatForRotation">
              <div class="rotate-icon cw">
                <i class="icon-Aula_rotate_right" @click.stop="rotateClockwise()" />
              </div>
              <div class="rotate-icon ccw">
                <i class="icon-Aula_rotate_left" @click.stop="rotateCounterClockwise()" />
              </div>
            </template>
            <AulaImg
              v-if="
                ((!attachment.isLoading && isMobile) || !isMobile) &&
                attachment != null &&
                mediaType === mediaTypes.IMAGE
              "
              ref="thumbnail"
              :alt="title"
              :img-url="url"
              class="thumbnail"
              :class="imgClasses"
            />
            <VideoThumbnail v-if="mediaType === mediaTypes.VIDEO" :media="attachment.media" />
            <SoundThumbnail v-if="mediaType === mediaTypes.SOUND" />
          </template>
          <template v-if="attachment.media.file == null">
            {{ attachment.name }}
          </template>
          <aula-spinner v-if="attachment.isLoading && isMobile" />
          <div v-if="(allowSelected || largeThumbnail) && isMobile" class="media-remove">
            <i
              class="icon-Aula_bin"
              tabindex="0"
              role="button"
              :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
              @click="$refs.deleteMediaModal.show()"
              @keydown.enter="$refs.deleteMediaModal.show()"
            />
          </div>
        </div>
        <div v-if="showMediaData && canEdit && canHandleMedia" class="media-data w-100">
          <aula-spinner v-if="attachment.isLoading" />
          <template v-else>
            <b-row>
              <b-col class="media-attachment-name" cols="12">
                {{ attachment.name != null ? attachment.name : attachment.media.file.name }}
              </b-col>
              <b-col v-if="parent !== parentTypes.DOCUMENTS" cols="12">
                <div class="text-truncate ml-2 mt-1">{{ taggedUserText }}</div>
                <b-dropdown
                  v-if="!(attachment.media.currentUserCanEditTags != null && !attachment.media.currentUserCanEditTags)"
                  ref="mediaTagDropdown"
                  class="dropdown-select foldermenu tag-panel position-relative"
                  :right="false"
                  variant="link"
                >
                  <template slot="button-content">
                    <div class="d-flex align-items-center">
                      <i class="icon-tag mr-1" />
                      {{ tagMediaButtonText }}
                      <i id="media-tag-button-icon" class="icon-Aula_down-arrow ml-1" />
                    </div>
                  </template>
                  <MediaTagHandling
                    :show-add-tag-label="false"
                    :can-edit-tags="canHandleMedia && !disabled"
                    :institution-code="institutionCodeForTagging"
                    :existing-tags="tags"
                    :missing-tags="missingTags"
                    @cancelTagging="cancelTagging"
                    @addTagsToMedia="addTagsToMedia"
                    @removeTagFromMedia="removeTagFromMedia"
                  />
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="!onlyTags" sm="12" lg="6" class="pr-1">
                <b-form-input
                  v-model="title"
                  :disabled="
                    (attachment.media.currentUserCanEditMetaData != null &&
                      !attachment.media.currentUserCanEditMetaData) ||
                    disabled
                  "
                  type="text"
                  :placeholder="'GALLERY_MEDIA_TITLE' | fromTextKey"
                  :aria-label="'ARIA_LABEL_GALLERY_MEDIA_TITLE' | fromTextKey"
                  @input="emitMediaData"
                />
              </b-col>
              <b-col v-if="!onlyTags" sm="12" lg="6" class="pl-1">
                <b-form-input
                  v-model="description"
                  :aria-label="'ARIA_LABEL_GALLERY_MEDIA_DESCRIPTION' | fromTextKey"
                  :disabled="
                    (attachment.media.currentUserCanEditMetaData != null &&
                      !attachment.media.currentUserCanEditMetaData) ||
                    disabled
                  "
                  type="text"
                  :placeholder="'GALLERY_MEDIA_DESCRIPTION' | fromTextKey"
                  @input="emitMediaData"
                />
              </b-col>
            </b-row>
          </template>
        </div>
        <div v-if="!isMobile || (!allowSelected && !largeThumbnail)" class="media-remove">
          <i
            class="icon-Aula_bin"
            tabindex="0"
            role="button"
            :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
            @click="$refs.deleteMediaModal.show()"
            @keydown.enter="$refs.deleteMediaModal.show()"
          />
        </div>
      </div>
      <div
        v-else-if="isLink(attachment) && showThumbnail"
        class="media-item download-link"
        @click="downloadAttachment(attachment)"
      >
        <div class="media-thumbnail">
          <i
            :class="
              attachment.link.service === 'OneDrive'
                ? 'icon-onedrive'
                : attachment.link.service === 'Google Drive'
                ? 'icon-googledrive'
                : 'icon-Aula_link'
            "
          />
        </div>
        <div class="media-name">{{ attachment.name }}</div>
        <div class="media-remove">
          <i
            class="icon-Aula_bin"
            tabindex="0"
            role="button"
            :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
            @click.stop="$refs.deleteMediaModal.show()"
            @keydown.enter="$refs.deleteMediaModal.show()"
          />
        </div>
      </div>
      <div v-else class="media-item download-link" @click="downloadAttachment(attachment)">
        <div class="media-thumbnail">
          <i class="icon-Aula_note" />
        </div>
        <div class="media-name">
          {{ attachment.name != null ? attachment.name : attachment.media.file.name }}
        </div>
        <div class="media-remove">
          <i
            class="icon-Aula_bin"
            tabindex="0"
            role="button"
            :aria-label="'ARIA_LABEL_DELETE' | fromTextKey"
            @click.stop="$refs.deleteMediaModal.show()"
            @keydown.enter="$refs.deleteMediaModal.show()"
          />
        </div>
      </div>
    </div>
    <aula-modal
      ref="deleteMediaModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.deleteMediaModal.hide()"
      @okClicked="$emit('removeMedia'), $refs.deleteMediaModal.hide()"
    >
      {{ fileRemoveWarningMessage | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { docTypes } from '../../../shared/enums/docTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import Vue from 'vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import AulaImg from '../../../shared/components/AulaImg';
import MediaTagHandling from '../../../shared/PageFragments/Media/MediaTagHandling.vue';
import VideoThumbnail from '../../../shared/PageFragments/Media/VideoThumbnail.vue';
import SoundThumbnail from '../../../shared/PageFragments/Media/SoundThumbnail.vue';

export default {
  props: {
    attachment: { type: Object, default: () => {} },
    mediasUpdated: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    selectedMedia: { type: Array, default: () => [] },
    allowSelected: { type: Boolean, default: true },
    onlyTags: { type: Boolean, default: false },
    showMediaData: { type: Boolean, default: true },
    showThumbnail: { type: Boolean, default: true },
    largeThumbnail: { type: Boolean, default: false },
    allowTaggingForAll: { type: Boolean, default: false },
    parent: { type: String, default: 'aula' },
    fileRemoveWarningMessage: {
      type: String,
      default: 'GALLERY_DELETE_MEDIA_CONFIRM',
    },
    institutionCodeForTagging: { type: String, default: null },
  },
  data: function () {
    return {
      title: this.attachment.media != null && this.attachment.media.title != null ? this.attachment.media.title : '',
      description:
        this.attachment.media != null && this.attachment.media.description != null
          ? this.attachment.media.description
          : '',
      tags: this.attachment.media != null && this.attachment.media.tags != null ? this.attachment.media.tags : [],
      existingTags:
        this.attachment.media != null && this.attachment.media.tags != null ? this.attachment.media.tags : [],
      missingTags:
        this.attachment.media != null && this.attachment.media.missingTags != null
          ? this.attachment.media.missingTags
          : false,
      resetInput: false,
      selected: false,
      parentTypes: parentTypes,
      docTypes: docTypes,
      portalRoles: portalRoles,
      mediaTypes: mediaTypes,
      moduleTypes: moduleTypes,
      url: '',
      rotationDegree: 0,
      allowedFormatsForRotation: ['png', 'jpeg', 'jpg', 'bmp'],
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      institutions: types.GET_INSTITUTIONS,
      hasPermission: types.HAS_PERMISSION,
    }),
    taggedUserText() {
      if (this.tags.length === 0) {
        return '';
      }
      const firstTag = Vue.filter('displayProfileNameWithMetadata')(this.tags[0]);
      const moreTags = this.tags.length > 1 ? ` + ${this.tags.length - 1} ${Vue.filter('fromTextKey')('OTHERS')}` : '';
      return firstTag + moreTags;
    },
    tagMediaButtonText() {
      return Vue.filter('fromTextKey')('GALLERY_ADD_TAGS');
    },
    imgClasses() {
      const classes = [];
      if (this.rotationDegree === 90 || this.rotationDegree === 270) {
        classes.push('vertical-rotated');
      }
      if (this.largeThumbnail) {
        classes.push('thumbnail-large');
      }
      return classes;
    },
    mediaIsValidFormatForRotation() {
      const fileNameSplit = this.attachment.name.split('.') || '';
      const ext = fileNameSplit[fileNameSplit.length - 1].toLowerCase();
      return this.allowedFormatsForRotation.includes(ext);
    },
    mediaType() {
      if (this.attachment.media != null) {
        return this.attachment.media.mediaType;
      }
      return null;
    },
    canHandleMedia() {
      return this.hasPermission(permissionEnum.HANDLE_MEDIA);
    },
  },
  watch: {
    attachment() {
      this.updateMediaData();
    },
    mediasUpdated() {
      this.updateMediaData();
    },
    'attachment.media.tags'() {
      if (this.attachment.media.tags != this.existingTags) {
        this.existingTags = this.attachment.media.tags;
        this.tags = this.attachment.media.tags;
      }
    },
    'attachment.media.file'() {
      this.getS3Object();
    },
  },
  mounted() {
    if (this.selectedMedia != null && this.selectedMedia.filter(m => m.guid == this.attachment.guid).length > 0) {
      this.selected = true;
    }
    if (this.parent == parentTypes.DOCUMENTS) {
      this.emitMediaData();
    }
    this.getS3Object();
  },
  methods: {
    onDropdownShown() {
      this.$nextTick(() => {
        this.$el.querySelector('input.el-select__input').focus();
      });
    },
    rotateClockwise() {
      const thumbnail = this.$refs.thumbnail.$el;
      this.rotationDegree += 90;
      if (this.rotationDegree > 270) {
        this.rotationDegree = 0;
      }
      this.emitMediaData();
      thumbnail.style.transform = 'rotate(' + this.rotationDegree + 'deg)';
    },
    rotateCounterClockwise() {
      const thumbnail = this.$refs.thumbnail.$el;
      this.rotationDegree -= 90;
      if (this.rotationDegree < 0) {
        this.rotationDegree = 270;
      }
      this.emitMediaData();
      thumbnail.style.transform = 'rotate(' + this.rotationDegree + 'deg)';
    },
    downloadAttachment(attachment) {
      if (attachment.file != null && attachment.file.url != undefined) {
        this.downloadFileFromUrl(attachment.file.url, attachment.file.name);
      } else if (attachment.media != null) {
        window.open(attachment.media.file.url);
      } else if (attachment.link != null) {
        window.open(attachment.link.url);
      }
    },
    downloadFileFromUrl(url, fileName) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    },
    isMedia(attachment) {
      if (attachment != null && attachment.media != null && this.mediaType != mediaTypes.LINK) {
        let fileNameSplit = '';
        if (attachment.name != null) {
          fileNameSplit = attachment.name.split('.');
        } else {
          fileNameSplit = attachment.media.file.name.split('.');
        }
        const ext = fileNameSplit[fileNameSplit.length - 1];
        if (
          Vue.filter('isMedia')(ext) == mediaTypes.SOUND ||
          Vue.filter('isMedia')(ext) == mediaTypes.IMAGE ||
          Vue.filter('isMedia')(ext) == mediaTypes.VIDEO
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isLink(attachment) {
      if (attachment != null && attachment.link != null) {
        return true;
      }
      return false;
    },
    selectMedia() {
      let emitData = { checked: this.selected, guid: this.attachment.guid };

      if (this.isMobile) {
        if (!this.allowSelected) {
          return;
        }
        this.selected = !this.selected;
        emitData = {
          checked: this.selected,
          guid: this.attachment.guid,
          url: this.url,
        };
      }
      this.$emit('mediaSelected', emitData);
    },
    cancelTagging() {
      this.$refs.mediaTagDropdown.hide();
    },
    addTagsToMedia(addedTags) {
      this.tags = this.tags.concat(addedTags);
      this.onEditTags();
    },
    onEditTags() {
      // AulaSearchRecipient returns selected groups and their members, filter out group DTOs, keep members only
      this.tags = this.tags.filter(item => item.type !== docTypes.GROUP.toLowerCase());
      this.emitMediaData();
    },
    removeTagFromMedia(tagToRemove) {
      const tagIndexToRemove = this.tags.findIndex(tag => tag.id === tagToRemove.id);
      this.tags.splice(tagIndexToRemove, 1);
      setTimeout(() => {
        this.$refs.mediaTagDropdown.show();
      }, 0);
      this.onEditTags();
    },
    emitMediaData() {
      const attachment = this.attachment;
      if (attachment.media) {
        attachment.media.title = this.title;
        attachment.media.rotationDegree = this.rotationDegree;
        attachment.media.description = this.description;
        attachment.media.tags = this.tags;
        this.$emit('mediaInfoUpdated', attachment);
      }
    },
    updateMediaData() {
      if (this.attachment.media != null) {
        if (this.attachment.media.title != this.title) {
          this.title = this.attachment.media.title;
        }
        if (this.attachment.media.description != this.description) {
          this.description = this.attachment.media.description;
        }
        if (this.attachment.media.tags != this.tags && this.selected) {
          this.resetInput = !this.resetInput;
          this.existingTags = this.attachment.media.tags;
          this.tags = this.attachment.media.tags;
        }
        if (this.attachment.media.missingTags != null) {
          this.missingTags = this.attachment.media.missingTags;
        }
      }
      if (this.selectedMedia != null && this.selectedMedia.filter(m => m.guid == this.attachment.guid).length > 0) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    },
    getS3Object() {
      if (this.attachment.media != null) {
        this.url = this.attachment.media.extraSmallThumbnailUrl || this.attachment.media.file.url;
        this.$emit('updateUrl', {
          guid: this.attachment.guid,
          url: this.url,
        });
      }
    },
  },
  components: {
    SoundThumbnail,
    VideoThumbnail,
    MediaTagHandling,
    AulaImg,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.thumbnail {
  @include breakpoint-lg-down() {
    height: 100px;
    width: 100%;
  }
}

.half-width {
  display: flex;
  justify-content: center;
  .album-media-data {
    max-width: 45vw;
  }
}
.media-data-container {
  --dropdown-menu-min-width: 400px;
  @include breakpoint-sm-down() {
    --dropdown-menu-min-width: 60vw;
  }
  @include breakpoint-xs-down() {
    --dropdown-menu-min-width: 85vw;
  }
  & .album-media-data .media-item .media-thumbnail {
    .rotate-icon {
      cursor: pointer;
      position: absolute;
      top: 5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 4px;
      background: white;
      opacity: 0.7;
      z-index: $media-icon-button-z-index;
      &.cw {
        right: 5px;
      }
      &.ccw {
        left: 5px;
      }
      i {
        color: $color-primary-darker;
      }
    }
  }
}

.media-item.download-link {
  cursor: pointer;

  .media-remove {
    bottom: auto;
  }
}
.media-thumbnail {
  display: flex;
}
</style>
