



import { PropType } from 'vue';
import { MediaModel } from '../../models/media.model';
import AulaImg from '../../components/AulaImg.vue';

export default {
  components: { AulaImg },
  props: {
    media: { type: Object as PropType<MediaModel>, required: true },
    large: { type: Boolean, default: false },
  },
  computed: {
    url() {
      if (this.large) {
        return this.media.largeThumbnailUrl;
      }
      return this.media.smallThumbnailUrl;
    },
  },
};
