export const presenceModules = Object.freeze({
  PICKUP_TIMES: 'pickup_times',
  DROP_OFF_TIME: 'drop_off_time',
  DAILY_MESSAGE: 'daily_message',
  REPORT_SICK: 'report_sick',
  VACATION: 'vacation',
  FIELD_TRIP: 'field_trip',
  SPARE_TIME_ACTIVITY: 'spare_time_activity',
  LOCATION: 'location',
  SLEEP: 'sleep',
  PICKUP_TYPE: 'pickup_type',
});
