
























import { PropType } from 'vue';
import { PostModel } from '../../../shared/models/post.model';
import AttachmentList from '../../../shared/components/AttachmentList.vue';
import MediaThumbnail from '../../../shared/components/MediaThumbnail.vue';
import Button from '../../../shared/directives/button.js';
import MediaDetail from '../gallery/MediaDetail.vue';
import { parentTypes } from '../../../shared/enums/parentTypes';
import { MediaModel } from '../../../shared/models/media.model';

const NUMBER_OF_THUMBNAILS = 4;
export default {
  components: { MediaDetail, MediaThumbnail, AttachmentList },
  directives: { Button },
  props: {
    post: { type: Object as PropType<PostModel>, required: true },
  },
  data() {
    return {
      isVisibleMediaGallery: false,
      selectedMediaId: null,
      mediaThumbnails: [],
    };
  },
  computed: {
    gridLayoutClass() {
      switch (this.visibleThumbnailMedia.length) {
        case 1:
          return 'grid-layout-1';
        case 2:
          return 'grid-layout-2';
        case 3:
          return 'grid-layout-3';
        default:
          return 'grid-layout-4';
      }
    },
    mediaList() {
      const mediaList = [];
      for (const attachment of this.post.attachments) {
        if (attachment.media != null) {
          mediaList.push(attachment.media);
        }
      }
      return mediaList;
    },
    hasMoreMedia() {
      return this.mediaThumbnails.length > NUMBER_OF_THUMBNAILS;
    },
    numberOfHiddenMedia() {
      if (!this.hasMoreMedia) {
        return 0;
      }
      return this.mediaThumbnails.length - NUMBER_OF_THUMBNAILS;
    },
    visibleThumbnailMedia() {
      return this.mediaThumbnails.slice(0, NUMBER_OF_THUMBNAILS);
    },
    parent() {
      return parentTypes.POSTS;
    },
  },
  mounted() {
    this.populateMediaThumbnails();
  },
  methods: {
    populateMediaThumbnails() {
      this.mediaThumbnails = this.mediaList;
    },
    openMediaGallery(media: MediaModel) {
      this.isVisibleMediaGallery = true;
      this.selectedMediaId = media.id;
    },
    closeMediaGallery() {
      this.isVisibleMediaGallery = false;
      this.selectedMediaId = null;
    },
    getOverlayText(mediaIndex) {
      if (mediaIndex < NUMBER_OF_THUMBNAILS - 1 || !this.hasMoreMedia) {
        return '';
      }
      return `+${this.numberOfHiddenMedia}`;
    },
    removeMedia(mediaId) {
      this.mediaThumbnails = this.mediaThumbnails.filter(media => media.id !== mediaId);
    },
  },
};
