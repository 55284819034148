





import { ObserverProviderKeys } from '../providers/observerProviderKeys';

export default {
  name: 'ObservedTarget',
  inject: {
    registerItem: ObserverProviderKeys.REGISTER_ITEM,
    unregisterItem: ObserverProviderKeys.UNREGISTER_ITEM,
  },
  emits: ['onIntersecting'],
  mounted() {
    this.registerItem(this.$refs.observedTarget, this.onIntersecting);
  },
  beforeDestroy() {
    this.unregisterItem(this.$refs.observedTarget);
  },
  methods: {
    onIntersecting(entry) {
      this.$emit('onIntersecting', entry);
    },
  },
};
