export enum notificationEventTypes {
  NEW_MESSAGE_PRIVATE_INBOX = 'NewMessagePrivateInbox',
  NEW_MESSAGE_COMMON_INBOX = 'NewMessageCommonInbox',
  NEW_SENSITIVE_MESSAGE_PRIVATE_INBOX = 'NewSensitiveMessagePrivateInbox',
  NEW_SENSITIVE_MESSAGE_COMMON_INBOX = 'NewSensitiveMessageCommonInbox',
  ADDED_TO_COMMON_INBOX = 'AddedToCommonInbox',
  REMOVED_FROM_COMMON_INBOX = 'RemovedFromCommonInbox',
  INVITED_TO_EVENT_NO_RESPONSE_REQUIRED = 'InvitedToEventNoResponseRequired',
  INVITED_TO_EVENT_RESPONSE_REQUIRED = 'InvitedToEventResponseRequired',
  EVENT_SUBSTITUTE_ADDED = 'SubstituteAdded',
  EVENT_LESSON_NOTE_CHANGED = 'LessonNoteChanged',
  INVITED_TO_REPEATING_EVENT = 'InvitedToRepeatingEvent',
  EVENT_CHANGED_NO_RESPONSE_REQUIRED = 'EventChangedNoResponseRequired',
  EVENT_CHANGED_RESPONSE_REQUIRED = 'EventChangedResponseRequired',
  EVENT_CANCELLED_NO_REASON = 'EventCancelledNoReason',
  INVITED_TO_PARENTAL_MEETING = 'InvitedToParentalMeeting',
  INVITED_TO_SCHOOL_HOME_MEETING = 'InvitedToSchoolHomeMeeting',
  INVITED_TO_PERFORMANCE_MEETING = 'InvitedToPerformanceMeeting',
  GRANTED_RIGHTS_FOR_OTHER_CALENDAR = 'GrantedRightsForOtherCalendar',
  REMOVED_RIGHTS_FOR_OTHERS_CALENDAR = 'RemovedRightsForOthersCalendar',
  LOST_ROOM_BECAUSE_OF_EXTERNAL_SCHEDULING = 'LostRoomBecauseOfExternalScheduling',
  SOMEONE_ELSE_RESPONDED_TO_EVENT = 'SomeoneElseRespondedToEvent',
  SOMEONE_ELSE_REMOVED_YOUR_RESPONSE_TO_EVENT = 'SomeoneElseRemovedYourResponseToEvent',
  POST_SHARED_WITH_ME = 'PostSharedWithMe',
  POST_WAS_REMOVED_FROM_GROUP_BY_ADMIN = 'PostWasRemovedFromGroupByAdmin',
  POST_WAS_DELETED = 'PostWasDeleted',
  DASHBOARD_WAS_UPDATED = 'DashboardWasUpdated',
  ALBUM_SHARED = 'AlbumShared',
  NEW_MEDIA_IN_ALBUM = 'NewMedia',
  MEDIA_ADDED_TO_ALBUM = 'MediaAddedToAlbum',
  TAGGED_IN_MEDIA = 'TaggedInMedia',
  NEW_COMMON_FILE = 'NewCommonFile',
  UPDATED_SHARING_COMMON_FILE = 'UpdatedSharingCommonFile',
  NEW_OR_UPDATED_COMMON_FILE = 'NewOrUpdatedCommonFile',
  NEW_OR_UPDATED_SECURE_DOCUMENT = 'NewOrUpdatedSecureDocument',
  VACATION_RESPONSE_REQUIRED = 'VacationResponseRequired',
  VACATION_REQUEST_CANCELLED_NO_REASON = 'VacationRequestCancelledNoReason',
  POST_WAS_REPORTED = 'PostWasReported',
  MEDIA_WAS_REPORTED = 'MediaWasReported',
  COMMENT_WAS_REPORTED = 'CommentWasReported',
  EVENT_CANCELLED_BY_SOMEONE_ELSE = 'EventCancelledBySomeoneElse',
  EVENT_CHANGED_BY_SOMEONE_ELSE = 'EventChangedBySomeoneElse',
  WIDGET_NOTIFICATION = 'WidgetNotification',
  INVITED_TO_EXCEPTION_EVENT = 'InvitedToExceptionEvent',
  INVITED_TO_SINGLE_OCCURRENCE_OF_EVENT = 'InvitedToSingleOccurrenceOfEvent',
  SINGLE_OCCURRENCE_EVENT_CANCELLED_NO_REASON = 'SingleOccurrenceEventCancelledNoReason',
  INVITED_TO_SURVEY = 'InvitedToSurvey',
  DELETION_RULES_INFO = 'DeletionRulesInfo',
  RESOURCE_RESERVATION_DECLINED = 'ResourceReservationDeclined',
  GENERAL_INFORMATION_SENT = 'GeneralInformationSent',
}
