<template>
  <div ref="observingContainer">
    <slot />
  </div>
</template>
<script>
import { ObserverProviderKeys } from '../providers/observerProviderKeys';
export default {
  name: 'ObservingContainer',
  provide() {
    return {
      [ObserverProviderKeys.REGISTER_ITEM]: this.registerItem,
      [ObserverProviderKeys.UNREGISTER_ITEM]: this.unregisterItem,
    };
  },
  props: {
    options: {
      default: () => ({}),
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      observer: null,
      targetCallbackMap: new Map(),
    };
  },
  beforeMount() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          /* @type HTMLElement */
          const target = entry.target;
          const targetCallback = this.targetCallbackMap.get(target);
          if (targetCallback != null && entry.isIntersecting) {
            targetCallback(entry);
          }
        });
      },
      {
        ...this.options,
        root: this.$refs.observingContainer,
      }
    );
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    registerItem(element, callback) {
      this.targetCallbackMap.set(element, callback);
      this.observer.observe(element);
    },
    unregisterItem(element) {
      this.targetCallbackMap.delete(element);
      this.observer.unobserve(element);
    },
  },
};
</script>
