import tinymce from 'tinymce';
import moment from 'moment-timezone';
import { store } from '../../../../src/store/store';
import { types } from '../../../../src/store/types/types';

const keepAliveIntervalMinutes = 1;
let lastKeepAlive = moment();

const setup = function (editor) {
  editor.on('keydown', function () {
    if (moment().diff(lastKeepAlive, 'minutes') >= keepAliveIntervalMinutes) {
      store.dispatch(types.ACTION_KEEPALIVE);
      lastKeepAlive = moment();
    }
  });
};

tinymce.PluginManager.add('editorKeepAlive', function (editor) {
  setup(editor);
});
