export enum notificationAreas {
  CALENDAR = 'Calendar',
  MESSAGES = 'Messages',
  ADMINISTRATION = 'Administration',
  GALLERY = 'Gallery',
  ALBUM = 'Album',
  POSTS = 'Posts',
  SCHEDULE = 'Schedule',
  DOCUMENTS = 'Documents',
  PRESENCE = 'Presence',
}
