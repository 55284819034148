








import { PropType } from 'vue';
import { SimpleGroupModel } from '../../../shared/models/simpleGroup.model';
import GroupMemberPopover from './GroupMemberPopover.vue';

const MINIMUM_NUMBER_OF_GROUPS = 1;
export default {
  components: { GroupMemberPopover },
  props: {
    groups: { type: Array as PropType<SimpleGroupModel[]>, required: true },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    numberOfHiddenGroups() {
      return this.groups.length - this.visibleGroups.length;
    },
    canShowHiddenGroupsText() {
      return !this.isExpanded && this.numberOfHiddenGroups > 0;
    },
    visibleGroups() {
      if (this.isExpanded) {
        return this.groups;
      }
      return this.groups.slice(0, MINIMUM_NUMBER_OF_GROUPS);
    },
    hiddenGroupsText() {
      const suffix =
        this.numberOfHiddenGroups === 1
          ? this.$options.filters.fromTextKey('SUFFIX_OTHER_GROUP')
          : this.$options.filters.fromTextKey('SUFFIX_OTHER_GROUPS');
      return `${this.numberOfHiddenGroups} ${suffix}`;
    },
  },
  mounted() {
    this.isExpanded = this.groups.length === MINIMUM_NUMBER_OF_GROUPS;
  },
  methods: {
    expandGroups() {
      this.isExpanded = true;
    },
  },
};
