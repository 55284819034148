import tinymce from 'tinymce';

tinymce.addI18n('da', {
  Redo: 'Gendan',
  Undo: 'Fortryd',
  Cut: 'Klip',
  Copy: 'Kopier',
  Paste: 'S\u00E6t ind',
  'Select all': 'V\u00E6lg alle',
  'New document': 'Nyt dokument',
  Ok: 'Ok',
  Cancel: 'Annuller',
  'Visual aids': 'Visuel hj\u00E6lp',
  Bold: 'Fed',
  Italic: 'Kursiv',
  Underline: 'Understreget',
  Strikethrough: 'Gennemstreget',
  Superscript: 'H\u00E6vet skrift',
  Subscript: 'S\u00E6nket skrift',
  'Clear formatting': 'Nulstil formattering',
  'Align left': 'Venstrejustér',
  'Align center': 'Centrer',
  'Align right': 'H\u00f8jrejustér',
  Justify: 'Justering',
  'Bullet list': 'Punktopstillet liste',
  'Numbered list': 'Nummereret liste',
  'Decrease indent': 'Formindsk indrykning',
  'Increase indent': 'For\u00f8g indrykning',
  Close: 'Luk',
  Formats: 'Formater',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X C V keyboard shortcuts instead.":
    'Din browser underst\u00f8tter ikke direkte adgang til udklipsholder. Benyt Ctrl+X C V tastaturgenveje i stedet for.',
  Headers: 'Overskrifter',
  'Header 1': 'Overskrift 1',
  'Header 2': 'Overskrift 2',
  'Header 3': 'Overskrift 3',
  'Header 4': 'Overskrift 4',
  'Header 5': 'Overskrift 5',
  'Header 6': 'Overskrift 6',
  Headings: 'Overskrifter',
  'Heading 1': 'Overskrift 1',
  'Heading 2': 'Overskrift 2',
  'Heading 3': 'Overskrift 3',
  'Heading 4': 'Overskrift 4',
  'Heading 5': 'Overskrift 5',
  'Heading 6': 'Overskrift 6',
  Preformatted: 'Forudformateret',
  Div: 'Div',
  Pre: 'Pre',
  Code: 'Kode',
  Paragraph: 'Normal',
  Blockquote: 'Citat',
  Inline: 'Inline',
  Blocks: 'Blokke',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'S\u00E6t ind er indstillet til at inds\u00E6tte som ren tekst. Indhold bliver nu indsat uden formatering indtil du \u00E6ndrer indstillingen.',
  Fonts: 'Skrifttyper',
  'Font Sizes': 'Skriftst\u00f8rrelse',
  Class: 'Class',
  'Browse for an image': 'S\u00f8g efter et billede',
  OR: 'OR',
  'Drop an image here': 'Tr\u00E6k et billede herover',
  Upload: 'Upload',
  Block: 'Blokér',
  Align: 'Align',
  Default: 'Standard',
  Circle: 'Cirkel',
  Disc: 'Disk',
  Square: 'Kvadrat',
  'Lower Alpha': 'Lower Alpha',
  'Lower Greek': 'Lower Greek',
  'Lower Roman': 'Lower Roman',
  'Upper Alpha': 'Upper Alpha',
  'Upper Roman': 'Upper Roman',
  'Anchor...': 'Anker...',
  Name: 'Navn',
  Id: 'Id',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'Id b\u00f8r starte med et bogstav, efterfulgt af bogstaver, tal, bindestreger, punktummer, koloner eller underscores.',
  'You have unsaved changes are you sure you want to navigate away?':
    'Du har ikke gemte \u00E6ndringer. Er du sikker p\u00E5 at du vil forts\u00E6tte?',
  'Restore last draft': 'Genopret sidste kladde',
  'Special character...': 'Specielle tegn...',
  'Source code': 'Kildekode',
  'Insert Edit code sample': 'Inds\u00E6t Rediger kodeeksempel',
  Language: 'Sprog',
  'Code sample...': 'Kodeeksempel...',
  'Color Picker': 'Farvev\u00E6lger',
  R: 'R',
  G: 'G',
  B: 'B',
  'Left to right': 'Venstre til h\u00f8jre',
  'Right to left': 'H\u00f8jre til venstre',
  Emoticons: 'Emojis',
  'Emoticons...': 'Emojis...',
  'Metadata and Document Properties': 'Metadata og dokumentegenskaber',
  Title: 'Titel',
  Keywords: 'S\u00f8geord',
  Description: 'Beskrivelse',
  Robots: 'Robotter',
  Author: 'Forfatter',
  Encoding: 'Kodning',
  Fullscreen: 'Fuldsk\u00E6rm',
  Action: 'Handling',
  Shortcut: 'Genvej',
  Help: 'Hj\u00E6lp',
  Address: 'Adresse',
  'Focus to menubar': 'Fokus p\u00E5 menulinjen',
  'Focus to toolbar': 'Fokus p\u00E5 v\u00E6rkt\u00f8jslinjen',
  'Focus to element path': 'Fokuser p\u00E5 elementvej',
  'Focus to contextual toolbar': 'Fokuser p\u00E5 kontekstuelle v\u00E6rkt\u00f8jslinje',
  'Insert link (if link plugin activated)': 'Inds\u00E6t link (hvis link plugin er aktiveret)',
  'Save (if save plugin activated)': 'Gem (hvis save plugin er aktiveret)',
  'Find (if searchreplace plugin activated)': 'Find (hvis searchreplace plugin er aktiveret)',
  'Plugins installed ({0}):': 'Installerede plugins ({0}):',
  'Premium plugins:': 'Premium plugins:',
  'Learn more...': 'L\u00E6r mere...',
  'You are using {0}': 'Du benytter {0}',
  Plugins: 'Plugins',
  'Handy Shortcuts': 'Praktiske Genveje',
  'Horizontal line': 'Vandret linie',
  'Insert edit image': 'Inds\u00E6t rediger billede',
  'Alternative description': 'Alternativ beskrivelse',
  Accessibility: 'Tilg\u00E6ngelighed',
  'Image is decorative': 'Billede er dekorativt',
  Source: 'Kilde',
  Dimensions: 'Dimensioner',
  'Constrain proportions': 'Behold propertioner',
  General: 'Generelt',
  Advanced: 'Avanceret',
  Style: 'Stil',
  'Vertical space': 'Lodret afstand',
  'Horizontal space': 'Vandret afstand',
  Border: 'Kant',
  'Insert image': 'Inds\u00E6t billede',
  'Image...': 'Billede...',
  'Image list': 'Billedeliste',
  'Rotate counterclockwise': 'Drej modsat urets retning',
  'Rotate clockwise': 'Drej med urets retning',
  'Flip vertically': 'Flip vertikalt',
  'Flip horizontally': 'Flip horisontalt',
  'Edit image': 'Rediger billede',
  'Image options': 'Billede indstillinger',
  'Zoom in': 'Zoom ind',
  'Zoom out': 'Zoom ud',
  Crop: 'Besk\u00E6r',
  Resize: 'Skaler',
  Orientation: 'Retning',
  Brightness: 'Lysstyrke',
  Sharpen: 'G\u00f8r skarpere',
  Contrast: 'Kontrast',
  'Color levels': 'Farve niveauer',
  Gamma: 'Gamma',
  Invert: 'Inverter',
  Apply: 'Anvend',
  Back: 'Tilbage',
  'Insert date time': 'Inds\u00E6t dato klokkesl\u00E6t',
  'Date time': 'Dato klokkesl\u00E6t',
  'Insert edit link': 'Inds\u00E6t rediger link',
  'Text to display': 'Tekst at vise',
  Url: 'Url',
  'Open link in...': '\u00C5bn link med...',
  'Current window': 'Aktuelle vindue',
  None: 'Ingen',
  Dotted: 'Prikket',
  Dashed: 'Stiplet',
  Double: 'Dobbelt',
  Groove: 'Rille',
  Ridge: 'Kamme',
  Inset: 'Indvendig',
  Outset: 'Udvendig',
  Hidden: 'Skjult',
  'New window': 'Nyt vindue',
  'Open link': '\u00C5ben link',
  'Remove link': 'Fjern link',
  Anchors: 'Ankre',
  'Link...': 'Link...',
  'Paste or type a link': 'Inds\u00E6t eller indtast et link',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    "URL'en som du angav ser ud til at v\u00E6re en email adresse. \u00D8nsker du at tilf\u00f8je det p\u00E5kr\u00E6vede prefiks  mailto: ?",
  'The URL you entered seems to be an external link. Do you want to add the required http:   prefix?':
    "URL'en som du angav ser ud til at v\u00E6re et eksternt link. \u00D8nsker du at tilf\u00f8je det p\u00E5kr\u00E6vede prefiks http:   ?",
  'The URL you entered seems to be an external link. Do you want to add the required https:   prefix?':
    "URL'en som du angav ser ud til at v\u00E6re et eksternt link. \u00D8nsker du at tilf\u00f8je det p\u00E5kr\u00E6vede https:   pr\u00E6fiks?",
  'Link list': 'Linkliste',
  'Insert video': 'Inds\u00E6t video',
  'Insert edit video': 'Inds\u00E6t rediger video',
  'Insert edit media': 'Inds\u00E6t rediger medier',
  'Alternative source': 'Alternativ kilde',
  'Alternative source URL': 'Alternativ URL kilde',
  'Media poster (Image URL)': 'Medieplakat (billede URL)',
  'Paste your embed code below:': 'Inds\u00E6t din integrerede kode herunder:',
  Embed: 'Integrer',
  'Media...': 'Medie...',
  'Nonbreaking space': 'H\u00E5rdt mellemrum',
  'Page break': 'Sideskift',
  'Paste as text': 'Inds\u00E6t som ren tekst',
  Preview: 'Forh\u00E5ndsvisning',
  'Print...': 'Udskriv...',
  Save: 'Gem',
  Find: 'Find',
  'Replace with': 'Erstat med',
  Replace: 'Erstat',
  'Replace all': 'Erstat alt',
  Previous: 'Forrige',
  Next: 'N\u00E6ste',
  'Find and Replace': 'Find og erstat',
  'Find and replace...': 'Find og erstat...',
  'Could not find the specified string.': 'Kunne ikke finde s\u00f8getekst',
  'Match case': 'STORE og sm\u00E5 bogstaver',
  'Find whole words only': 'Find kun hele ord',
  'Find in selection': 'Find i det valgte',
  Spellcheck: 'Stavekontrol',
  'Spellcheck Language': 'Sprog til stavekontrol',
  'No misspellings found.': 'Ingen stavefejl fundet.',
  Ignore: 'Ignorer',
  'Ignore all': 'Ignorer alt',
  Finish: 'F\u00E6rdig',
  'Add to Dictionary': 'Tilf\u00f8j til ordbog',
  'Insert table': 'Inds\u00E6t tabel',
  'Table properties': 'Tabelegenskaber',
  'Delete table': 'Slet tabel',
  Cell: 'Celle',
  Row: 'R\u00E6kke',
  Column: 'Kolonne',
  'Cell properties': 'Celleegenskaber',
  'Merge cells': 'Flet celler',
  'Split cell': 'Split celle',
  'Insert row before': 'Inds\u00E6t r\u00E6kke over',
  'Insert row after': 'Inds\u00E6t r\u00E6kke under',
  'Delete row': 'Slet r\u00E6kke',
  'Row properties': 'R\u00E6kkeegenskaber',
  'Cut row': 'Klip r\u00E6kke',
  'Cut column': 'Klip kolonne',
  'Copy column': 'Kopier kolonne',
  'Copy row': 'Kopier r\u00E6kke',
  'Paste row before': 'Inds\u00E6t r\u00E6kke over',
  'Paste column before': 'Inds\u00E6t kolonne over',
  'Paste row after': 'Inds\u00E6t r\u00E6kke under',
  'Paste column after': 'Inds\u00E6t kolonne under',
  'Insert column before': 'Inds\u00E6t kolonne til venstre',
  'Insert column after': 'Inds\u00E6t kolonne til h\u00f8jre',
  'Delete column': 'Slet kolonne',
  Cols: 'Kolonner',
  Rows: 'R\u00E6kker',
  Width: 'Bredde',
  Height: 'H\u00f8jde',
  'Cell spacing': 'Celleafstand',
  'Cell padding': 'Cellepadding',
  Caption: 'Overskrift',
  'Show caption': 'Vis overskrift',
  Left: 'Venstre',
  Center: 'Centrering',
  Right: 'H\u00f8jre',
  'Cell type': 'Celletype',
  Scope: 'Anvendelsesomr\u00E5de',
  Alignment: 'Tilpasning',
  'H Align': 'H juster',
  'V Align': 'V juster',
  Top: 'Top',
  Middle: 'Midte',
  Bottom: 'Bund',
  'Header cell': 'Sidehoved celle',
  'Row group': 'R\u00E6kkegruppe',
  'Column group': 'Kolonnegruppe',
  'Row type': 'R\u00E6kketype',
  Header: 'Sidehoved',
  Body: 'Body',
  Footer: 'Sidefod',
  'Border color': 'Kantfarve',
  'Insert template...': 'Inds\u00E6t skabelon...',
  Templates: 'Skabeloner',
  Template: 'Skabelon',
  'Text color': 'Tekstfarve',
  'Background color': 'Baggrundsfarve',
  'Custom...': 'Brugerdefineret...',
  'Custom color': 'Brugerdefineret farve',
  'No color': 'Ingen farve',
  'Remove color': 'Fjern farve',
  'Table of Contents': 'Indholdsfortegnelse',
  'Show blocks': 'Vis blokke',
  'Show invisible characters': 'Vis usynlige tegn',
  'Word count': 'Optalte ord',
  Count: 'Antal',
  Document: 'Dokument',
  Selection: 'Valg',
  Words: 'Ord',
  'Words: {0}': 'Ord: {0}',
  '{0} words': '{0} ord',
  File: 'Fil',
  Edit: 'Rediger',
  Insert: 'Inds\u00E6t',
  View: 'Vis',
  Format: 'Format',
  Table: 'Tabel',
  Tools: 'V\u00E6rkt\u00f8j',
  'Powered by {0}': 'Drevet af {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    'Rich Text Area. Tryk ALT-F9 for menu. Tryk ALT-F10 for v\u00E6rkt\u00f8jslinje. Tryk ALT-0 for hj\u00E6lp',
  'Image title': 'Billedtitel',
  'Border width': 'Kantbredde',
  'Border style': 'Kantstil',
  Error: 'Fejl',
  Warn: 'Advar',
  Valid: 'Gyldig',
  'To open the popup, press Shift+Enter': 'Tryk skift + enter for at \u00E5bne pop op',
  'Rich Text Area. Press ALT-0 for help.': 'Rich tekst omr\u00E5de. Tryk p\u00E5 ALT-0 for hj\u00E6lp.',
  'System Font': 'Systemskrifttype',
  'Failed to upload image: {0}': 'Mislykket billed-upload:',
  'Failed to load plugin: {0} from url {1}': 'Mislykket plugin indl\u00E6sning: {0}  fra url {1}',
  'Failed to load plugin url: {0}': 'Mislykket indl\u00E6sning af plugin-url: {0}',
  'Failed to initialize plugin: {0}': 'Mislykket initiering a plugin: {0}',
  example: 'eksempel',
  Search: 'S\u00f8g',
  All: 'Alle',
  Currency: 'Valuta',
  Text: 'Tekst',
  Quotations: 'Anf\u00f8rselstegn',
  Mathematical: 'Matematiske tegn',
  'Extended Latin': 'Udvidet Latin',
  Symbols: 'Symboler',
  Arrows: 'Pile',
  'User Defined': 'Brugerdefineret',
  'dollar sign': 'dollartegn',
  'currency sign': 'valutategn',
  'euro-currency sign': 'euro-tegn',
  'colon sign': 'kolontegn',
  'cruzeiro sign': 'cruzeiro-tegn',
  'french franc sign': 'fransk frank-tegn',
  'lira sign': 'lira-tegn',
  'mill sign': 'mill-tegn',
  'naira sign': 'naira-tegn',
  'peseta sign': 'peseta-tegn',
  'rupee sign': 'rupee-tegn',
  'won sign': 'won-tegn',
  'new sheqel sign': 'ny sheqel-tegn',
  'dong sign': 'dong-tegn',
  'kip sign': 'kip-tegn',
  'tugrik sign': 'tugrik-tegn',
  'drachma sign': 'drakmer-tegn',
  'german penny symbol': 'tysk penny-symbol',
  'peso sign': 'peso-tegn',
  'guarani sign': 'guarani-tegn',
  'austral sign': 'austral-tegn',
  'hryvnia sign': 'hryvnia-tegn',
  'cedi sign': 'cedi-tegn',
  'livre tournois sign': 'livre tournois-tegn',
  'spesmilo sign': 'spesmilo-tegn',
  'tenge sign': 'tenge-tegn',
  'indian rupee sign': 'indisk rupee-tegn',
  'turkish lira sign': 'tyrkisk lira-tegn',
  'nordic mark sign': 'nordisk mark-tegn',
  'manat sign': 'manat-tegn',
  'ruble sign': 'rubel-tegn',
  'yen character': 'yen-tegn',
  'yuan character': 'yuan-tegn',
  'yuan character, in hong kong and taiwan': 'yuan-tegn, i hong kong og taiwan',
  'yen yuan character variant one': 'yen yuan-tegn variant en',
  'Loading emoticons...': 'Indl\u00E6ser emojis...',
  'Could not load emoticons': 'Kunne ikke indl\u00E6se emojis',
  People: 'Folk',
  'Animals and Nature': 'Dyr og natur',
  'Food and Drink': 'Mad og drikke',
  Activity: 'Aktivitet',
  'Travel and Places': 'Transport og steder',
  Objects: 'Objekter',
  Flags: 'Flag',
  Characters: 'Tegn',
  'Characters (no spaces)': 'Tegn (uden mellemrum)',
  '{0} characters': '{0} tegn',
  'Error: Form submit field collision.': 'Fejl: Form submit felt kollision',
  'Error: No form element found.': 'Fejl: Intet formelement fundet.',
  Update: 'Opdater',
  'Color swatch': 'Farvepr\u00f8ve',
  Turquoise: 'Turkis',
  Green: 'Gr\u00f8n',
  Blue: 'Bl\u00E5',
  Purple: 'Lilla',
  'Navy Blue': 'Marinebl\u00E5',
  'Dark Turquoise': 'M\u00f8rketurkis',
  'Dark Green': 'M\u00f8rkegr\u00f8n',
  'Medium Blue': 'Medium bl\u00E5',
  'Medium Purple': 'Medium lilla',
  'Midnight Blue': 'Midnatsbl\u00E5',
  Yellow: 'Gul',
  Orange: 'Orange',
  Red: 'R\u00f8d',
  'Light Gray': 'Lysegr\u00E5',
  Gray: 'Gr\u00E5',
  'Dark Yellow': 'M\u00f8rkegul',
  'Dark Orange': 'M\u00f8rkeorange',
  'Dark Red': 'M\u00f8rker\u00f8d',
  'Medium Gray': 'Medium gr\u00E5',
  'Dark Gray': 'M\u00f8rkegr\u00E5',
  'Light Green': 'Lysegr\u00f8n',
  'Light Yellow': 'Lysegul',
  'Light Red': 'Lyser\u00f8d',
  'Light Purple': 'Lyslilla',
  'Light Blue': 'Lysebl\u00E5',
  'Dark Purple': 'M\u00f8rkelilla',
  'Dark Blue': 'M\u00f8rkebl\u00E5',
  Black: 'Sort',
  White: 'Hvid',
  'Switch to or from fullscreen mode': 'Skift til eller fra fuldsk\u00E6rmstilstand',
  'Open help dialog': '\u00C5bn hj\u00E6lpedialog',
  history: 'historie',
  styles: 'stile',
  formatting: 'formatering',
  alignment: 'justering',
  indentation: 'indrykning',
  Font: 'Skrifttype',
  Size: 'St\u00f8rrelse',
  'More...': 'Mere...',
  'Select...': 'V\u00E6lg...',
  Preferences: 'Pr\u00E6ferencer',
  Yes: 'Ja',
  No: 'Nej',
  'Keyboard Navigation': 'Navigation med tastatur',
  Version: 'Version',
  'Code view': 'Kodevisning',
  'Open popup menu for split buttons': '\u00C5ben popup menu for split knapper',
  'List Properties': 'List egenskaber',
  'List properties...': 'List egenskaber...',
  'Start list at number': 'Start liste ved nummer',
  'Line height': 'Linjeh\u00f8jde',
  comments: 'kommentarer',
  'Format Painter': 'Formatpensel',
  'Insert edit iframe': 'Inds\u00E6t rediger iframe',
  Capitalization: 'Store bogstaver',
  lowercase: 'sm\u00E5 bogstaver',
  UPPERCASE: 'STORE BOGSTAVER',
  'Title Case': 'Stort begyndelsesbogstav',
  'permanent pen': 'permanent pen',
  'Permanent Pen Properties': 'Permanente penegenskaber',
  'Permanent pen properties...': 'Permanente penegenskaber...',
  'case change': '\u00C6ndring af stort sm\u00E5t',
  'page embed': 'sideindlejring',
  'Advanced sort...': 'Avanceret sortering...',
  'Advanced Sort': 'Avanceret sortering',
  'Sort table by column ascending': 'Sorter tabel efter kolonne stigende',
  'Sort table by column descending': 'Sorter tabel efter kolonne faldende',
  Sort: 'Sorter',
  Order: 'R\u00E6kkef\u00f8lge',
  'Sort by': 'Sorter efter',
  Ascending: 'Stigende',
  Descending: 'Faldende',
  'Column {0}': 'Kolonne {0}',
  'Row {0}': 'R\u00E6kke {0}',
  'Spellcheck...': 'Stavekontrol...',
  'Misspelled word': 'Ord med stavefejl',
  Suggestions: 'Forslag',
  Change: '\u00C6ndring',
  'Finding word suggestions': 'Finder ordforslag',
  Success: 'Succes',
  Repair: 'Reparer',
  'Issue {0} of {1}': 'Problem {0} ud af {1}',
  'Images must be marked as decorative or have an alternative text description':
    'Billeder skal markeres som dekorative eller have en alternativ tekstbeskrivelse',
  'Images must have an alternative text description. Decorative images are not allowed.':
    'Billeder skal have en alternativ tekstbeskrivelse. Dekorative billeder er ikke tilladte.',
  'Or provide alternative text:': 'Eller angiv en alternativ tekst:',
  'Make image decorative:': 'G\u00f8r billede dekorativ:',
  'ID attribute must be unique': 'ID attribut skal v\u00E6re unik',
  'Make ID unique': 'G\u00f8r ID unik',
  'Keep this ID and remove all others': 'Behold dette ID og fjern alle andre',
  'Remove this ID': 'Fjern dette ID',
  'Remove all IDs': "Fjern alle ID'er",
  Checklist: 'Kontrolliste',
  Anchor: 'Ankor',
  'Special character': 'Specielle tegn',
  'Code sample': 'Kodepr\u00f8ve',
  Color: 'Farve',
  'Document properties': 'Dokumentegenskaber',
  'Image description': 'Billedbeskrivelse',
  Image: 'Billede',
  'Insert link': 'Inds\u00E6t link',
  Target: 'Target',
  Link: 'Link',
  Poster: 'Poster',
  Media: 'Medier',
  Print: 'Udskriv',
  Prev: 'Forrige',
  'Find and replace': 'Find og erstat',
  'Whole words': 'Hele ord',
  'Insert template': 'Inds\u00E6t skabelon',
  'Insert/edit image': 'Indsæt/rediger billede',
  'Insert/edit link': 'Indsæt eller rediger link',
  Jan: 'jan.',
  Feb: 'feb.',
  Mar: 'mar.',
  Apr: 'apr.',
  May: 'maj',
  Jun: 'jun.',
  Jul: 'jul.',
  Aug: 'aug.',
  Sep: 'sep.',
  Nov: 'nov.',
  Dec: 'dec.',
});
