




















































































import { PropType } from 'vue';
import { CommentModel } from '../../../shared/models/comment.model';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import * as dateUtil from '../../../shared/utils/dateUtil.js';
import capitalize from 'lodash/capitalize';
import Icon from '../../../shared/components/Icon.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Comment from '../comments/Comment.vue';
import { GetCommentsRequest } from '../../../shared/models/requests/getComments.request';
import { commentTypes } from '../../../shared/enums/commentTypes';
import { commentService } from '../../../shared/services/api/comment.service';
import CommentTextarea from '../comments/CommentTextarea.vue';
import { AddCommentRequest } from '../../../shared/models/requests/addComment.request';
import { textUtil } from '../../../shared/utils/textUtil';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { InstitutionModel } from '../../../shared/models/institution.model';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  components: { CommentTextarea, Comment, AulaButton, Icon },
  props: {
    comment: { type: Object as PropType<CommentModel>, required: true },
    showReportedContent: { type: Boolean, default: false },
    previewMode: { type: Boolean, default: false },
    canReply: { type: Boolean, default: false },
    highlightedCommentId: { type: Number, default: null },
    relatedProfiles: { type: Array as PropType<InstitutionProfileModel[]>, default: () => [] },
  },
  data() {
    return {
      isExpanded: false,
      isLoading: false,
      isSubmitting: false,
      isWritingReply: false,
      index: 0,
      replies: [] as CommentModel[],
      replyText: '',
      selectedProfile: null as InstitutionModel,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
    }),
    isHighlighted() {
      return this.highlightedCommentId === this.comment.id;
    },
    canSelectInstitution() {
      return this.relatedProfiles.length > 1 && this.selectedProfile?.role === portalRoles.EMPLOYEE;
    },
    institutionProfileId() {
      const relatedInstitution = this.selectedProfile?.institution;
      const institutionProfile = this.institutions.find(
        institution => institution.institutionCode === relatedInstitution?.institutionCode
      );
      return institutionProfile?.institutionProfileId || null;
    },
    creatorGenitiveName() {
      return textUtil.getGenitiveName(this.comment.creator.name);
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    commentDateTime() {
      const date = dateUtil.getRelativeDateTime(this.comment.createdAt);
      return capitalize(date);
    },
    replyCount() {
      return this.comment.commentCount;
    },
    hasMoreReplies() {
      return this.replies.length < this.replyCount;
    },
    canExpandReplies() {
      return !this.isExpanded && this.replyCount > 0;
    },
    hasRepliesExpanded() {
      if (!this.isExpanded) {
        return false;
      }
      return this.replies.length > 0 || this.isWritingReply;
    },
  },
  mounted() {
    this.selectedProfile = this.relatedProfiles[0];
  },
  methods: {
    selectProfile(profile) {
      this.selectedProfile = profile;
    },
    handleReplyClicked() {
      this.isWritingReply = true;
      if (!this.isExpanded) {
        this.expandReplies();
      }
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    async expandReplies() {
      this.isExpanded = true;
      this.replies = await this.getReplies();
    },
    async addReply() {
      const payload: AddCommentRequest = {
        creatorInstProfileId: this.institutionProfileId,
        commentableItem: {
          id: this.comment.id,
          type: commentTypes.COMMENT,
        },
        content: this.replyText,
      };
      this.isSubmitting = true;
      const reply = await commentService.addComment(payload);
      this.isSubmitting = false;
      this.replies.push(reply);
    },
    async getReplies() {
      const params: GetCommentsRequest = {
        parentType: commentTypes.COMMENT,
        parentId: this.comment.id,
        limit: 5,
        startIndex: this.index,
        includeReportedComments: this.showReportedContent,
      };
      this.isLoading = true;
      const { comments } = await commentService.getComments(params);
      this.isLoading = false;
      return comments;
    },
    async loadPreviousReplies() {
      this.index = this.replies.length;
      const previousReplies = await this.getReplies();
      this.replies.unshift(...previousReplies);
    },
    handleSubmitClicked() {
      this.handleSubmitReply();
    },
    async handleSubmitReply() {
      this.isExpanded = true;
      await this.addReply();
      this.resetReplyInput();
    },
    resetReplyInput() {
      this.replyText = '';
      this.isWritingReply = false;
    },
  },
};
