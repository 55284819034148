export const editorToolbarItems = Object.freeze({
  BASIC: [
    'foldFonts emoticons link youtube skoletube bornetube',
    'fontselect fontsizeselect bold italic underline strikethrough | blockquote backcolor forecolor | charmap | help',
    'alignleft aligncenter alignright | bullist numlist indent outdent | undo redo',
  ],
  NOTE: [
    'foldFonts link',
    'fontselect fontsizeselect bold italic underline strikethrough | blockquote backcolor forecolor | hr insertdatetime charmap searchreplace | help',
    'alignleft aligncenter alignright | bullist numlist indent outdent | link unlink | undo redo | fullscreen',
  ],
  EXTENDED: [
    'fontselect fontsizeselect bold italic underline strikethrough | blockquote backcolor forecolor | hr insertdatetime charmap searchreplace | help',
    'alignleft aligncenter alignright lineheight | bullist numlist indent outdent | link unlink | undo redo | fullscreen',
  ],
  ADVANCED: [
    'fontselect fontsizeselect bold italic underline strikethrough | blockquote backcolor forecolor | table image hr insertdatetime charmap searchreplace | help',
    'alignleft aligncenter alignright lineheight | bullist numlist indent outdent | link unlink | undo redo | fullscreen',
  ],
});
