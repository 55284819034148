
















































import { PropType } from 'vue';
import { PostModel } from '../../../shared/models/post.model';
import * as dateUtil from '../../../shared/utils/dateUtil.js';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import capitalize from 'lodash/capitalize';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import AvatarGroup from '../../../shared/components/AvatarGroup.vue';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import GroupMemberPopoverList from '../groups/GroupMemberPopoverList.vue';
import { portalRoles } from '../../../shared/enums/portalRoles';
import ContactAvatar from '../contacts/ContactAvatar.vue';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';

export default {
  components: { ContactAvatar, GroupMemberPopoverList, AvatarGroup, Icon },
  props: {
    post: { type: Object as PropType<PostModel>, required: true },
    previewMode: { type: Boolean, default: false },
  },
  emits: ['edit', 'delete', 'report'],
  computed: {
    ...mapGetters({
      hasPermission: types.HAS_PERMISSION,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      institutions: types.GET_INSTITUTIONS,
      children: types.GET_CHILDREN,
      loggedInProfile: types.GET_CURRENT_PROFILE,
      hasNonBlockingProfiles: types.GET_HAS_NON_BLOCKING_PROFILES,
      profile: types.GET_CURRENT_PROFILE,
    }),
    isBlockedCommunication() {
      return this.profile.communicationBlock && !this.hasNonBlockingProfiles;
    },
    canViewOwnerProfile() {
      if (this.previewMode) {
        return false;
      }
      if (this.loggedInProfile.role === portalRoles.EMPLOYEE) {
        return true;
      }
      return this.institutions.some(
        institution => institution.institutionCode === this.post.ownerProfile.institution.institutionCode
      );
    },
    canPerformActions() {
      if (this.previewMode) {
        return false;
      }
      return this.canEdit || this.post.canCurrentUserDelete || this.post.canCurrentUserReport;
    },
    canEdit() {
      const isUnblockedOwnerPost = this.institutions.some(
        institution =>
          institution.institutionProfileId === this.post.ownerProfile?.id && !institution.communicationBlock
      );
      const hasGroupDistribution = this.hasPermission(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS);
      const canEditGroupPost = this.post.sharedWithGroups.every(group =>
        this.hasPermissionGroup(permissionEnum.WRITE_POST, group.id)
      );
      return !this.post.isExpired && isUnblockedOwnerPost && (hasGroupDistribution || canEditGroupPost);
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    editedDateTime() {
      if (!this.post.editedAt) {
        return '';
      }
      const date = dateUtil.getRelativeDateTime(this.post.editedAt);
      const editedText = this.$options.filters.fromTextKey('EDITED');
      return capitalize(`${date} (${editedText})`);
    },
    createdDateTime() {
      const date = DateTimeUtil.formatDate(this.post.timestamp, dateFormatEnum.SHORT_DATE_TIME);
      const suffix = `(${this.$options.filters.fromTextKey('CREATED')})`;
      return capitalize(`${date} ${suffix}`);
    },
    publishDateTime() {
      const date = dateUtil.getRelativeDateTime(this.post.publishAt);
      let suffix = '';
      if (this.editedDateTime) {
        suffix = `(${this.$options.filters.fromTextKey('CREATED')})`;
      }
      if (!this.isPublished) {
        suffix = `(${this.$options.filters.fromTextKey('POSTS_PUBLISH_LABEL')})`;
      }
      return capitalize(`${date} ${suffix}`);
    },
    expiredDateTime() {
      if (!this.post.expireAt) {
        return '';
      }
      const date = dateUtil.format(this.post.expireAt, dateFormatEnum.SHORT_DATE);
      const suffix = `(${this.$options.filters.fromTextKey('POSTS_EXPIRE_LABEL')})`;
      return capitalize(`${date} ${suffix}`);
    },
    createdDateTimeTooltip() {
      return capitalize(dateUtil.format(this.post.timestamp, dateFormatEnum.FULL_DATE));
    },
    editedDateTimeTooltip() {
      return capitalize(dateUtil.format(this.post.editedAt, dateFormatEnum.FULL_DATE));
    },
    expiredDateTimeTooltip() {
      return capitalize(dateUtil.format(this.post.expireAt, dateFormatEnum.FULL_DATE));
    },
    publishDateTimeTooltip() {
      return capitalize(dateUtil.format(this.post.publishAt, dateFormatEnum.FULL_DATE));
    },
    canViewSharedProfiles() {
      return this.institutions?.length > 1 || this.children?.length > 1;
    },
    isPublished() {
      return this.post.isPublished;
    },
    relatedProfiles() {
      return this.post.relatedProfiles.map(profile => {
        if (profile.role === portalRoles.EMPLOYEE) {
          return this.institutions.find(institution => institution.institutionProfileId === profile.id);
        }
        return profile;
      });
    },
  },
  methods: {
    emitEdit() {
      this.$emit('edit');
    },
    emitDelete() {
      this.$emit('delete');
    },
    emitReport() {
      this.$emit('report');
    },
  },
};
