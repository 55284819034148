






















import { PropType } from 'vue';
import { PostModel } from '../../../shared/models/post.model';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

export default {
  components: { Icon, AulaButton },
  props: {
    post: { type: Object as PropType<PostModel>, required: true },
    isUnread: { type: Boolean, default: false },
  },
  data() {
    return {
      isOverflowed: false,
      isExpanded: false,
    };
  },
  computed: {
    buttonTextToggleExpansion() {
      if (this.isExpanded) {
        return this.$options.filters.fromTextKey('POSTS_BUTTON_SEELESS');
      }
      return this.$options.filters.fromTextKey('POSTS_BUTTON_SEEMORE');
    },
    buttonIconToggleExpansion() {
      if (this.isExpanded) {
        return iconClassEnum.UP_ARROW;
      }
      return iconClassEnum.DOWN_ARROW;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      const contentElement = this.$refs.contentWrapper;
      this.isOverflowed = contentElement.clientHeight < contentElement.scrollHeight;
      this.isExpanded = !this.isOverflowed;
    },
    expand() {
      this.isExpanded = true;
    },
  },
};
