
















import { PropType } from 'vue';
import { InstitutionProfileModel } from '../models/institutionProfile.model';
import Avatar from './Avatar.vue';
import Button from '../directives/button.js';

export default {
  name: 'AvatarGroup',
  components: { Avatar },
  directives: { Button },
  props: {
    profiles: { type: Array as PropType<InstitutionProfileModel[]>, required: true },
    limit: { type: Number, default: 3 },
    variant: { type: String, default: 'light' },
  },
  computed: {
    visibleProfiles() {
      return this.profiles.slice(0, this.limit);
    },
    numberOfExtraProfiles() {
      if (this.profiles.length <= this.limit) {
        return 0;
      }
      return this.profiles.length - this.limit + 1;
    },
    isSmallText() {
      return this.numberOfExtraProfiles.toString().length > 2;
    },
  },
};
