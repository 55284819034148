<template>
  <b-row class="document-sharing">
    <b-col cols="9" class="text">
      {{ name }}
    </b-col>
    <b-col cols="3" class="action-area d-flex justify-content-between align-items-start">
      <b-form-checkbox :disabled="readonly || !canEdit" :checked="checked" @change="onEditPermissionChanged">
        <span class="sr-only">{{ 'ARIA_LABEL_GIVE_DOCUMENT_SHARE' | fromTextKey({ username: name }) }}</span>
      </b-form-checkbox>
      <IconButton
        v-if="!readonly"
        icon="delete"
        @click="onRemoveDocumentSharing"
        @keydown.enter="onRemoveDocumentSharing"
      />
    </b-col>
  </b-row>
</template>
<script>
import IconButton from '../../../shared/components/IconButton';
export default {
  components: { IconButton },
  props: {
    name: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: true },
    checked: { type: Boolean, default: false },
  },
  methods: {
    onEditPermissionChanged(value) {
      this.$emit('change', value);
    },
    onRemoveDocumentSharing() {
      this.$emit('remove');
    },
  },
};
</script>
