// noinspection NonAsciiCharacters,JSNonASCIINames

import tinymce from 'tinymce/tinymce';
tinymce.Resource.add('tinymce.plugins.emoticons', {
  grinende_ansigt: {
    keywords: ['ansigt', 'smil', 'glad', 'glæde', ':D', 'grin'],
    char: '\ud83d\ude00',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sammenbidt_ansigt: {
    keywords: ['ansigt', 'grimasse', 'tænder'],
    char: '\ud83d\ude2c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  grin: {
    keywords: ['ansigt', 'glad', 'smil', 'glæde', 'kawaii'],
    char: '\ud83d\ude01',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ansigt_med_glædestårer: {
    keywords: ['ansigt', 'græder', 'tårer', 'tude', 'hulke', 'hyle', 'glad', 'glædestårer', 'haha'],
    char: '\ud83d\ude02',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ruller_rundt_på_gulvet_af_grin: {
    keywords: ['ansigt', 'ruller', 'gulvet', 'rofl', 'griner', 'lol', 'haha'],
    char: '\ud83e\udd23',
    fitzpatrick_scale: false,
    category: 'people',
  },
  fester: {
    keywords: ['ansigt', 'fejring', 'woohoo'],
    char: '\ud83e\udd73',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smiley: {
    keywords: ['ansigt', 'glad', 'glæde', 'haha', ':D', ':)', 'smil', 'sjov'],
    char: '\ud83d\ude03',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smil: {
    keywords: ['ansigt', 'glad', 'glæde', 'sjov', 'haha', 'griner', 'like', ':D', ':)'],
    char: '\ud83d\ude04',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sveddråbe_smiley: {
    keywords: ['ansigt', 'varm', 'glad', 'griner', 'sved', 'smil', 'lettelse'],
    char: '\ud83d\ude05',
    fitzpatrick_scale: false,
    category: 'people',
  },
  griner: {
    keywords: ['glad', 'glæde', 'lol', 'tilfreds', 'haha', 'ansigt', 'glad', 'XD', 'griner'],
    char: '\ud83d\ude06',
    fitzpatrick_scale: false,
    category: 'people',
  },
  uskyldig: {
    keywords: ['ansigt', 'engel', 'himlen', 'halo'],
    char: '\ud83d\ude07',
    fitzpatrick_scale: false,
    category: 'people',
  },
  blinke_smiley: {
    keywords: ['ansigt', 'glad', 'drilsk', 'hentyder', 'blinker', 'hemmelig', ';)', 'smil', 'øje'],
    char: '\ud83d\ude09',
    fitzpatrick_scale: false,
    category: 'people',
  },
  rødmer: {
    keywords: ['ansigt', 'smil', 'glad', 'rødmet', 'crush', 'forlegen', 'flov', 'genert', 'glæde'],
    char: '\ud83d\ude0a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smiler_lidt_ansigt: {
    keywords: ['ansigt', 'smil'],
    char: '\ud83d\ude42',
    fitzpatrick_scale: false,
    category: 'people',
  },
  vendt_på_hovedet_ansigt: {
    keywords: ['ansigt', 'omvendt', 'fjollet', 'smil'],
    char: '\ud83d\ude43',
    fitzpatrick_scale: false,
    category: 'people',
  },
  afslappet: {
    keywords: ['ansigt', 'rødme', 'massage', 'glæde'],
    char: '\u263a\ufe0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lækkert: {
    keywords: ['glad', 'glæde', 'tunge', 'smil', 'ansigt', 'fjollet', 'yummy', 'nom', 'delicious', 'savouring'],
    char: '\ud83d\ude0b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lettet: {
    keywords: ['ansigt', 'afslappet', 'phew', 'massage', 'glæde'],
    char: '\ud83d\ude0c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  hjerter_i_øjnene: {
    keywords: ['ansigt', 'kærlighed', 'like', 'affektion', 'valentines', 'forelskelse', 'crush', 'hjerte'],
    char: '\ud83d\ude0d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smilende_ansigt_med_tre_hjerter: {
    keywords: ['ansigt', 'kærlighed', 'like', 'affektion', 'valentines', 'forelskelse', 'crush', 'hjerter', 'adore'],
    char: '\ud83e\udd70',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kyssende_hjerte: {
    keywords: ['ansigt', 'kærlighed', 'like', 'affektion', 'valentines', 'forelskelse', 'kys'],
    char: '\ud83d\ude18',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kyssende: {
    keywords: ['kærlighed', 'like', 'ansigt', '3', 'valentines', 'forelskelse', 'kys'],
    char: '\ud83d\ude17',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kyssende_smilende_øjne: {
    keywords: ['ansigt', 'affektion', 'valentines', 'forelskelse', 'kys'],
    char: '\ud83d\ude19',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kyssende_lukkede_øjne: {
    keywords: ['ansigt', 'kærlighed', 'like', 'affektion', 'valentines', 'forelskelse', 'kys'],
    char: '\ud83d\ude1a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  rækker_tunge_med_blinkende_øje: {
    keywords: ['ansigt', 'prank', 'barnligt', 'legende', 'drilsk', 'smil', 'blinke', 'tunge'],
    char: '\ud83d\ude1c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  fjollet: {
    keywords: ['ansigt', 'fjollet', 'skør', 'gøglet', 'skør'],
    char: '\ud83e\udd2a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  løftet_øjenbryn: {
    keywords: ['ansigt', 'mistillid', 'skeptisk', 'misbilligelse', 'vantro', 'overraskelse'],
    char: '\ud83e\udd28',
    fitzpatrick_scale: false,
    category: 'people',
  },
  monokel: {
    keywords: ['ansigt', 'stuffy', 'wealthy'],
    char: '\ud83e\uddd0',
    fitzpatrick_scale: false,
    category: 'people',
  },
  rækker_tunge_med_lukkede_øjne: {
    keywords: ['ansigt', 'prank', 'legende', 'drilsk', 'smil', 'tunge'],
    char: '\ud83d\ude1d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  rækker_tunge: {
    keywords: ['ansigt', 'prank', 'barnligt', 'legende', 'drilsk', 'smil', 'tunge'],
    char: '\ud83d\ude1b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  penge_mund_ansigt: {
    keywords: ['ansigt', 'rig', 'dollar', 'penge'],
    char: '\ud83e\udd11',
    fitzpatrick_scale: false,
    category: 'people',
  },
  nørd_ansigt: {
    keywords: ['ansigt', 'nørdet', 'geek', 'tåbe'],
    char: '\ud83e\udd13',
    fitzpatrick_scale: false,
    category: 'people',
  },
  solbriller: {
    keywords: ['ansigt', 'cool', 'smil', 'sommer', 'strand', 'solbrille'],
    char: '\ud83d\ude0e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  stjerne_struck: {
    keywords: ['ansigt', 'smil', 'starry', 'øjne', 'grinende'],
    char: '\ud83e\udd29',
    fitzpatrick_scale: false,
    category: 'people',
  },
  klovneansigt: {
    keywords: ['ansigt'],
    char: '\ud83e\udd21',
    fitzpatrick_scale: false,
    category: 'people',
  },
  cowboy_hat_ansigt: {
    keywords: ['ansigt', 'cowgirl', 'hat'],
    char: '\ud83e\udd20',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kram: {
    keywords: ['ansigt', 'smil', 'kram'],
    char: '\ud83e\udd17',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lummer: {
    keywords: ['ansigt', 'smil', 'strid', 'led', 'streng', 'lummer', 'lumsk', 'prank', 'smug', 'sarkasme'],
    char: '\ud83d\ude0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ingen_mund: {
    keywords: ['ansigt', 'hellokitty'],
    char: '\ud83d\ude36',
    fitzpatrick_scale: false,
    category: 'people',
  },
  neutral_ansigt: {
    keywords: ['ligeglad', 'meh', ':|', 'neutral'],
    char: '\ud83d\ude10',
    fitzpatrick_scale: false,
    category: 'people',
  },
  udtryksløs: {
    keywords: ['ansigt', 'ligeglad', '-_-', 'meh', 'deadpan'],
    char: '\ud83d\ude11',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ubehaget: {
    keywords: [
      'ligeglad',
      'kedsom',
      'udtryksløst_ansigt',
      'seriøs',
      'sarkasme',
      'uimponeret',
      'skeptisk',
      'tvivlsom',
      'side_øje',
    ],
    char: '\ud83d\ude12',
    fitzpatrick_scale: false,
    category: 'people',
  },
  himlende_øjne: {
    keywords: ['ansigt', 'rullende_øjne', 'frustreret'],
    char: '\ud83d\ude44',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tænker: {
    keywords: ['ansigt', 'hmmm', 'tænk', 'overvej'],
    char: '\ud83e\udd14',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lyvende_ansigt: {
    keywords: ['ansigt', 'lyve', 'pinocchio'],
    char: '\ud83e\udd25',
    fitzpatrick_scale: false,
    category: 'people',
  },
  hånd_over_mund: {
    keywords: ['ansigt', 'ups', 'shock', 'overraskelse'],
    char: '\ud83e\udd2d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tyssende: {
    keywords: ['ansigt', 'quiet', 'shhh'],
    char: '\ud83e\udd2b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  symboler_over_mund: {
    keywords: ['ansigt', 'bander', 'sværger', 'skændes', 'bandeord', 'eksplosiv'],
    char: '\ud83e\udd2c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  eksploderende_hoved: {
    keywords: ['ansigt', 'chokeret', 'hjerne', 'eksploderet'],
    char: '\ud83e\udd2f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  genert: {
    keywords: ['ansigt', 'rødme', 'genert', 'smigret'],
    char: '\ud83d\ude33',
    fitzpatrick_scale: false,
    category: 'people',
  },
  skuffet: {
    keywords: ['ansigt', 'trist', 'ked_af_det', 'deprimeret', ':('],
    char: '\ud83d\ude1e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  bekymret: {
    keywords: ['ansigt', 'concern', 'nervøs', ':('],
    char: '\ud83d\ude1f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sur: {
    keywords: ['vred', 'ansigt', 'irriteret', 'frustreret'],
    char: '\ud83d\ude20',
    fitzpatrick_scale: false,
    category: 'people',
  },
  rasende: {
    keywords: ['sur', 'vred', 'hader', 'foragter'],
    char: '\ud83d\ude21',
    fitzpatrick_scale: false,
    category: 'people',
  },
  eftertænksom: {
    keywords: ['ansigt', 'ked_af_det', 'deprimeret'],
    char: '\ud83d\ude14',
    fitzpatrick_scale: false,
    category: 'people',
  },
  forvirret: {
    keywords: ['ansigt', 'ligeglad', 'hvad', 'underlig', 'hmmm', ':/'],
    char: '\ud83d\ude15',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lettere_rynket_pande_ansigt: {
    keywords: ['ansigt', 'panderynken', 'skuffet', 'trist', 'ked_af_det'],
    char: '\ud83d\ude41',
    fitzpatrick_scale: false,
    category: 'people',
  },
  forpint_ansigt: {
    keywords: ['ansigt', 'ked_af_det', 'frown'],
    char: '\u2639',
    fitzpatrick_scale: false,
    category: 'people',
  },
  udholdende: {
    keywords: ['ansigt', 'syg', 'nej', 'ked_af_det', 'oops'],
    char: '\ud83d\ude23',
    fitzpatrick_scale: false,
    category: 'people',
  },
  forbistret: {
    keywords: ['ansigt', 'forvirret', 'syg', 'utilpas', 'oops', ':S'],
    char: '\ud83d\ude16',
    fitzpatrick_scale: false,
    category: 'people',
  },
  træt_ansigt: {
    keywords: ['syg', 'jamre', 'ked_af_det', 'frustreret'],
    char: '\ud83d\ude2b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  træt: {
    keywords: ['ansigt', 'træt', 'søvnig', 'trist', 'frustreret', 'ked_af_det'],
    char: '\ud83d\ude29',
    fitzpatrick_scale: false,
    category: 'people',
  },
  bønfaldende: {
    keywords: ['ansigt', 'begging', 'mercy'],
    char: '\ud83e\udd7a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  triumf: {
    keywords: ['ansigt', 'gas', 'phew', 'stolt', 'pride'],
    char: '\ud83d\ude24',
    fitzpatrick_scale: false,
    category: 'people',
  },
  åben_mund: {
    keywords: ['ansigt', 'overraskelse', 'impressed', 'wow', 'whoa', ':O'],
    char: '\ud83d\ude2e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  skrig: {
    keywords: ['ansigt', 'munch', 'bange', 'omg'],
    char: '\ud83d\ude31',
    fitzpatrick_scale: false,
    category: 'people',
  },
  skræmt: {
    keywords: ['ansigt', 'bange', 'skrækslagen', 'nervøs', 'oops', 'hvad'],
    char: '\ud83d\ude28',
    fitzpatrick_scale: false,
    category: 'people',
  },
  koldsved: {
    keywords: ['ansigt', 'nervøs', 'sved'],
    char: '\ud83d\ude30',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tysset: {
    keywords: ['ansigt', 'woo', 'shh'],
    char: '\ud83d\ude2f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  panderynken: {
    keywords: ['ansigt', 'aw', 'rynket_pande', 'undrende'],
    char: '\ud83d\ude26',
    fitzpatrick_scale: false,
    category: 'people',
  },
  fortvivlet: {
    keywords: ['ansigt', 'overrumplet', 'nervøs'],
    char: '\ud83d\ude27',
    fitzpatrick_scale: false,
    category: 'people',
  },
  græder: {
    keywords: ['ansigt', 'tårer', 'trist', 'deprimeret', 'ked_af_det', ":'("],
    char: '\ud83d\ude22',
    fitzpatrick_scale: false,
    category: 'people',
  },
  skuffet_lettet: {
    keywords: ['ansigt', 'phew', 'sved', 'nervøs'],
    char: '\ud83d\ude25',
    fitzpatrick_scale: false,
    category: 'people',
  },
  savlende_ansigt: {
    keywords: ['ansigt', 'fråderen', 'savl'],
    char: '\ud83e\udd24',
    fitzpatrick_scale: false,
    category: 'people',
  },
  søvnig: {
    keywords: ['ansigt', 'træt', 'slappe_af', 'nap'],
    char: '\ud83d\ude2a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sved: {
    keywords: ['ansigt', 'varm', 'trist', 'træt', 'motion', 'træning'],
    char: '\ud83d\ude13',
    fitzpatrick_scale: false,
    category: 'people',
  },
  varm: {
    keywords: ['ansigt', 'feber', 'varme', 'rød', 'svedig'],
    char: '\ud83e\udd75',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kold: {
    keywords: ['ansigt', 'blå', 'freezing', 'frozen', 'frostbite', 'icicles'],
    char: '\ud83e\udd76',
    fitzpatrick_scale: false,
    category: 'people',
  },
  snøfter: {
    keywords: ['ansigt', 'græder', 'tårer', 'trist', 'ked_af_det', 'deprimeret'],
    char: '\ud83d\ude2d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  svimmel_ansigt: {
    keywords: ['udkørt', 'bevidstløs', 'xox', 'svimmel'],
    char: '\ud83d\ude35',
    fitzpatrick_scale: false,
    category: 'people',
  },
  forbløffet: {
    keywords: ['ansigt', 'xox', 'overrasket', 'forgiftet'],
    char: '\ud83d\ude32',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lynlås_mund_ansigt: {
    keywords: ['ansigt', 'lukket', 'lynlås', 'hemmelig'],
    char: '\ud83e\udd10',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kvalme_ansigt: {
    keywords: ['ansigt', 'bræk', 'ulækkert', 'grøn', 'syg', 'kaste op', 'ill'],
    char: '\ud83e\udd22',
    fitzpatrick_scale: false,
    category: 'people',
  },
  nyse_ansigt: {
    keywords: ['ansigt', 'gesundheit', 'nyse', 'syg', 'allergi'],
    char: '\ud83e\udd27',
    fitzpatrick_scale: false,
    category: 'people',
  },
  opkast: {
    keywords: ['ansigt', 'syg'],
    char: '\ud83e\udd2e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  maske: {
    keywords: ['ansigt', 'syg', 'ill', 'sygdom'],
    char: '\ud83d\ude37',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ansigt_med_termometer: {
    keywords: ['syg', 'temperatur', 'termometer', 'kold', 'feber'],
    char: '\ud83e\udd12',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ansigt_med_hovedbandage: {
    keywords: ['tilskadekommet', 'klodset', 'bandage', 'skade'],
    char: '\ud83e\udd15',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ørt: {
    keywords: ['ansigt', 'svimmel', 'beruset', 'tipsy', 'wavy'],
    char: '\ud83e\udd74',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sovende: {
    keywords: ['ansigt', 'træt', 'søvnig', 'nat', 'zzz'],
    char: '\ud83d\ude34',
    fitzpatrick_scale: false,
    category: 'people',
  },
  zzz: {
    keywords: ['søvnig', 'træt', 'drøm'],
    char: '\ud83d\udca4',
    fitzpatrick_scale: false,
    category: 'people',
  },
  bæ: {
    keywords: ['hankey', 'lorteansigt', 'fejle', 'lort', 'shit'],
    char: '\ud83d\udca9',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smilende_djævel: {
    keywords: ['djævel', 'horn'],
    char: '\ud83d\ude08',
    fitzpatrick_scale: false,
    category: 'people',
  },
  djævel: {
    keywords: ['djævel', 'sur', 'horn'],
    char: '\ud83d\udc7f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  japansk_monster: {
    keywords: ['monster', 'rød', 'mask', 'halloween', 'uhyggelig', 'uhyggelig', 'djævel', 'dæmon', 'japansk', 'ogre'],
    char: '\ud83d\udc79',
    fitzpatrick_scale: false,
    category: 'people',
  },
  japansk_trold: {
    keywords: ['rød', 'ond', 'mask', 'monster', 'uhyggelig', 'uhyggelig', 'japansk', 'goblin'],
    char: '\ud83d\udc7a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  dødningehoved: {
    keywords: ['kranie', 'skelet', 'uhyggelig', 'død'],
    char: '\ud83d\udc80',
    fitzpatrick_scale: false,
    category: 'people',
  },
  spøgelse: {
    keywords: ['halloween', 'spooky', 'uhyggelig'],
    char: '\ud83d\udc7b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  alien: {
    keywords: ['UFO', 'paul', 'underlig', 'rummet'],
    char: '\ud83d\udc7d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  robot: {
    keywords: ['computer', 'maskine', 'bot'],
    char: '\ud83e\udd16',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smiley_kat: {
    keywords: ['dyr', 'katte', 'glad', 'smil'],
    char: '\ud83d\ude3a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  smilende_kat: {
    keywords: ['dyr', 'katte', 'smil'],
    char: '\ud83d\ude38',
    fitzpatrick_scale: false,
    category: 'people',
  },
  glad_kat: {
    keywords: ['dyr', 'katte', 'haha', 'glad', 'tårer'],
    char: '\ud83d\ude39',
    fitzpatrick_scale: false,
    category: 'people',
  },
  hjerte_øjne_kat: {
    keywords: ['dyr', 'kærlighed', 'like', 'affektion', 'katte', 'valentines', 'hjerte'],
    char: '\ud83d\ude3b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lumsk_kat: {
    keywords: ['dyr', 'katte', 'lumsk'],
    char: '\ud83d\ude3c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kyssende_kat: {
    keywords: ['dyr', 'katte', 'kys'],
    char: '\ud83d\ude3d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  skrigende_kat: {
    keywords: ['dyr', 'katte', 'munch', 'bange', 'skrig'],
    char: '\ud83d\ude40',
    fitzpatrick_scale: false,
    category: 'people',
  },
  grædende_katte_ansigt: {
    keywords: ['dyr', 'tårer', 'tude', 'hulke', 'hyle', 'trist', 'katte', 'ked_af_det', 'græder'],
    char: '\ud83d\ude3f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  trutmund_kat: {
    keywords: ['dyr', 'katte'],
    char: '\ud83d\ude3e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  håndfladerne_op: {
    keywords: ['hænder', 'håndbevægelse', 'kopformet', 'bøn'],
    char: '\ud83e\udd32',
    fitzpatrick_scale: true,
    category: 'people',
  },
  løftede_hænder: {
    keywords: ['håndbevægelse', 'hurra', 'yea', 'fejring', 'hænder'],
    char: '\ud83d\ude4c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  klap: {
    keywords: ['hænder', 'ros', 'bifald', 'applaus', 'tillykke', 'yay'],
    char: '\ud83d\udc4f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  vinke: {
    keywords: ['hænder', 'håndbevægelse', 'farvel', 'solong', 'vi_ses', 'hej', 'hi', 'håndflade'],
    char: '\ud83d\udc4b',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ring_til_mig_hånd: {
    keywords: ['hænder', 'håndbevægelse', 'ring'],
    char: '\ud83e\udd19',
    fitzpatrick_scale: true,
    category: 'people',
  },
  '+1': {
    keywords: ['thumbsup', 'tommelfinger', 'ja', 'super', 'godt', 'enig', 'accepter', 'cool', 'hånd', 'like'],
    char: '\ud83d\udc4d',
    fitzpatrick_scale: true,
    category: 'people',
  },
  '-1': {
    keywords: ['thumbsdown', 'tommelfinger', 'nej', 'nej_tak', 'hånd'],
    char: '\ud83d\udc4e',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ansigtslag: {
    keywords: ['sur', 'vold', 'knytnæve', 'slå', 'angreb', 'hånd'],
    char: '\ud83d\udc4a',
    fitzpatrick_scale: true,
    category: 'people',
  },
  næve: {
    keywords: ['fingre', 'hånd', 'grasp'],
    char: '\u270a',
    fitzpatrick_scale: true,
    category: 'people',
  },
  næve_venstre: {
    keywords: ['hånd', 'fistbump'],
    char: '\ud83e\udd1b',
    fitzpatrick_scale: true,
    category: 'people',
  },
  næve_højre: {
    keywords: ['hånd', 'fistbump'],
    char: '\ud83e\udd1c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  v: {
    keywords: ['fingre', 'ohyeah', 'hånd', 'peace', 'sejr', 'to'],
    char: '\u270c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ok_hånd: {
    keywords: ['fingre', 'limbs', 'perfekt', 'ok', 'okay'],
    char: '\ud83d\udc4c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  løftet_hånd: {
    keywords: ['fingre', 'stop', 'highfive', 'håndflade', 'ban'],
    char: '\u270b',
    fitzpatrick_scale: true,
    category: 'people',
  },
  løftet_bagside_af_hånd: {
    keywords: ['fingre', 'løftet', 'baghånd'],
    char: '\ud83e\udd1a',
    fitzpatrick_scale: true,
    category: 'people',
  },
  åbne_hænder: {
    keywords: ['fingre', 'sommerfugl', 'hænder', 'åben'],
    char: '\ud83d\udc50',
    fitzpatrick_scale: true,
    category: 'people',
  },
  muskel: {
    keywords: ['arm', 'flex', 'hånd', 'sommer', 'stærk', 'biceps'],
    char: '\ud83d\udcaa',
    fitzpatrick_scale: true,
    category: 'people',
  },
  bøn: {
    keywords: ['please', 'håb', 'ønske', 'namaste', 'highfive'],
    char: '\ud83d\ude4f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  fod: {
    keywords: ['spark', 'stomp'],
    char: '\ud83e\uddb6',
    fitzpatrick_scale: true,
    category: 'people',
  },
  leg: {
    keywords: ['spark', 'limb'],
    char: '\ud83e\uddb5',
    fitzpatrick_scale: true,
    category: 'people',
  },
  give_hånd: {
    keywords: ['enighed', 'shake'],
    char: '\ud83e\udd1d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  peg_op: {
    keywords: ['hånd', 'fingre', 'vejledning', 'op'],
    char: '\u261d',
    fitzpatrick_scale: true,
    category: 'people',
  },
  peg_op_2: {
    keywords: ['fingre', 'hånd', 'vejledning', 'op'],
    char: '\ud83d\udc46',
    fitzpatrick_scale: true,
    category: 'people',
  },
  peg_ned: {
    keywords: ['fingre', 'hånd', 'vejledning', 'ned'],
    char: '\ud83d\udc47',
    fitzpatrick_scale: true,
    category: 'people',
  },
  peg_til_venstre: {
    keywords: ['vejledning', 'fingre', 'hånd', 'left'],
    char: '\ud83d\udc48',
    fitzpatrick_scale: true,
    category: 'people',
  },
  peg_til_højre: {
    keywords: ['fingre', 'hånd', 'vejledning', 'højre'],
    char: '\ud83d\udc49',
    fitzpatrick_scale: true,
    category: 'people',
  },
  fu: {
    keywords: ['hånd', 'fingre', 'slem', 'mellem_finger', 'midte', 'flipping'],
    char: '\ud83d\udd95',
    fitzpatrick_scale: true,
    category: 'people',
  },
  løftet_hånd_med_fingrene_spredte: {
    keywords: ['hånd', 'fingre', 'palm'],
    char: '\ud83d\udd90',
    fitzpatrick_scale: true,
    category: 'people',
  },
  elsker_dig: {
    keywords: ['hånd', 'fingre', 'håndbevægelse'],
    char: '\ud83e\udd1f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  metal: {
    keywords: ['hånd', 'fingre', 'onde_øje', 'rock_on'],
    char: '\ud83e\udd18',
    fitzpatrick_scale: true,
    category: 'people',
  },
  krydsede_fingre: {
    keywords: ['godt', 'heldigt'],
    char: '\ud83e\udd1e',
    fitzpatrick_scale: true,
    category: 'people',
  },
  vulcan_salute: {
    keywords: ['hånd', 'fingre', 'lev_længe_og_godt', 'star trek'],
    char: '\ud83d\udd96',
    fitzpatrick_scale: true,
    category: 'people',
  },
  skrive_hånd: {
    keywords: ['nedre_venstre_kuglepen', 'skrivebord', 'skriv', 'udforme'],
    char: '\u270d',
    fitzpatrick_scale: true,
    category: 'people',
  },
  selfie: {
    keywords: ['kamera', 'telefon'],
    char: '\ud83e\udd33',
    fitzpatrick_scale: true,
    category: 'people',
  },
  manicure: {
    keywords: ['skønhed', 'manicure', 'fingre', 'mode', 'negle'],
    char: '\ud83d\udc85',
    fitzpatrick_scale: true,
    category: 'people',
  },
  læber: {
    keywords: ['mund', 'kys'],
    char: '\ud83d\udc44',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tand: {
    keywords: ['tænder', 'tandlæge'],
    char: '\ud83e\uddb7',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tunge: {
    keywords: ['mund', 'legende'],
    char: '\ud83d\udc45',
    fitzpatrick_scale: false,
    category: 'people',
  },
  øre: {
    keywords: ['ansigt', 'høre', 'lyd', 'lytte'],
    char: '\ud83d\udc42',
    fitzpatrick_scale: true,
    category: 'people',
  },
  næse: {
    keywords: ['lugte', 'sniff'],
    char: '\ud83d\udc43',
    fitzpatrick_scale: true,
    category: 'people',
  },
  øje: {
    keywords: ['ansigt', 'se', 'se', 'watch', 'stirre'],
    char: '\ud83d\udc41',
    fitzpatrick_scale: false,
    category: 'people',
  },
  øjne: {
    keywords: ['se', 'watch', 'stale', 'titte', 'se'],
    char: '\ud83d\udc40',
    fitzpatrick_scale: false,
    category: 'people',
  },
  hjerne: {
    keywords: ['smart', 'intelligent'],
    char: '\ud83e\udde0',
    fitzpatrick_scale: false,
    category: 'people',
  },
  person_i_silhuette: {
    keywords: ['bruger', 'person', 'menneske'],
    char: '\ud83d\udc64',
    fitzpatrick_scale: false,
    category: 'people',
  },
  personer_i_silhuette: {
    keywords: ['bruger', 'person', 'menneske', 'gruppe', 'team'],
    char: '\ud83d\udc65',
    fitzpatrick_scale: false,
    category: 'people',
  },
  talende_hoved: {
    keywords: ['bruger', 'person', 'menneske', 'synge', 'sige', 'tale'],
    char: '\ud83d\udde3',
    fitzpatrick_scale: false,
    category: 'people',
  },
  baby: {
    keywords: ['barn', 'dreng', 'pige', 'spædbarn'],
    char: '\ud83d\udc76',
    fitzpatrick_scale: true,
    category: 'people',
  },
  barn: {
    keywords: ['kønsneutral', 'ung'],
    char: '\ud83e\uddd2',
    fitzpatrick_scale: true,
    category: 'people',
  },
  dreng: {
    keywords: ['mand', 'mand', 'guy', 'teenager'],
    char: '\ud83d\udc66',
    fitzpatrick_scale: true,
    category: 'people',
  },
  pige: {
    keywords: ['dame', 'kvinde', 'teenager'],
    char: '\ud83d\udc67',
    fitzpatrick_scale: true,
    category: 'people',
  },
  voksen: {
    keywords: ['kønsneutral', 'person'],
    char: '\ud83e\uddd1',
    fitzpatrick_scale: true,
    category: 'people',
  },
  man: {
    keywords: ['mustache', 'fader', 'far', 'guy', 'classy', 'sir', 'moustache'],
    char: '\ud83d\udc68',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde: {
    keywords: ['dame', 'pige', 'lady'],
    char: '\ud83d\udc69',
    fitzpatrick_scale: true,
    category: 'people',
  },
  blond_kvinde: {
    keywords: ['kvinde', 'dame', 'pige', 'blond', 'person'],
    char: '\ud83d\udc71\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  blond_mand: {
    keywords: ['mand', 'mand', 'dreng', 'blond', 'guy', 'person'],
    char: '\ud83d\udc71',
    fitzpatrick_scale: true,
    category: 'people',
  },
  skægget_person: {
    keywords: ['person', 'beskægget'],
    char: '\ud83e\uddd4',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ældre_voksen: {
    keywords: ['menneske', 'ældre', 'senior', 'kønsneutral'],
    char: '\ud83e\uddd3',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ældre_mand: {
    keywords: ['menneske', 'mand', 'men', 'gammel', 'ældre', 'senior'],
    char: '\ud83d\udc74',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ældre_kvinde: {
    keywords: ['menneske', 'dame', 'kvinder', 'lady', 'gammel', 'ældre', 'senior'],
    char: '\ud83d\udc75',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_med_gua_pi_mao: {
    keywords: ['mand', 'dreng', 'kinesisk'],
    char: '\ud83d\udc72',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_med_hovedtørklæde: {
    keywords: ['dame', 'hijab', 'mantilla', 'tichel'],
    char: '\ud83e\uddd5',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_med_turban: {
    keywords: ['dame', 'indisk', 'hinduism', 'araber', 'kvinde'],
    char: '\ud83d\udc73\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_med_turban: {
    keywords: ['mand', 'indisk', 'hinduism', 'araber'],
    char: '\ud83d\udc73',
    fitzpatrick_scale: true,
    category: 'people',
  },
  politikvinde: {
    keywords: ['kvinde', 'politi', 'loven', 'lov', 'håndhævelse', 'arresteret', '112', 'dame'],
    char: '\ud83d\udc6e\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  politimand: {
    keywords: ['mand', 'politi', 'loven', 'lov', 'håndhævelse', 'arresteret', '112'],
    char: '\ud83d\udc6e',
    fitzpatrick_scale: true,
    category: 'people',
  },
  konstruktionsarbejder_kvinde: {
    keywords: ['dame', 'menneske', 'wip', 'bygge', 'konstruktions', 'arbejder', 'arbejde', 'kvinde'],
    char: '\ud83d\udc77\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  konstruktions_arbejder_mand: {
    keywords: ['mand', 'menneske', 'wip', 'guy', 'bygge', 'konstruktions', 'arbejder', 'arbejde'],
    char: '\ud83d\udc77',
    fitzpatrick_scale: true,
    category: 'people',
  },
  vagtkvinde: {
    keywords: ['uk', 'gb', 'britisk', 'dame', 'royal', 'kvinde'],
    char: '\ud83d\udc82\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  vagtmand: {
    keywords: ['uk', 'gb', 'britisk', 'mand', 'guy', 'royal'],
    char: '\ud83d\udc82',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvindelig_detektiv: {
    keywords: ['menneske', 'spion', 'detektiv', 'dame', 'kvinde'],
    char: '\ud83d\udd75\ufe0f\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mandlig_detektiv: {
    keywords: ['menneske', 'spion', 'detektiv'],
    char: '\ud83d\udd75',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_sundhedsarbejder: {
    keywords: ['doktor', 'sundhedsplejerske', 'terapeut', 'sundhed', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\u2695\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_sundhedsarbejder: {
    keywords: ['doktor', 'sundhedsplejerske', 'terapeut', 'sundhed', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\u2695\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_bonde: {
    keywords: ['rancher', 'botanist', 'kvinde', 'landmand', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udf3e',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_farmer: {
    keywords: ['rancher', 'botanist', 'mand', 'landmand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udf3e',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_kok: {
    keywords: ['chef', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udf73',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_kok: {
    keywords: ['chef', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udf73',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_student: {
    keywords: ['graduate', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udf93',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_student: {
    keywords: ['graduate', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udf93',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_sanger: {
    keywords: ['rockstar', 'underholder', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udfa4',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_sanger: {
    keywords: ['rockstar', 'underholder', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udfa4',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_lærer: {
    keywords: ['instruktør', 'professor', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udfeb',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_lærer: {
    keywords: ['instruktør', 'professor', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udfeb',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_fabriksarbejder: {
    keywords: ['samlebånd', 'industri', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udfed',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_fabriksarbejder: {
    keywords: ['samlebånd', 'industri', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udfed',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_technologist: {
    keywords: ['koder', 'udvikler', 'ingeniør', 'programmer', 'software', 'kvinde', 'menneske', 'laptop', 'computer'],
    char: '\ud83d\udc69\u200d\ud83d\udcbb',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_technologist: {
    keywords: ['koder', 'udvikler', 'ingeniør', 'programmer', 'software', 'mand', 'menneske', 'laptop', 'computer'],
    char: '\ud83d\udc68\u200d\ud83d\udcbb',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_kontorarbejder: {
    keywords: ['business', 'manager', 'leder', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83d\udcbc',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_kontorarbejder: {
    keywords: ['business', 'manager', 'leder', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83d\udcbc',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_mekaniker: {
    keywords: ['plumber', 'kvinde', 'menneske', 'skruenøgle'],
    char: '\ud83d\udc69\u200d\ud83d\udd27',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_mekaniker: {
    keywords: ['plumber', 'mand', 'menneske', 'skruenøgle'],
    char: '\ud83d\udc68\u200d\ud83d\udd27',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_forsker: {
    keywords: ['biolog', 'kemiker', 'videnskabsmand', 'ingeniør', 'fysiker', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83d\udd2c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_forsker: {
    keywords: ['biolog', 'kemiker', 'ingeniør', 'fysiker', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83d\udd2c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_kunstner: {
    keywords: ['maler', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83c\udfa8',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_kunstner: {
    keywords: ['maler', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83c\udfa8',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_brandmand: {
    keywords: ['brandkvinde', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83d\ude92',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_brandmand: {
    keywords: ['brandmand', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83d\ude92',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_pilot: {
    keywords: ['flyver', 'fly', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\u2708\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_pilot: {
    keywords: ['flyver', 'fly', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\u2708\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_astronaut: {
    keywords: ['rummet', 'raket', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\ud83d\ude80',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_astronaut: {
    keywords: ['rummet', 'raket', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\ud83d\ude80',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_dommer: {
    keywords: ['retfærdighed', 'ret', 'kvinde', 'menneske'],
    char: '\ud83d\udc69\u200d\u2696\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_dommer: {
    keywords: ['retfærdighed', 'ret', 'mand', 'menneske'],
    char: '\ud83d\udc68\u200d\u2696\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_superhelt: {
    keywords: ['kvinde', 'dame', 'godt', 'heroine', 'superkræfter'],
    char: '\ud83e\uddb8\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_superhelt: {
    keywords: ['mand', 'mand', 'godt', 'hero', 'superkræfter'],
    char: '\ud83e\uddb8\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_superskurk: {
    keywords: ['kvinde', 'dame', 'ond', 'dårlig', 'kriminel', 'heroine', 'superkræfter'],
    char: '\ud83e\uddb9\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_superskurk: {
    keywords: ['mand', 'mand', 'ond', 'dårlig', 'kriminel', 'hero', 'superkræfter'],
    char: '\ud83e\uddb9\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mrs_claus: {
    keywords: ['kvinde', 'dame', 'xmas', 'juledamen', 'moderjul'],
    char: '\ud83e\udd36',
    fitzpatrick_scale: true,
    category: 'people',
  },
  julemanden: {
    keywords: ['festival', 'mand', 'mand', 'xmas', 'julemand'],
    char: '\ud83c\udf85',
    fitzpatrick_scale: true,
    category: 'people',
  },
  magiker: {
    keywords: ['kvinde', 'dame', 'mage', 'heks'],
    char: '\ud83e\uddd9\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  troldmand: {
    keywords: ['mand', 'mand', 'mage', 'trolddom'],
    char: '\ud83e\uddd9\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_elf: {
    keywords: ['kvinde', 'dame'],
    char: '\ud83e\udddd\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_elf: {
    keywords: ['mand', 'mand'],
    char: '\ud83e\udddd\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_vampyr: {
    keywords: ['kvinde', 'dame'],
    char: '\ud83e\udddb\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_vampyr: {
    keywords: ['mand', 'mand', 'dracula'],
    char: '\ud83e\udddb\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_zombie: {
    keywords: ['kvinde', 'dame', 'undead', 'gående dead'],
    char: '\ud83e\udddf\u200d\u2640\ufe0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  mand_zombie: {
    keywords: ['mand', 'mand', 'dracula', 'undead', 'gående dead'],
    char: '\ud83e\udddf\u200d\u2642\ufe0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kvinde_lampeånd: {
    keywords: ['kvinde', 'dame'],
    char: '\ud83e\uddde\u200d\u2640\ufe0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  mand_lampeånd: {
    keywords: ['mand', 'mand'],
    char: '\ud83e\uddde\u200d\u2642\ufe0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  havfrue: {
    keywords: ['kvinde', 'dame', 'havfrue', 'ariel'],
    char: '\ud83e\udddc\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  havmand: {
    keywords: ['mand', 'mand', 'triton'],
    char: '\ud83e\udddc\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_fe: {
    keywords: ['kvinde', 'dame'],
    char: '\ud83e\uddda\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_fe: {
    keywords: ['mand', 'mand'],
    char: '\ud83e\uddda\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  engel: {
    keywords: ['himlen', 'vinger', 'halo'],
    char: '\ud83d\udc7c',
    fitzpatrick_scale: true,
    category: 'people',
  },
  gravid_kvinde: {
    keywords: ['baby'],
    char: '\ud83e\udd30',
    fitzpatrick_scale: true,
    category: 'people',
  },
  amning: {
    keywords: ['børnepleje', 'baby'],
    char: '\ud83e\udd31',
    fitzpatrick_scale: true,
    category: 'people',
  },
  prinsesse: {
    keywords: ['pige', 'kvinde', 'dame', 'blond', 'krone', 'royal', 'dronning'],
    char: '\ud83d\udc78',
    fitzpatrick_scale: true,
    category: 'people',
  },
  prins: {
    keywords: ['dreng', 'mand', 'mand', 'krone', 'royal', 'konge'],
    char: '\ud83e\udd34',
    fitzpatrick_scale: true,
    category: 'people',
  },
  brud_med_slør: {
    keywords: ['par', 'ægteskab', 'bryllup', 'kvinde', 'brud'],
    char: '\ud83d\udc70',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_i_tuxedo: {
    keywords: ['par', 'ægteskab', 'bryllup', 'brudgom'],
    char: '\ud83e\udd35',
    fitzpatrick_scale: true,
    category: 'people',
  },
  løbende_kvinde: {
    keywords: ['kvinde', 'walking', 'motion', 'træning', 'kapløb', 'løbende', 'dame'],
    char: '\ud83c\udfc3\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  løbende_mand: {
    keywords: ['mand', 'gående', 'motion', 'træning', 'kapløb', 'løbende'],
    char: '\ud83c\udfc3',
    fitzpatrick_scale: true,
    category: 'people',
  },
  gående_kvinde: {
    keywords: ['menneske', 'fødder', 'skridt', 'kvinde', 'dame'],
    char: '\ud83d\udeb6\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  gående_mand: {
    keywords: ['menneske', 'fødder', 'skridt'],
    char: '\ud83d\udeb6',
    fitzpatrick_scale: true,
    category: 'people',
  },
  danser: {
    keywords: ['dame', 'pige', 'kvinde', 'sjov'],
    char: '\ud83d\udc83',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_danser: {
    keywords: ['mand', 'dreng', 'sjov', 'danser'],
    char: '\ud83d\udd7a',
    fitzpatrick_scale: true,
    category: 'people',
  },
  danser_kvinder: {
    keywords: ['dame', 'kanin', 'kvinder', 'piges'],
    char: '\ud83d\udc6f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  danser_mænd: {
    keywords: ['mand', 'kanin', 'men', 'drengs'],
    char: '\ud83d\udc6f\u200d\u2642\ufe0f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  par: {
    keywords: ['par', 'folk', 'menneske', 'kærlighed', 'date', 'dating', 'like', 'affektion', 'valentines', 'ægteskab'],
    char: '\ud83d\udc6b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  to_mænd_holder_hænder: {
    keywords: ['par', 'par', 'kærlighed', 'like', 'bromance', 'venskab', 'folk', 'menneske'],
    char: '\ud83d\udc6c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  to_kvinder_holder_hænder: {
    keywords: ['par', 'venskab', 'par', 'kærlighed', 'like', 'dame', 'folk', 'menneske'],
    char: '\ud83d\udc6d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  nejende_kvinde: {
    keywords: ['kvinde', 'bøje', 'dame', 'pige'],
    char: '\ud83d\ude47\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  nejende_mand: {
    keywords: ['mand', 'bøje', 'mand', 'dreng'],
    char: '\ud83d\ude47',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_facepalming: {
    keywords: ['mand', 'mand', 'dreng', 'vantro'],
    char: '\ud83e\udd26\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_facepalming: {
    keywords: ['kvinde', 'dame', 'pige', 'vantro'],
    char: '\ud83e\udd26\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_trækker_på_skuldrene: {
    keywords: ['kvinde', 'dame', 'pige', 'forvirret', 'ligeglad', 'tvivl'],
    char: '\ud83e\udd37',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_trækker_på_skuldrene: {
    keywords: ['mand', 'mand', 'dreng', 'forvirret', 'ligeglad', 'tvivl'],
    char: '\ud83e\udd37\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  tipper_hånd_kvinde: {
    keywords: ['dame', 'pige', 'kvinde', 'menneske', 'information'],
    char: '\ud83d\udc81',
    fitzpatrick_scale: true,
    category: 'people',
  },
  tipper_hånd_mand: {
    keywords: ['mand', 'dreng', 'mand', 'menneske', 'information'],
    char: '\ud83d\udc81\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  nej_tak_kvinde: {
    keywords: ['dame', 'pige', 'kvinde', 'nope'],
    char: '\ud83d\ude45',
    fitzpatrick_scale: true,
    category: 'people',
  },
  nej_tak_mand: {
    keywords: ['mand', 'dreng', 'mand', 'nope'],
    char: '\ud83d\ude45\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ok_kvinde: {
    keywords: ['kvinder', 'pige', 'dame', 'pink', 'menneske', 'kvinde'],
    char: '\ud83d\ude46',
    fitzpatrick_scale: true,
    category: 'people',
  },
  ok_mand: {
    keywords: ['men', 'dreng', 'mand', 'blå', 'menneske', 'mand'],
    char: '\ud83d\ude46\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  rejser_hånd_kvinde: {
    keywords: ['dame', 'pige', 'kvinde'],
    char: '\ud83d\ude4b',
    fitzpatrick_scale: true,
    category: 'people',
  },
  rejser_hånd_mand: {
    keywords: ['mand', 'dreng', 'mand'],
    char: '\ud83d\ude4b\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  trutmund_kvinde: {
    keywords: ['dame', 'pige', 'kvinde'],
    char: '\ud83d\ude4e',
    fitzpatrick_scale: true,
    category: 'people',
  },
  trutmund_mand: {
    keywords: ['mand', 'dreng', 'mand'],
    char: '\ud83d\ude4e\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  panderynken_kvinde: {
    keywords: ['dame', 'pige', 'kvinde', 'trist', 'deprimeret', 'afskrækket', 'ulykkelig'],
    char: '\ud83d\ude4d',
    fitzpatrick_scale: true,
    category: 'people',
  },
  panderynken_mand: {
    keywords: ['mand', 'dreng', 'mand', 'trist', 'deprimeret', 'afskrækket', 'ulykkelig'],
    char: '\ud83d\ude4d\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  frisure_kvinde: {
    keywords: ['dame', 'klippet', 'pige', 'kvinde'],
    char: '\ud83d\udc87',
    fitzpatrick_scale: true,
    category: 'people',
  },
  frisure_mand: {
    keywords: ['mand', 'klippet', 'dreng', 'mand'],
    char: '\ud83d\udc87\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  massage_kvinde: {
    keywords: ['dame', 'pige', 'kvinde', 'hoved'],
    char: '\ud83d\udc86',
    fitzpatrick_scale: true,
    category: 'people',
  },
  massage_mand: {
    keywords: ['mand', 'dreng', 'mand', 'hoved'],
    char: '\ud83d\udc86\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  kvinde_i_dampet_rum: {
    keywords: ['dame', 'kvinde', 'spa', 'dampbad', 'sauna'],
    char: '\ud83e\uddd6\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  mand_i_dampet_rum: {
    keywords: ['mand', 'mand', 'spa', 'dampbad', 'sauna'],
    char: '\ud83e\uddd6\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'people',
  },
  par_med_hjerte_kvinde_mand: {
    keywords: ['par', 'kærlighed', 'like', 'affektion', 'menneske', 'dating', 'valentines', 'ægteskab'],
    char: '\ud83d\udc91',
    fitzpatrick_scale: false,
    category: 'people',
  },
  par_med_hjerte_kvinde_kvinde: {
    keywords: ['par', 'kærlighed', 'like', 'affektion', 'menneske', 'dating', 'valentines', 'ægteskab'],
    char: '\ud83d\udc69\u200d\u2764\ufe0f\u200d\ud83d\udc69',
    fitzpatrick_scale: false,
    category: 'people',
  },
  par_med_hjerte_mand_mand: {
    keywords: ['par', 'kærlighed', 'like', 'affektion', 'menneske', 'dating', 'valentines', 'ægteskab'],
    char: '\ud83d\udc68\u200d\u2764\ufe0f\u200d\ud83d\udc68',
    fitzpatrick_scale: false,
    category: 'people',
  },
  parkys_mand_kvinde: {
    keywords: ['par', 'valentines', 'kærlighed', 'like', 'dating', 'ægteskab'],
    char: '\ud83d\udc8f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  parkys_kvinde_kvinde: {
    keywords: ['par', 'valentines', 'kærlighed', 'like', 'dating', 'ægteskab'],
    char: '\ud83d\udc69\u200d\u2764\ufe0f\u200d\ud83d\udc8b\u200d\ud83d\udc69',
    fitzpatrick_scale: false,
    category: 'people',
  },
  parkys_mand_mand: {
    keywords: ['par', 'valentines', 'kærlighed', 'like', 'dating', 'ægteskab'],
    char: '\ud83d\udc68\u200d\u2764\ufe0f\u200d\ud83d\udc8b\u200d\ud83d\udc68',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_kvinde_dreng: {
    keywords: ['hjem', 'forældre', 'barn', 'mom', 'far', 'fader', 'moder', 'folk', 'menneske'],
    char: '\ud83d\udc6a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_kvinde_pige: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'barn'],
    char: '\ud83d\udc68\u200d\ud83d\udc69\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_kvinde_pige_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_kvinde_dreng_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc69\u200d\ud83d\udc66\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_kvinde_pige_pige: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_kvinde_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc69\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_kvinde_pige: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc69\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_kvinde_pige_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_kvinde_dreng_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc69\u200d\ud83d\udc66\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_kvinde_pige_pige: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_mand_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc68\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_mand_pige: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc68\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_mand_pige_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc68\u200d\ud83d\udc67\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_mand_dreng_dreng: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc68\u200d\ud83d\udc66\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_mand_pige_pige: {
    keywords: ['hjem', 'forældre', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc68\u200d\ud83d\udc67\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_dreng: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'barn'],
    char: '\ud83d\udc69\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_pige: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'barn'],
    char: '\ud83d\udc69\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_pige_dreng: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_dreng_dreng: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc66\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_kvinde_pige_pige: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc69\u200d\ud83d\udc67\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_dreng: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'barn'],
    char: '\ud83d\udc68\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_pige: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'barn'],
    char: '\ud83d\udc68\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_pige_dreng: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc67\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_dreng_dreng: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc66\u200d\ud83d\udc66',
    fitzpatrick_scale: false,
    category: 'people',
  },
  familie_mand_pige_pige: {
    keywords: ['hjem', 'forælder', 'folk', 'menneske', 'børn'],
    char: '\ud83d\udc68\u200d\ud83d\udc67\u200d\ud83d\udc67',
    fitzpatrick_scale: false,
    category: 'people',
  },
  garn: {
    keywords: ['bold', 'crochet', 'strikke'],
    char: '\ud83e\uddf6',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tråd: {
    keywords: ['nål', 'sy', 'spole', 'streng'],
    char: '\ud83e\uddf5',
    fitzpatrick_scale: false,
    category: 'people',
  },
  frakke: {
    keywords: ['jakke'],
    char: '\ud83e\udde5',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kittel: {
    keywords: ['doktor', 'eksperiment', 'forsker', 'kemiker'],
    char: '\ud83e\udd7c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kvindes_tøj: {
    keywords: ['mode', 'shopping_bags', 'dame'],
    char: '\ud83d\udc5a',
    fitzpatrick_scale: false,
    category: 'people',
  },
  trøje: {
    keywords: ['mode', 'tøj', 'afslappet', 'trøje', 'tee'],
    char: '\ud83d\udc55',
    fitzpatrick_scale: false,
    category: 'people',
  },
  jeans: {
    keywords: ['mode', 'bukser', 'shopping'],
    char: '\ud83d\udc56',
    fitzpatrick_scale: false,
    category: 'people',
  },
  slips: {
    keywords: ['skjorte', 'suitup', 'formel', 'mode', 'tøj', 'business'],
    char: '\ud83d\udc54',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kjole: {
    keywords: ['tøj', 'mode', 'shopping'],
    char: '\ud83d\udc57',
    fitzpatrick_scale: false,
    category: 'people',
  },
  bikini: {
    keywords: ['svømme', 'dame', 'kvinde', 'pige', 'mode', 'strand', 'sommer'],
    char: '\ud83d\udc59',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kimono: {
    keywords: ['kjole', 'mode', 'kvinder', 'dame', 'japansk'],
    char: '\ud83d\udc58',
    fitzpatrick_scale: false,
    category: 'people',
  },
  løbestik: {
    keywords: ['dame', 'pige', 'mode', 'kvinde'],
    char: '\ud83d\udc84',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kys: {
    keywords: ['ansigt', 'læber', 'kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc8b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  fodtryk: {
    keywords: ['fødder', 'forfølge', 'gående', 'strand'],
    char: '\ud83d\udc63',
    fitzpatrick_scale: false,
    category: 'people',
  },
  gummisko: {
    keywords: ['sko', 'slip-on', 'slipper'],
    char: '\ud83e\udd7f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  højhælet: {
    keywords: ['mode', 'skos', 'dame', 'pumps', 'stiletter'],
    char: '\ud83d\udc60',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sandal: {
    keywords: ['skos', 'mode', 'klipklapper', 'flip flops'],
    char: '\ud83d\udc61',
    fitzpatrick_scale: false,
    category: 'people',
  },
  støvle: {
    keywords: ['skos', 'mode'],
    char: '\ud83d\udc62',
    fitzpatrick_scale: false,
    category: 'people',
  },
  herre_sko: {
    keywords: ['mode', 'mand'],
    char: '\ud83d\udc5e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  atletik_sko: {
    keywords: ['skos', 'sport', 'sneakers'],
    char: '\ud83d\udc5f',
    fitzpatrick_scale: false,
    category: 'people',
  },
  vandre_støvle: {
    keywords: ['backpacking', 'camping', 'vandre'],
    char: '\ud83e\udd7e',
    fitzpatrick_scale: false,
    category: 'people',
  },
  strømper: {
    keywords: ['strømper', 'tøj'],
    char: '\ud83e\udde6',
    fitzpatrick_scale: false,
    category: 'people',
  },
  handsker: {
    keywords: ['hænder', 'vinter', 'tøj'],
    char: '\ud83e\udde4',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tørklæde: {
    keywords: ['neck', 'vinter', 'tøj'],
    char: '\ud83e\udde3',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kvindes_hat: {
    keywords: ['mode', 'accessories', 'dame', 'lady', 'forår'],
    char: '\ud83d\udc52',
    fitzpatrick_scale: false,
    category: 'people',
  },
  tophat: {
    keywords: ['magi', 'gentleman', 'classy', 'cirkus'],
    char: '\ud83c\udfa9',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kasket_hat: {
    keywords: ['cap', 'baseball'],
    char: '\ud83e\udde2',
    fitzpatrick_scale: false,
    category: 'people',
  },
  rednings_arbejder_hjelm: {
    keywords: ['konstruktions', 'bygge'],
    char: '\u26d1',
    fitzpatrick_scale: false,
    category: 'people',
  },
  studenterhat: {
    keywords: [
      'skole',
      'uni',
      'uddannelse',
      'universitet',
      'afgangseksamen ',
      'cap',
      'hat',
      'lov',
      'lære',
      'uddannelse',
    ],
    char: '\ud83c\udf93',
    fitzpatrick_scale: false,
    category: 'people',
  },
  krone: {
    keywords: ['konge', 'kod', 'leder', 'royal', 'lord'],
    char: '\ud83d\udc51',
    fitzpatrick_scale: false,
    category: 'people',
  },
  skoletaske: {
    keywords: ['student', 'uddannelse', 'taske', 'rygsæk'],
    char: '\ud83c\udf92',
    fitzpatrick_scale: false,
    category: 'people',
  },
  bagage: {
    keywords: ['pakke', 'rejse'],
    char: '\ud83e\uddf3',
    fitzpatrick_scale: false,
    category: 'people',
  },
  clutch: {
    keywords: ['taske', 'accessories', 'shopping'],
    char: '\ud83d\udc5d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  pung: {
    keywords: ['mode', 'accessories', 'penge', 'salg', 'shopping'],
    char: '\ud83d\udc5b',
    fitzpatrick_scale: false,
    category: 'people',
  },
  håndtaske: {
    keywords: ['mode', 'accessory', 'accessories', 'shopping'],
    char: '\ud83d\udc5c',
    fitzpatrick_scale: false,
    category: 'people',
  },
  kuffert: {
    keywords: ['business', 'dokumenter', 'arbejde', 'loven', 'lov', 'job', 'karriere'],
    char: '\ud83d\udcbc',
    fitzpatrick_scale: false,
    category: 'people',
  },
  briller: {
    keywords: ['mode', 'accessories', 'nørdet', 'tåbe', 'geek'],
    char: '\ud83d\udc53',
    fitzpatrick_scale: false,
    category: 'people',
  },
  mørk_solbriller: {
    keywords: ['ansigt', 'cool', 'accessories'],
    char: '\ud83d\udd76',
    fitzpatrick_scale: false,
    category: 'people',
  },
  sikkerhedsbriller: {
    keywords: ['øjne', 'beskyttelse', 'sikkerhed'],
    char: '\ud83e\udd7d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  ring: {
    keywords: [
      'bryllup',
      'fri',
      'ægteskab',
      'valentines',
      'diamant',
      'mode',
      'smykker',
      'ædelsten',
      'gift',
      'viet',
      'vielsesring',
    ],
    char: '\ud83d\udc8d',
    fitzpatrick_scale: false,
    category: 'people',
  },
  lukket_paraply: {
    keywords: ['vejret', 'regn', 'drypper'],
    char: '\ud83c\udf02',
    fitzpatrick_scale: false,
    category: 'people',
  },
  hund: {
    keywords: ['dyr', 'ven', 'natur', 'woof', 'hvalp', 'kæledyr', 'troskab'],
    char: '\ud83d\udc36',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kat: {
    keywords: ['dyr', 'meow', 'natur', 'kæledyr', 'killing'],
    char: '\ud83d\udc31',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  mus: {
    keywords: ['dyr', 'natur', 'ost', 'rodent'],
    char: '\ud83d\udc2d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hamster: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc39',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kanin: {
    keywords: ['dyr', 'natur', 'kæledyr', 'hoppe', 'magi', 'kanin'],
    char: '\ud83d\udc30',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ræv_ansigt: {
    keywords: ['dyr', 'natur', 'ansigt'],
    char: '\ud83e\udd8a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bjørn: {
    keywords: ['dyr', 'natur', 'vildt'],
    char: '\ud83d\udc3b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  panda_ansigt: {
    keywords: ['dyr', 'natur', 'panda'],
    char: '\ud83d\udc3c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  koala: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc28',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tiger: {
    keywords: ['dyr', 'cat', 'fare', 'vildt', 'natur', 'roar'],
    char: '\ud83d\udc2f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  løve: {
    keywords: ['dyr', 'natur'],
    char: '\ud83e\udd81',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ko: {
    keywords: ['kød', 'okse', 'dyr', 'natur', 'moo', 'mælk'],
    char: '\ud83d\udc2e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  gris: {
    keywords: ['dyr', 'oink', 'natur'],
    char: '\ud83d\udc37',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  grisenæse: {
    keywords: ['dyr', 'oink'],
    char: '\ud83d\udc3d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  frø: {
    keywords: ['dyr', 'natur', 'kvæk', 'tudse'],
    char: '\ud83d\udc38',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  blæksprutte: {
    keywords: ['dyr', 'natur', 'havet', 'vand'],
    char: '\ud83e\udd91',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  blæksprutte_8_armet: {
    keywords: ['dyr', 'kreatur', 'havet', 'til_søs', 'natur', 'strand'],
    char: '\ud83d\udc19',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  reje: {
    keywords: ['dyr', 'havet', 'natur', 'seafood'],
    char: '\ud83e\udd90',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  abe_ansigt: {
    keywords: ['dyr', 'natur', 'cirkus'],
    char: '\ud83d\udc35',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  gorilla: {
    keywords: ['dyr', 'natur', 'cirkus'],
    char: '\ud83e\udd8d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  abe_holder_sig_for_øjnene: {
    keywords: ['abe', 'dyr', 'natur', 'haha'],
    char: '\ud83d\ude48',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  abe_holder_sig_for_ørerne: {
    keywords: ['dyr', 'abe', 'natur'],
    char: '\ud83d\ude49',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  abe_holder_sig_for_munden: {
    keywords: ['abe', 'dyr', 'natur', 'omg'],
    char: '\ud83d\ude4a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  abe: {
    keywords: ['dyr', 'natur', 'banan', 'cirkus'],
    char: '\ud83d\udc12',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kylling: {
    keywords: ['dyr', 'kulk', 'natur', 'fugl'],
    char: '\ud83d\udc14',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  pingvin: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc27',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bird: {
    keywords: ['dyr', 'natur', 'fly', 'tweet', 'forår'],
    char: '\ud83d\udc26',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  baby_høne: {
    keywords: ['dyr', 'kylling', 'fugl'],
    char: '\ud83d\udc24',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  klækkende_kylling: {
    keywords: ['dyr', 'kylling', 'æg', 'født', 'baby', 'fugl'],
    char: '\ud83d\udc23',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  udklækket_kylling: {
    keywords: ['dyr', 'kylling', 'baby', 'fugl'],
    char: '\ud83d\udc25',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  and: {
    keywords: ['dyr', 'natur', 'fugl', 'mallard'],
    char: '\ud83e\udd86',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ørn: {
    keywords: ['dyr', 'natur', 'fugl'],
    char: '\ud83e\udd85',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ugle: {
    keywords: ['dyr', 'natur', 'fugl', 'hoot'],
    char: '\ud83e\udd89',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  flagermus: {
    keywords: ['dyr', 'natur', 'blind', 'vampyr'],
    char: '\ud83e\udd87',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ulv: {
    keywords: ['dyr', 'natur', 'vildt'],
    char: '\ud83d\udc3a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  vildsvin: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc17',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hest: {
    keywords: ['dyr', 'brun', 'natur'],
    char: '\ud83d\udc34',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  enhjørning: {
    keywords: ['dyr', 'natur', 'horn', 'mystisk'],
    char: '\ud83e\udd84',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bi: {
    keywords: ['dyr', 'insekt', 'natur', 'bug', 'forår', 'honning'],
    char: '\ud83d\udc1d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  insekt: {
    keywords: ['dyr', 'insekt', 'natur', 'orm'],
    char: '\ud83d\udc1b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sommerfugl: {
    keywords: ['dyr', 'insekt', 'natur', 'larve'],
    char: '\ud83e\udd8b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  snegle: {
    keywords: ['langsom', 'dyr', 'skal'],
    char: '\ud83d\udc0c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bille: {
    keywords: ['dyr', 'insekt', 'natur', 'mariehøne'],
    char: '\ud83d\udc1e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  myre: {
    keywords: ['dyr', 'insekt', 'natur', 'bug'],
    char: '\ud83d\udc1c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  græshoppe: {
    keywords: ['dyr', 'fårekylling', 'chirp'],
    char: '\ud83e\udd97',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  edderkop: {
    keywords: ['dyr', 'arachnid'],
    char: '\ud83d\udd77',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  skorpion: {
    keywords: ['dyr', 'arachnid'],
    char: '\ud83e\udd82',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  krabbe: {
    keywords: ['dyr', 'crustacean'],
    char: '\ud83e\udd80',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  slange: {
    keywords: ['dyr', 'ond', 'natur', 'hiss', 'python'],
    char: '\ud83d\udc0d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  øgle: {
    keywords: ['dyr', 'natur', 'reptil'],
    char: '\ud83e\udd8e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  't-rex': {
    keywords: ['dyr', 'natur', 'dinosaur', 'tyrannosaurus', 'uddød'],
    char: '\ud83e\udd96',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sauropod: {
    keywords: ['dyr', 'natur', 'dinosaur', 'brachiosaurus', 'brontosaurus', 'diplodocus', 'uddød'],
    char: '\ud83e\udd95',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  skildpadde: {
    keywords: ['dyr', 'langsom', 'natur', 'tortoise'],
    char: '\ud83d\udc22',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tropisk_fisk: {
    keywords: ['dyr', 'svømme', 'havet', 'strand', 'nemo'],
    char: '\ud83d\udc20',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  fisk: {
    keywords: ['dyr', 'mad', 'natur'],
    char: '\ud83d\udc1f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  blæsefisk: {
    keywords: ['dyr', 'natur', 'mad', 'til_søs', 'havet'],
    char: '\ud83d\udc21',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  delfin: {
    keywords: ['dyr', 'natur', 'fisk', 'til_søs', 'havet', 'flipper', 'finner', 'strand'],
    char: '\ud83d\udc2c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  haj: {
    keywords: ['dyr', 'natur', 'fisk', 'til_søs', 'havet', 'jaws', 'finner', 'strand'],
    char: '\ud83e\udd88',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hval: {
    keywords: ['dyr', 'natur', 'til_søs', 'havet'],
    char: '\ud83d\udc33',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hval2: {
    keywords: ['dyr', 'natur', 'til_søs', 'havet'],
    char: '\ud83d\udc0b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  krokodille: {
    keywords: ['dyr', 'natur', 'reptil', 'øgle', 'alligator'],
    char: '\ud83d\udc0a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  leopard: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc06',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  zebra: {
    keywords: ['dyr', 'natur', 'striber', 'safari'],
    char: '\ud83e\udd93',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tiger2: {
    keywords: ['dyr', 'natur', 'roar'],
    char: '\ud83d\udc05',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  vandbøffel: {
    keywords: ['dyr', 'natur', 'okse', 'ko'],
    char: '\ud83d\udc03',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  okse: {
    keywords: ['dyr', 'ko', 'kød'],
    char: '\ud83d\udc02',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ko2: {
    keywords: ['kød', 'okse', 'dyr', 'natur', 'moo', 'mælk'],
    char: '\ud83d\udc04',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hjort: {
    keywords: ['dyr', 'natur', 'rådyr', 'horn', 'vildt'],
    char: '\ud83e\udd8c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  dromedar_kamel: {
    keywords: ['dyr', 'varm', 'ørken', 'hump'],
    char: '\ud83d\udc2a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kamel: {
    keywords: ['dyr', 'natur', 'varm', 'ørken', 'pukkel'],
    char: '\ud83d\udc2b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  giraf: {
    keywords: ['dyr', 'natur', 'pletter', 'safari'],
    char: '\ud83e\udd92',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  elefant: {
    keywords: ['dyr', 'natur', 'næse', 'th', 'cirkus'],
    char: '\ud83d\udc18',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  næsehorn: {
    keywords: ['dyr', 'natur', 'horn'],
    char: '\ud83e\udd8f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ged: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc10',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  buk: {
    keywords: ['dyr', 'får', 'natur'],
    char: '\ud83d\udc0f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  får: {
    keywords: ['dyr', 'natur', 'uld', 'skibit'],
    char: '\ud83d\udc11',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  væddeløbshest: {
    keywords: ['dyr', 'spille', 'held'],
    char: '\ud83d\udc0e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  gris2: {
    keywords: ['dyr', 'natur'],
    char: '\ud83d\udc16',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  rotte: {
    keywords: ['dyr', 'mus', 'rodent'],
    char: '\ud83d\udc00',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  mus2: {
    keywords: ['dyr', 'natur', 'rodent'],
    char: '\ud83d\udc01',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hane: {
    keywords: ['dyr', 'natur', 'kylling'],
    char: '\ud83d\udc13',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kalkun: {
    keywords: ['dyr', 'fugl'],
    char: '\ud83e\udd83',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  due: {
    keywords: ['dyr', 'fugl'],
    char: '\ud83d\udd4a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hund2: {
    keywords: ['dyr', 'natur', 'ven', 'doge', 'kæledyr', 'troskab'],
    char: '\ud83d\udc15',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  poodle: {
    keywords: ['dog', 'dyr', '101', 'natur', 'kæledyr'],
    char: '\ud83d\udc29',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kat2: {
    keywords: ['dyr', 'meow', 'kæledyr', 'katte'],
    char: '\ud83d\udc08',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kanin2: {
    keywords: ['dyr', 'natur', 'kæledyr', 'magi', 'forår'],
    char: '\ud83d\udc07',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  jordegern: {
    keywords: ['dyr', 'natur', 'rodent', 'egern'],
    char: '\ud83d\udc3f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  høg: {
    keywords: ['dyr', 'natur', 'spiny'],
    char: '\ud83e\udd94',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  vaskebjørn: {
    keywords: ['dyr', 'natur'],
    char: '\ud83e\udd9d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  lama: {
    keywords: ['dyr', 'natur', 'alpaca'],
    char: '\ud83e\udd99',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  flodhest: {
    keywords: ['dyr', 'natur'],
    char: '\ud83e\udd9b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kænguru: {
    keywords: ['dyr', 'natur', 'australien', 'joey', 'hoppe', 'marsupial'],
    char: '\ud83e\udd98',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bæver: {
    keywords: ['dyr', 'natur', 'honning'],
    char: '\ud83e\udda1',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  svane: {
    keywords: ['dyr', 'natur', 'fugl'],
    char: '\ud83e\udda2',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  påfugl: {
    keywords: ['dyr', 'natur', 'peahen', 'fugl'],
    char: '\ud83e\udd9a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  papegøje: {
    keywords: ['dyr', 'natur', 'fugl', 'pirat', 'tale'],
    char: '\ud83e\udd9c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hummer: {
    keywords: ['dyr', 'natur', 'bisque', 'klør', 'seafood'],
    char: '\ud83e\udd9e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  myg: {
    keywords: ['dyr', 'natur', 'insekt', 'malaria'],
    char: '\ud83e\udd9f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  poteaftryk: {
    keywords: ['dyr', 'forfølge', 'fodtryk', 'dog', 'cat', 'kæledyr', 'fødder'],
    char: '\ud83d\udc3e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  drage: {
    keywords: ['dyr', 'myte', 'natur', 'kinesisk', 'grøn'],
    char: '\ud83d\udc09',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  drage_ansigt: {
    keywords: ['dyr', 'myte', 'natur', 'kinesisk', 'grøn'],
    char: '\ud83d\udc32',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kaktus: {
    keywords: ['vegetabilsk', 'plante', 'natur'],
    char: '\ud83c\udf35',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  juletræ: {
    keywords: ['festival', 'ferie', 'december', 'xmas', 'jul', 'fejring'],
    char: '\ud83c\udf84',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  juletræ2: {
    keywords: ['plant', 'natur'],
    char: '\ud83c\udf32',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  løvtræ: {
    keywords: ['plant', 'natur'],
    char: '\ud83c\udf33',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  palme: {
    keywords: ['plante', 'vegetabilsk', 'natur', 'sommer', 'strand', 'mojito', 'tropisk'],
    char: '\ud83c\udf34',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  frøplante: {
    keywords: ['plant', 'natur', 'græs', 'plæne', 'forår'],
    char: '\ud83c\udf31',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  urt: {
    keywords: ['vegetabilsk', 'plante', 'medicin', 'weed', 'græs', 'plæne'],
    char: '\ud83c\udf3f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  trekløver: {
    keywords: ['vegetabilsk', 'plant', 'natur', 'irsk', 'kløver'],
    char: '\u2618',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  firkløver: {
    keywords: ['vegetabilsk', 'plant', 'natur', 'heldigt', 'irsk'],
    char: '\ud83c\udf40',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bambus: {
    keywords: ['plant', 'natur', 'vegetabilsk', 'panda', 'pine_dekoration'],
    char: '\ud83c\udf8d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tanabata_træ: {
    keywords: ['plant', 'natur', 'afgrening', 'sommer'],
    char: '\ud83c\udf8b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  blade: {
    keywords: ['natur', 'plant', 'træ', 'vegetabilsk', 'græs', 'plæne', 'forår'],
    char: '\ud83c\udf43',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  efterårsblade: {
    keywords: ['natur', 'plant', 'faldende', 'vegetabilsk', 'blade'],
    char: '\ud83c\udf42',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ahornblad: {
    keywords: ['natur', 'plant', 'vegetabilsk', 'canada', 'efterår'],
    char: '\ud83c\udf41',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  risplante: {
    keywords: ['natur', 'plant'],
    char: '\ud83c\udf3e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  hibiscus: {
    keywords: ['plant', 'vegetabilsk', 'blomster', 'strand'],
    char: '\ud83c\udf3a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  solsikke: {
    keywords: ['natur', 'plant', 'efterår'],
    char: '\ud83c\udf3b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  rose: {
    keywords: ['blomster', 'valentines', 'kærlighed', 'forår'],
    char: '\ud83c\udf39',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  visnet_blomst: {
    keywords: ['plant', 'natur', 'blomst'],
    char: '\ud83e\udd40',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tulipan: {
    keywords: ['blomster', 'plant', 'natur', 'sommer', 'forår'],
    char: '\ud83c\udf37',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  blomst: {
    keywords: ['natur', 'blomster', 'gul'],
    char: '\ud83c\udf3c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  kirsebærblomst: {
    keywords: ['natur', 'plant', 'forår', 'blomst'],
    char: '\ud83c\udf38',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  bouquet: {
    keywords: ['blomster', 'natur', 'forår'],
    char: '\ud83d\udc90',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  svamp: {
    keywords: ['plant', 'vegetabilsk'],
    char: '\ud83c\udf44',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  valnød: {
    keywords: ['mad', 'egern'],
    char: '\ud83c\udf30',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  græskarlanterne: {
    keywords: ['halloween', 'lys', 'græskar', 'uhyggelig', 'efterår'],
    char: '\ud83c\udf83',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  skal: {
    keywords: ['natur', 'til_søs', 'strand'],
    char: '\ud83d\udc1a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  edderkoppespind: {
    keywords: ['dyr', 'insekt', 'spindelvæv', 'arachnid', 'silke'],
    char: '\ud83d\udd78',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  jorden_amerika: {
    keywords: ['globe', 'verden', 'USA', 'international'],
    char: '\ud83c\udf0e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  jorden_afrika: {
    keywords: ['globe', 'verden', 'international'],
    char: '\ud83c\udf0d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  jorden_asien: {
    keywords: ['globe', 'verden', 'øst', 'international'],
    char: '\ud83c\udf0f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  fuld_måne: {
    keywords: ['natur', 'gul', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf15',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  aftagende_fuldmåne: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove', 'tiltagende_gibbous_måne'],
    char: '\ud83c\udf16',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sidste_kvartmåne: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf17',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  aftagende_halvmåne: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf18',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  nymåne: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf11',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  voksende_nymåne: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf12',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  første_kvartmåne: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf13',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tiltagende_halvmåne: {
    keywords: ['natur', 'nat', 'sky', 'gray', 'tusmørke', 'flyt', 'rummet', 'aften', 'sove'],
    char: '\ud83c\udf14',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  nymåne_med_ansigt: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf1a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  fuldmåne_med_ansigt: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf1d',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  første_kvartmåne_med_ansigt: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf1b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sidste_kvartmåne_med_ansigt: {
    keywords: ['natur', 'tusmørke', 'flyt', 'rummet', 'nat', 'aften', 'sove'],
    char: '\ud83c\udf1c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sol_med_ansigt: {
    keywords: ['natur', 'morgen', 'sky'],
    char: '\ud83c\udf1e',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  halvmåne: {
    keywords: ['nat', 'sove', 'sky', 'aften', 'magi'],
    char: '\ud83c\udf19',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  stjerne: {
    keywords: ['nat', 'gul'],
    char: '\u2b50',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  blinkende_stjerne: {
    keywords: ['nat', 'stjernestøv', 'fantastisk', 'godt', 'magi'],
    char: '\ud83c\udf1f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  svimmel: {
    keywords: ['stjerne', 'stjerner', 'stjerneskud', 'magi'],
    char: '\ud83d\udcab',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  stjerner: {
    keywords: ['stjerner', 'skinne', 'shiny', 'cool', 'fantastisk', 'godt', 'magi'],
    char: '\u2728',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  komet: {
    keywords: ['rummet'],
    char: '\u2604',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  solskin: {
    keywords: ['vejret', 'natur', 'lyst', 'sommer', 'strand', 'forår'],
    char: '\u2600\ufe0f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sol_bagved_lille_sky: {
    keywords: ['vejret'],
    char: '\ud83c\udf24',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  delvist_solskin: {
    keywords: ['vejret', 'natur', 'sky', 'morgen', 'efterår', 'forår'],
    char: '\u26c5',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sol_bagved_stor_sky: {
    keywords: ['vejret'],
    char: '\ud83c\udf25',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sol_bagved_regnsky: {
    keywords: ['vejret', 'vand'],
    char: '\ud83c\udf26',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sky: {
    keywords: ['vejret', 'sky'],
    char: '\u2601\ufe0f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sky_med_regn: {
    keywords: ['vejret', 'vand'],
    char: '\ud83c\udf27',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sky_med_lysning_og_regn: {
    keywords: ['vejret', 'lysning'],
    char: '\u26c8',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sky_med_lysning: {
    keywords: ['vejret', 'torden'],
    char: '\ud83c\udf29',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  zap: {
    keywords: ['torden', 'vejret', 'lyn', 'hurtig'],
    char: '\u26a1',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  ild: {
    keywords: ['varm', 'kok', 'flamme'],
    char: '\ud83d\udd25',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  boom: {
    keywords: ['bombe', 'eksplodere', 'eksplosion', 'kollision', 'bombet'],
    char: '\ud83d\udca5',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  snefnug: {
    keywords: ['vinter', 'sæson', 'kold', 'vejret', 'jul', 'xmas'],
    char: '\u2744\ufe0f',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sky_med_sne: {
    keywords: ['vejret'],
    char: '\ud83c\udf28',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  snemand: {
    keywords: ['vinter', 'sæson', 'kold', 'vejret', 'jul', 'xmas', 'frozen', 'uden_sne'],
    char: '\u26c4',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  snemand_med_sne: {
    keywords: ['vinter', 'sæson', 'kold', 'vejret', 'jul', 'xmas', 'frozen'],
    char: '\u2603',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  vind_ansigt: {
    keywords: ['blæst', 'luft'],
    char: '\ud83c\udf2c',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  pust: {
    keywords: ['vind', 'luft', 'hurtig', 'shoo', 'fart', 'røg', 'puff'],
    char: '\ud83d\udca8',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tornado: {
    keywords: ['vejret', 'cyklon', 'twister'],
    char: '\ud83c\udf2a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  tåge: {
    keywords: ['vejret', 'skyer'],
    char: '\ud83c\udf2b',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  åben_paraply: {
    keywords: ['vejret', 'forår'],
    char: '\u2602',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  paraply: {
    keywords: ['regnvejr', 'vejret', 'forår'],
    char: '\u2614',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  dråbe: {
    keywords: ['vand', 'dryp', 'vandhane', 'forår'],
    char: '\ud83d\udca7',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  sved_dråber: {
    keywords: ['vand', 'dryp', 'oops'],
    char: '\ud83d\udca6',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  havet: {
    keywords: ['til_søs', 'vand', 'bølge', 'natur', 'tsunami', 'katastrofe'],
    char: '\ud83c\udf0a',
    fitzpatrick_scale: false,
    category: 'animals_and_nature',
  },
  grønt_æble: {
    keywords: ['frugt', 'natur'],
    char: '\ud83c\udf4f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  æble: {
    keywords: ['frugt', 'mac', 'apple', 'skole'],
    char: '\ud83c\udf4e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  pære: {
    keywords: ['frugt', 'natur', 'mad'],
    char: '\ud83c\udf50',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  mandarin: {
    keywords: ['mad', 'frugt', 'natur', 'appelsin'],
    char: '\ud83c\udf4a',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  citron: {
    keywords: ['frugt', 'natur'],
    char: '\ud83c\udf4b',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  banan: {
    keywords: ['frugt', 'mad', 'abe'],
    char: '\ud83c\udf4c',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  vandmelon: {
    keywords: ['frugt', 'mad', 'picnic', 'sommer'],
    char: '\ud83c\udf49',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  vindruer: {
    keywords: ['frugt', 'mad', 'vin'],
    char: '\ud83c\udf47',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  jordbær: {
    keywords: ['frugt', 'mad', 'natur'],
    char: '\ud83c\udf53',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  melon: {
    keywords: ['frugt', 'natur', 'mad'],
    char: '\ud83c\udf48',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kirsebær: {
    keywords: ['mad', 'frugt'],
    char: '\ud83c\udf52',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fersken: {
    keywords: ['frugt', 'natur', 'mad'],
    char: '\ud83c\udf51',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  ananas: {
    keywords: ['frugt', 'natur', 'mad'],
    char: '\ud83c\udf4d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kokosnød: {
    keywords: ['frugt', 'natur', 'mad', 'palme'],
    char: '\ud83e\udd65',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kiwifrugt: {
    keywords: ['frugt', 'mad'],
    char: '\ud83e\udd5d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  mango: {
    keywords: ['frugt', 'mad', 'tropisk'],
    char: '\ud83e\udd6d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  avocado: {
    keywords: ['frugt', 'mad'],
    char: '\ud83e\udd51',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  broccoli: {
    keywords: ['frugt', 'mad', 'vegetabilsk'],
    char: '\ud83e\udd66',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  tomat: {
    keywords: ['frugt', 'vegetabilsk', 'natur', 'mad'],
    char: '\ud83c\udf45',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  aubergine: {
    keywords: ['vegetabilsk', 'natur', 'mad', 'aubergine'],
    char: '\ud83c\udf46',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  agurk: {
    keywords: ['frugt', 'mad', 'sylte'],
    char: '\ud83e\udd52',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  gulerod: {
    keywords: ['vegetabilsk', 'mad', 'orange'],
    char: '\ud83e\udd55',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  chili: {
    keywords: ['mad', 'spicy', 'chilli', 'stærk', 'chili'],
    char: '\ud83c\udf36',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kartoffel: {
    keywords: ['mad', 'knold', 'grøntsag', 'stivelse'],
    char: '\ud83e\udd54',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  korn: {
    keywords: ['mad', 'vegetabilsk', 'plant'],
    char: '\ud83c\udf3d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  salat: {
    keywords: ['mad', 'vegetabilsk', 'plant', 'bok choy', 'kål', 'grøntkål', 'salat'],
    char: '\ud83e\udd6c',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  sød_kartoffel: {
    keywords: ['mad', 'natur'],
    char: '\ud83c\udf60',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  peanuts: {
    keywords: ['mad', 'nød'],
    char: '\ud83e\udd5c',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  honningkrukke: {
    keywords: ['bier', 'sød', 'køkken'],
    char: '\ud83c\udf6f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  croissant: {
    keywords: ['mad', 'brød', 'fransk'],
    char: '\ud83e\udd50',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  brød: {
    keywords: ['mad', 'morgenmad', 'toast'],
    char: '\ud83c\udf5e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  baguette_brød: {
    keywords: ['mad', 'brød', 'fransk'],
    char: '\ud83e\udd56',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  bagel: {
    keywords: ['mad', 'brød', 'bageri', 'smøre'],
    char: '\ud83e\udd6f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  pretzel: {
    keywords: ['mad', 'brød', 'snoet'],
    char: '\ud83e\udd68',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  ost: {
    keywords: ['mad', 'cheddar'],
    char: '\ud83e\uddc0',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  æg: {
    keywords: ['mad', 'kylling', 'morgenmad'],
    char: '\ud83e\udd5a',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  bacon: {
    keywords: ['mad', 'morgenmad', 'gris', 'gris', 'kød'],
    char: '\ud83e\udd53',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  bøf: {
    keywords: ['mad', 'ko', 'kød', 'udskæring', 'chop', 'lammekotelet', 'svinekotelet'],
    char: '\ud83e\udd69',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  pandekager: {
    keywords: ['mad', 'morgenmad', 'flapjacks', 'pandekager'],
    char: '\ud83e\udd5e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fjerkræ: {
    keywords: ['mad', 'kød', 'kyllingelår', 'fugl', 'kylling', 'kalkun'],
    char: '\ud83c\udf57',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kød_på_ben: {
    keywords: ['godt', 'mad', 'kyllingelår'],
    char: '\ud83c\udf56',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  ben: {
    keywords: ['skelet'],
    char: '\ud83e\uddb4',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  stegt_reje: {
    keywords: ['mad', 'dyr', 'appetizer', 'sommer'],
    char: '\ud83c\udf64',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  stegt_æg: {
    keywords: ['mad', 'morgenmad', 'køkken', 'æg'],
    char: '\ud83c\udf73',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  hamburger: {
    keywords: ['kød', 'fast food', 'kød', 'osteburger', 'mcdonalds', 'burger king'],
    char: '\ud83c\udf54',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fritter: {
    keywords: ['chips', 'pomfritter', 'pommes', 'snack', 'fast food'],
    char: '\ud83c\udf5f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fyldt_pita_brød: {
    keywords: ['mad', 'fladbrød', 'kebab', 'fyldt', 'gyro'],
    char: '\ud83e\udd59',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  hotdog: {
    keywords: ['mad', 'frankfurter'],
    char: '\ud83c\udf2d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  pizza: {
    keywords: ['mad', 'fest'],
    char: '\ud83c\udf55',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  sandwich: {
    keywords: ['mad', 'frokost', 'brød'],
    char: '\ud83e\udd6a',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  dåsemad: {
    keywords: ['mad', 'suppe'],
    char: '\ud83e\udd6b',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  spaghetti: {
    keywords: ['mad', 'italiensk', 'nudel'],
    char: '\ud83c\udf5d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  taco: {
    keywords: ['mad', 'mexikansk'],
    char: '\ud83c\udf2e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  burrito: {
    keywords: ['mad', 'mexikansk'],
    char: '\ud83c\udf2f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  grøn_salat: {
    keywords: ['mad', 'sundt', 'salat'],
    char: '\ud83e\udd57',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  paella: {
    keywords: ['mad', 'madlavning', 'kasserolle', 'paella'],
    char: '\ud83e\udd58',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  ramen: {
    keywords: ['mad', 'japansk', 'nudel', 'chopsticks'],
    char: '\ud83c\udf5c',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  gryderet: {
    keywords: ['mad', 'kød', 'suppe'],
    char: '\ud83c\udf72',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fiskekage: {
    keywords: ['mad', 'japan', 'til_søs', 'strand', 'narutomaki', 'pink', 'swirl', 'kamaboko', 'surimi', 'ramen'],
    char: '\ud83c\udf65',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  lykkekage: {
    keywords: ['mad', 'profeti'],
    char: '\ud83e\udd60',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  sushi: {
    keywords: ['mad', 'fisk', 'japansk', 'ris'],
    char: '\ud83c\udf63',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  bento: {
    keywords: ['mad', 'japansk', 'kasse'],
    char: '\ud83c\udf71',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  karry: {
    keywords: ['mad', 'spicy', 'stærk', 'indisk'],
    char: '\ud83c\udf5b',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  risbold: {
    keywords: ['mad', 'japansk'],
    char: '\ud83c\udf59',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  ris: {
    keywords: ['mad', 'kina', 'asiatisk'],
    char: '\ud83c\udf5a',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  riskiks: {
    keywords: ['mad', 'japansk'],
    char: '\ud83c\udf58',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  oden: {
    keywords: ['mad', 'spyd', 'japansk'],
    char: '\ud83c\udf62',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  dango: {
    keywords: ['mad', 'dessert', 'sød', 'japansk', 'spyd', 'barbecue', 'kød'],
    char: '\ud83c\udf61',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  formet_ice: {
    keywords: ['varm', 'dessert', 'sommer'],
    char: '\ud83c\udf67',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  is: {
    keywords: ['mad', 'varmt', 'dessert'],
    char: '\ud83c\udf68',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  softice: {
    keywords: ['mad', 'varmt', 'dessert', 'sommer'],
    char: '\ud83c\udf66',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  tærte: {
    keywords: ['mad', 'dessert', 'bagværk'],
    char: '\ud83e\udd67',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kage: {
    keywords: ['mad', 'dessert'],
    char: '\ud83c\udf70',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  cupcake: {
    keywords: ['mad', 'dessert', 'bageri', 'sød'],
    char: '\ud83e\uddc1',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  månekage: {
    keywords: ['mad', 'efterår'],
    char: '\ud83e\udd6e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fødselsdag: {
    keywords: ['mad', 'dessert', 'kage'],
    char: '\ud83c\udf82',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  cremekage: {
    keywords: ['dessert', 'mad'],
    char: '\ud83c\udf6e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  slik: {
    keywords: ['snack', 'dessert', 'sød', 'lolly'],
    char: '\ud83c\udf6c',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  slikkepind: {
    keywords: ['mad', 'snack', 'slik', 'sød'],
    char: '\ud83c\udf6d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  chokoladebar: {
    keywords: ['mad', 'snack', 'dessert', 'sød'],
    char: '\ud83c\udf6b',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  popcorn: {
    keywords: ['mad', 'film', 'films', 'snack'],
    char: '\ud83c\udf7f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  dumpling: {
    keywords: ['mad', 'empanada', 'pierogi', 'potsticker'],
    char: '\ud83e\udd5f',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  doughnut: {
    keywords: ['mad', 'dessert', 'snack', 'sød', 'donut'],
    char: '\ud83c\udf69',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  cookie: {
    keywords: ['mad', 'snack', 'oreo', 'chokolade', 'sød', 'dessert'],
    char: '\ud83c\udf6a',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  mælkeglas: {
    keywords: ['drik', 'drik', 'ko'],
    char: '\ud83e\udd5b',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  øl: {
    keywords: ['afslapning', 'drik', 'drik', 'fuld', 'fest', 'pub', 'sommer', 'alkohol', 'sprut'],
    char: '\ud83c\udf7a',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  øller: {
    keywords: ['afslapning', 'drik', 'drik', 'fuld', 'fest', 'pub', 'sommer', 'alkohol', 'sprut'],
    char: '\ud83c\udf7b',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  klinkende_glases: {
    keywords: ['drik', 'drik', 'fest', 'alkohol', 'celebrate', 'cheers', 'skål', 'vin', 'champagne', 'toast'],
    char: '\ud83e\udd42',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  vinglas: {
    keywords: ['drik', 'drik', 'fuld', 'alkohol', 'sprut'],
    char: '\ud83c\udf77',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fladbundet_glas: {
    keywords: ['drik', 'drik', 'fuld', 'alkohol', 'liquor', 'sprut', 'bourbon', 'scotch', 'whisky', 'glas', 'shot'],
    char: '\ud83e\udd43',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  cocktail: {
    keywords: ['drik', 'fuld', 'alkohol', 'drik', 'sprut', 'mojito'],
    char: '\ud83c\udf78',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  tropisk_drink: {
    keywords: ['drik', 'cocktail', 'sommer', 'strand', 'alkohol', 'sprut', 'mojito'],
    char: '\ud83c\udf79',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  champagne: {
    keywords: ['drik', 'vin', 'flaske', 'fejring'],
    char: '\ud83c\udf7e',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  sake: {
    keywords: ['vin', 'drik', 'fuld', 'drik', 'japansk', 'alkohol', 'sprut'],
    char: '\ud83c\udf76',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  the: {
    keywords: ['drik', 'skål', 'morgenmad', 'grøn', 'britisk'],
    char: '\ud83c\udf75',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kop_med_sugerør: {
    keywords: ['drik', 'sodavand'],
    char: '\ud83e\udd64',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  kaffe: {
    keywords: ['drik', 'koffein', 'latte', 'espresso'],
    char: '\u2615',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  babyflaske: {
    keywords: ['mad', 'beholder', 'mælk'],
    char: '\ud83c\udf7c',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  salt: {
    keywords: ['krydderi', 'shaker'],
    char: '\ud83e\uddc2',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  ske: {
    keywords: ['bestik', 'køkken', 'service'],
    char: '\ud83e\udd44',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  gaffel_og_kniv: {
    keywords: ['bestik', 'køkken'],
    char: '\ud83c\udf74',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  tallerken_med_bestik: {
    keywords: ['mad', 'spise', 'måltid', 'frokost', 'aftensmad', 'restaurant'],
    char: '\ud83c\udf7d',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  skål_med_ske: {
    keywords: ['mad', 'morgenmad', 'morgenmad', 'havregryn', 'porridge'],
    char: '\ud83e\udd63',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  takeout_box: {
    keywords: ['mad', 'leftovers', 'rester', 'levering', 'take-away'],
    char: '\ud83e\udd61',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  chopsticks: {
    keywords: ['mad'],
    char: '\ud83e\udd62',
    fitzpatrick_scale: false,
    category: 'food_and_drink',
  },
  fodbold: {
    keywords: ['sport', 'fodbold'],
    char: '\u26bd',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  basketbold: {
    keywords: ['sport', 'bold', 'NBA'],
    char: '\ud83c\udfc0',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  amerikansk_fodbold: {
    keywords: ['sport', 'bold', 'NFL'],
    char: '\ud83c\udfc8',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  baseball: {
    keywords: ['sport', 'bold'],
    char: '\u26be',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  softball: {
    keywords: ['sport', 'bold'],
    char: '\ud83e\udd4e',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  tennis: {
    keywords: ['sport', 'bold', 'grøn'],
    char: '\ud83c\udfbe',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  volleybold: {
    keywords: ['sport', 'bold'],
    char: '\ud83c\udfd0',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  rugby_fodbold: {
    keywords: ['sport', 'team'],
    char: '\ud83c\udfc9',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  frisbee_disc: {
    keywords: ['sport', 'frisbee', 'ultimate'],
    char: '\ud83e\udd4f',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  '8bold': {
    keywords: ['pool', 'hobby', 'billard', 'spil', 'held', 'magi'],
    char: '\ud83c\udfb1',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  golf: {
    keywords: ['sport', 'business', 'flag', 'hul', 'sommer'],
    char: '\u26f3',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  golf_kvinde: {
    keywords: ['sport', 'business', 'kvinde', 'dame'],
    char: '\ud83c\udfcc\ufe0f\u200d\u2640\ufe0f',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  golf_mand: {
    keywords: ['sport', 'business'],
    char: '\ud83c\udfcc',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  ping_pong: {
    keywords: ['sport', 'bordtennis', 'pingpong'],
    char: '\ud83c\udfd3',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  badminton: {
    keywords: ['sport', 'ketcher'],
    char: '\ud83c\udff8',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  mål: {
    keywords: ['sport', 'net'],
    char: '\ud83e\udd45',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  ishockey: {
    keywords: ['sport'],
    char: '\ud83c\udfd2',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  hockey: {
    keywords: ['sport'],
    char: '\ud83c\udfd1',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  lacrosse: {
    keywords: ['sport', 'bold', 'stav'],
    char: '\ud83e\udd4d',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  cricket: {
    keywords: ['sport'],
    char: '\ud83c\udfcf',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  ski: {
    keywords: ['sport', 'vinter', 'kold', 'sne'],
    char: '\ud83c\udfbf',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  skiløber: {
    keywords: ['sport', 'vinter', 'sne'],
    char: '\u26f7',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  snowboarder: {
    keywords: ['sport', 'vinter'],
    char: '\ud83c\udfc2',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  person_fægter: {
    keywords: ['sport', 'fægte', 'sværd'],
    char: '\ud83e\udd3a',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  kvinder_wrestler: {
    keywords: ['sport', 'wrestler'],
    char: '\ud83e\udd3c\u200d\u2640\ufe0f',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  mænd_wrestler: {
    keywords: ['sport', 'wrestler'],
    char: '\ud83e\udd3c\u200d\u2642\ufe0f',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  kvinde_danser: {
    keywords: ['gymnastik', 'står_på_hænder'],
    char: '\ud83e\udd38\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mand_vejrmøller: {
    keywords: ['gymnastik', 'står_på_hænder'],
    char: '\ud83e\udd38\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  kvinde_spiller_håndbold: {
    keywords: ['sport'],
    char: '\ud83e\udd3e\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mand_spiller_håndbold: {
    keywords: ['sport'],
    char: '\ud83e\udd3e\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  skøjte: {
    keywords: ['sport'],
    char: '\u26f8',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  curlingsten: {
    keywords: ['sport'],
    char: '\ud83e\udd4c',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  skateboard: {
    keywords: ['board'],
    char: '\ud83d\udef9',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  slæde: {
    keywords: ['slæde', 'kælk', 'toboggan'],
    char: '\ud83d\udef7',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  bue_og_pil: {
    keywords: ['sport'],
    char: '\ud83c\udff9',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  fiske_stang_og_fisk: {
    keywords: ['mad', 'hobby', 'sommer'],
    char: '\ud83c\udfa3',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  boxing_handske: {
    keywords: ['sport', 'kæmper', 'slås'],
    char: '\ud83e\udd4a',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  kampsport_uniform: {
    keywords: ['judo', 'karate', 'taekwondo'],
    char: '\ud83e\udd4b',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  roende_kvinde: {
    keywords: ['sport', 'hobby', 'vand', 'skib', 'kvinde', 'dame'],
    char: '\ud83d\udea3\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  roende_mand: {
    keywords: ['sport', 'hobby', 'vand', 'skib'],
    char: '\ud83d\udea3',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  klatrende_kvinde: {
    keywords: ['sport', 'hobby', 'kvinde', 'dame', 'klippe'],
    char: '\ud83e\uddd7\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  klatrende_mand: {
    keywords: ['sport', 'hobby', 'mand', 'mand', 'klippe'],
    char: '\ud83e\uddd7\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  svømmende_kvinde: {
    keywords: ['sport', 'motion', 'træning', 'menneske', 'atlet', 'vand', 'sommer', 'kvinde', 'dame'],
    char: '\ud83c\udfca\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  svømmende_mand: {
    keywords: ['sport', 'motion', 'træning', 'menneske', 'atlet', 'vand', 'sommer'],
    char: '\ud83c\udfca',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  kvinde_spiller_vandpolo: {
    keywords: ['sport', 'pool'],
    char: '\ud83e\udd3d\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mand_spiller_vandpolo: {
    keywords: ['sport', 'pool'],
    char: '\ud83e\udd3d\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  kvinde_i_lotus_position: {
    keywords: ['kvinde', 'dame', 'meditation', 'yoga', 'serenity', 'zen', 'mindfulness'],
    char: '\ud83e\uddd8\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mand_i_lotus_position: {
    keywords: ['mand', 'mand', 'meditation', 'yoga', 'serenity', 'zen', ' mindfulness'],
    char: '\ud83e\uddd8\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  surfing_kvinde: {
    keywords: ['sport', 'havet', 'til_søs', 'sommer', 'strand', 'kvinde', 'dame'],
    char: '\ud83c\udfc4\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  surfing_mand: {
    keywords: ['sport', 'havet', 'til_søs', 'sommer', 'strand'],
    char: '\ud83c\udfc4',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  bad: {
    keywords: ['ren', 'bad', 'badeværelse'],
    char: '\ud83d\udec0',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  basketball_kvinde: {
    keywords: ['sport', 'menneske', 'kvinde', 'dame'],
    char: '\u26f9\ufe0f\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  basketball_mand: {
    keywords: ['sport', 'menneske'],
    char: '\u26f9',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  vægt_løftende_kvinde: {
    keywords: ['sport', 'træning', 'motion', 'træning', 'kvinde', 'dame'],
    char: '\ud83c\udfcb\ufe0f\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  vægt_løftende_mand: {
    keywords: ['sport', 'træning', 'motion', 'træning'],
    char: '\ud83c\udfcb',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  cyklende_kvinde: {
    keywords: ['sport', 'cykel', 'motion', 'træning', 'hipster', 'kvinde', 'dame'],
    char: '\ud83d\udeb4\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  cyklende_mand: {
    keywords: ['sport', 'cykel', 'motion', 'træning', 'hipster'],
    char: '\ud83d\udeb4',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mountainbike_cyklende_kvinde: {
    keywords: ['transport', 'sport', 'menneske', 'kapløb', 'cykel', 'kvinde', 'dame'],
    char: '\ud83d\udeb5\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mountainbike_cyklende_mand: {
    keywords: ['transport', 'sport', 'menneske', 'kapløb', 'cykel'],
    char: '\ud83d\udeb5',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  hestevæddeløb: {
    keywords: ['dyr', 'betting', 'konkurrence', 'gambling', 'held'],
    char: '\ud83c\udfc7',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  business_suit_svæver: {
    keywords: ['suit', 'business', 'svæve', 'hover', 'hoppe'],
    char: '\ud83d\udd74',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  trofæ: {
    keywords: ['vinde', 'pris', 'konkurrence', 'placering', 'ftw', 'ceremony'],
    char: '\ud83c\udfc6',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  løbetrøje: {
    keywords: ['spille', 'optog'],
    char: '\ud83c\udfbd',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  medalje_sport: {
    keywords: ['pris', 'vinde'],
    char: '\ud83c\udfc5',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  medalje_militær: {
    keywords: ['pris', 'vinde', 'hær'],
    char: '\ud83c\udf96',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  første_plads_medalje: {
    keywords: ['pris', 'vinde', 'første'],
    char: '\ud83e\udd47',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  anden_plads_medalje: {
    keywords: ['pris', 'nummer_to'],
    char: '\ud83e\udd48',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  tredje_plads_medalje: {
    keywords: ['pris', 'nummer_tre'],
    char: '\ud83e\udd49',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  mindebånd: {
    keywords: ['sport', 'velfortjent', 'støtte', 'opmærksom'],
    char: '\ud83c\udf97',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  roset: {
    keywords: ['blomst', 'dekoration', 'militær'],
    char: '\ud83c\udff5',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  billet: {
    keywords: ['begivenhed', 'koncert', 'indgang'],
    char: '\ud83c\udfab',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  billetter: {
    keywords: ['sport', 'koncert', 'indgang'],
    char: '\ud83c\udf9f',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  udøvende_skuespil: {
    keywords: ['skuespil', 'drama'],
    char: '\ud83c\udfad',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  kunst: {
    keywords: ['design', 'male', 'tegne', 'farver'],
    char: '\ud83c\udfa8',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  cirkus_telt: {
    keywords: ['festival', 'karneval', 'fest'],
    char: '\ud83c\udfaa',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  kvinde_jonglere: {
    keywords: ['jonglere', 'balance', 'skill', 'multitask'],
    char: '\ud83e\udd39\u200d\u2640\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mand_jonglere: {
    keywords: ['jonglere', 'balance', 'skill', 'multitask'],
    char: '\ud83e\udd39\u200d\u2642\ufe0f',
    fitzpatrick_scale: true,
    category: 'activity',
  },
  mikrofon: {
    keywords: ['lyd', 'musik', 'PA', 'synge', 'talkshow'],
    char: '\ud83c\udfa4',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  headphones: {
    keywords: ['musik', 'hovedtelefoner', 'score', 'gadgets'],
    char: '\ud83c\udfa7',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  node_på_nodehæfte: {
    keywords: ['treble', 'clef', 'udøve', 'musik'],
    char: '\ud83c\udfbc',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  keyboard: {
    keywords: ['piano', 'klaver', 'instrument', 'udøve', 'spille'],
    char: '\ud83c\udfb9',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  tromme: {
    keywords: ['musik', 'instrument', 'trommestik', 'lilletromme'],
    char: '\ud83e\udd41',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  saxofon: {
    keywords: ['musik', 'instrument', 'jazz', 'blues'],
    char: '\ud83c\udfb7',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  trumpet: {
    keywords: ['musik', 'blæser'],
    char: '\ud83c\udfba',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  guitar: {
    keywords: ['musik', 'instrument'],
    char: '\ud83c\udfb8',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  violin: {
    keywords: ['musik', 'instrument', 'orkester', 'symfoni'],
    char: '\ud83c\udfbb',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  klaptræ: {
    keywords: ['film', 'film', 'action'],
    char: '\ud83c\udfac',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  video_spil: {
    keywords: ['spil', 'konsol', 'PS4', 'playstation', 'controller'],
    char: '\ud83c\udfae',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  space_invader: {
    keywords: ['spil', 'arkade', 'spil'],
    char: '\ud83d\udc7e',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  dart: {
    keywords: ['spil', 'spil', 'bar', 'mål', 'bullseye'],
    char: '\ud83c\udfaf',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  spilleterning: {
    keywords: ['terning', 'tilfældig', 'bordplade', 'spille', 'held'],
    char: '\ud83c\udfb2',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  skakbrik: {
    keywords: ['bonde'],
    char: '\u265f',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  spillemaskine: {
    keywords: ['bet', 'spille', 'vegas', 'fruit machine', 'held', 'casino'],
    char: '\ud83c\udfb0',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  puslespil: {
    keywords: ['puzzle', 'brik'],
    char: '\ud83e\udde9',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  bowling: {
    keywords: ['sport', 'sjov', 'spil'],
    char: '\ud83c\udfb3',
    fitzpatrick_scale: false,
    category: 'activity',
  },
  rød_bil: {
    keywords: ['rød', 'transport', 'køretøj'],
    char: '\ud83d\ude97',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  taxi: {
    keywords: ['uber', 'køretøj', 'taxa', 'biler', 'transport'],
    char: '\ud83d\ude95',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  blå_bil: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude99',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bus: {
    keywords: ['bil', 'køretøj', 'transport'],
    char: '\ud83d\ude8c',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  rutebil: {
    keywords: ['bart', 'transport', 'gammel', 'rutebil', 'køretøj'],
    char: '\ud83d\ude8e',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  racerbil: {
    keywords: ['sport', 'kapløb', 'hurtig', 'formula', 'f1'],
    char: '\ud83c\udfce',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  politibil: {
    keywords: ['køretøj', 'biler', 'transport', 'loven', 'lov', 'håndhævelse'],
    char: '\ud83d\ude93',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  ambulance: {
    keywords: ['sundhed', '112', 'hospital'],
    char: '\ud83d\ude91',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  brandbil: {
    keywords: ['transport', 'biler', 'køretøj'],
    char: '\ud83d\ude92',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  minibus: {
    keywords: ['køretøj', 'bil', 'transport'],
    char: '\ud83d\ude90',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  lastbil: {
    keywords: ['biler', 'truck', 'pick-up', 'transport'],
    char: '\ud83d\ude9a',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  varebil: {
    keywords: ['køretøj', 'biler', 'transport', 'express'],
    char: '\ud83d\ude9b',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  traktor: {
    keywords: ['køretøj', 'bil', 'landbrug', 'agrikultur'],
    char: '\ud83d\ude9c',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  elektrisk_løbehjul: {
    keywords: ['køretøj', 'lime', 'razor'],
    char: '\ud83d\udef4',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  motorcykel: {
    keywords: ['kapløb', 'sport', 'hurtig'],
    char: '\ud83c\udfcd',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  cykel: {
    keywords: ['sport', 'cykel', 'motion', 'træning', 'hipster'],
    char: '\ud83d\udeb2',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  knallert: {
    keywords: ['køretøj', 'scooter', 'vespa', 'sasha'],
    char: '\ud83d\udef5',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  udrykningsirener: {
    keywords: ['politi', 'ambulance', '112', 'nødsituation', 'advarsel', 'fejl', 'pinged', 'loven', 'lov'],
    char: '\ud83d\udea8',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  modkørende_politi_bil: {
    keywords: ['køretøj', 'loven', 'lov', 'håndhævelse', '112'],
    char: '\ud83d\ude94',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  modkørende_bus: {
    keywords: ['køretøj', 'transport'],
    char: '\ud83d\ude8d',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  modkørende_bil: {
    keywords: ['bil', 'køretøj', 'transport'],
    char: '\ud83d\ude98',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  modkørende_taxa: {
    keywords: ['køretøj', 'biler', 'uber'],
    char: '\ud83d\ude96',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kabinelift: {
    keywords: ['transport', 'gondol', 'lift', 'køretøj', 'ski'],
    char: '\ud83d\udea1',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  stor_bjerggondol: {
    keywords: ['transport', 'lift', 'køretøj', 'ski'],
    char: '\ud83d\udea0',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  svævende_sporvogn: {
    keywords: ['køretøj', 'tog', 'transport'],
    char: '\ud83d\ude9f',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  togvogn: {
    keywords: ['transport', 'sporvogn', 'køretøj'],
    char: '\ud83d\ude83',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  tog: {
    keywords: ['transport', 'køretøj', 'gods', 'offentlig', 'rejse'],
    char: '\ud83d\ude8b',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  lyntog: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude9d',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  lyntog2_side: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude84',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  lyntog_front: {
    keywords: ['transport', 'køretøj', 'fart', 'hurtig', 'offentlig', 'rejse'],
    char: '\ud83d\ude85',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  letbane: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude88',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bjergtogbane: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude9e',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  damp_lokomotiv: {
    keywords: ['transport', 'køretøj', 'tog'],
    char: '\ud83d\ude82',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  tog2: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude86',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  metro: {
    keywords: ['transport', 'blå-firkant', 'mrt', 'undergrund', 'tube'],
    char: '\ud83d\ude87',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  sporvogn: {
    keywords: ['transport', 'køretøj'],
    char: '\ud83d\ude8a',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  station: {
    keywords: ['transport', 'køretøj', 'offentlig'],
    char: '\ud83d\ude89',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  flyvende_ufo: {
    keywords: ['transport', 'køretøj', 'ufo'],
    char: '\ud83d\udef8',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  helikopter: {
    keywords: ['transport', 'køretøj', 'fly'],
    char: '\ud83d\ude81',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  lille_flyver: {
    keywords: ['fly', 'transport', 'fly', 'køretøj'],
    char: '\ud83d\udee9',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  flyver: {
    keywords: ['køretøj', 'transport', 'flyver', 'fly'],
    char: '\u2708\ufe0f',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  fly_afgang: {
    keywords: ['lufthavn', 'fly', 'landing'],
    char: '\ud83d\udeeb',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  fly_ankomst: {
    keywords: ['lufthavn', 'fly', 'boarding'],
    char: '\ud83d\udeec',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  sejlskib: {
    keywords: ['skib', 'sommer', 'transport', 'vand', 'sejlads'],
    char: '\u26f5',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  motorbåd: {
    keywords: ['skib'],
    char: '\ud83d\udee5',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  speedbåd: {
    keywords: ['skib', 'transport', 'køretøj', 'sommer'],
    char: '\ud83d\udea4',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  færge: {
    keywords: ['båd', 'skib', 'yacht'],
    char: '\u26f4',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  passagerskib: {
    keywords: ['yacht', 'krydstogt', 'færge'],
    char: '\ud83d\udef3',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  raket: {
    keywords: ['affyring', 'skib', 'staffmode', 'NASA', 'ydre_rum', 'rummet', 'fly'],
    char: '\ud83d\ude80',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  satellit: {
    keywords: ['kommunikation', 'gps', 'kredsløb', 'rumfart', 'NASA', 'ISS'],
    char: '\ud83d\udef0',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  sæde: {
    keywords: ['sidde', 'flyver', 'transport', 'bus', 'fly', 'fly'],
    char: '\ud83d\udcba',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kano: {
    keywords: ['båd', 'padle', 'vand', 'skib'],
    char: '\ud83d\udef6',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  anker: {
    keywords: ['skib', 'færge', 'til_søs', 'båd'],
    char: '\u2693',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  konstruktion: {
    keywords: ['wip', 'fremskridt', 'pas_på', 'advarsel'],
    char: '\ud83d\udea7',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  brændstofpumpe: {
    keywords: ['gas station', 'benzin', 'diesel', 'petroleum'],
    char: '\u26fd',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  busstoppested: {
    keywords: ['transport', 'vente'],
    char: '\ud83d\ude8f',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  vertikalt_trafiklys: {
    keywords: ['transport', 'køre'],
    char: '\ud83d\udea6',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  trafiklys: {
    keywords: ['transport', 'signal'],
    char: '\ud83d\udea5',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  ternet_flag: {
    keywords: ['konkurrence', 'målstreg', 'kapløb', 'gokart'],
    char: '\ud83c\udfc1',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  skib: {
    keywords: ['transport', 'titanic', 'afgang'],
    char: '\ud83d\udea2',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  pariserhjul: {
    keywords: ['paris', 'karneval', 'londoneye'],
    char: '\ud83c\udfa1',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  rutschebane: {
    keywords: ['karneval', 'legeplads', 'tivoli', 'sjov'],
    char: '\ud83c\udfa2',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  karrusel_hest: {
    keywords: ['tivoli', 'karneval'],
    char: '\ud83c\udfa0',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  byggeplads: {
    keywords: ['wip', 'arbejde', 'fremskridt'],
    char: '\ud83c\udfd7',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  tåget: {
    keywords: ['foto', 'bjerg'],
    char: '\ud83c\udf01',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  tokyo_tower: {
    keywords: ['foto', 'japansk'],
    char: '\ud83d\uddfc',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  fabrik: {
    keywords: ['bygning', 'industry', 'forurening', 'røg'],
    char: '\ud83c\udfed',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  springvand: {
    keywords: ['foto', 'sommer', 'vand', 'frisk'],
    char: '\u26f2',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  ris_landskab: {
    keywords: ['foto', 'japan', 'asien', 'tsukimi'],
    char: '\ud83c\udf91',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bjerg: {
    keywords: ['foto', 'natur', 'miljø'],
    char: '\u26f0',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bjerg_sne: {
    keywords: ['foto', 'natur', 'miljø', 'vinter', 'kold'],
    char: '\ud83c\udfd4',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  mount_fuji: {
    keywords: ['foto', 'bjerg', 'natur', 'japansk'],
    char: '\ud83d\uddfb',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  vulkan: {
    keywords: ['foto', 'natur', 'katastrofe'],
    char: '\ud83c\udf0b',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kort_over_japan: {
    keywords: ['nation', 'land', 'japansk', 'asien'],
    char: '\ud83d\uddfe',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  camping: {
    keywords: ['foto', 'udendørs', 'telt'],
    char: '\ud83c\udfd5',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  telt: {
    keywords: ['foto', 'camping', 'udendørs'],
    char: '\u26fa',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  nationalpark: {
    keywords: ['foto', 'miljø', 'natur'],
    char: '\ud83c\udfde',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  motorvej: {
    keywords: ['vej', 'cupertino', 'landevej', 'stor_vej'],
    char: '\ud83d\udee3',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  togbane_spor: {
    keywords: ['tog', 'transport'],
    char: '\ud83d\udee4',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  solopgang: {
    keywords: ['morgen', 'udsigt', 'ferie', 'foto'],
    char: '\ud83c\udf05',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  solopgang_over_bjerge: {
    keywords: ['udsigt', 'ferie', 'foto'],
    char: '\ud83c\udf04',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  ørken: {
    keywords: ['foto', 'varmt', 'sand', 'sahara'],
    char: '\ud83c\udfdc',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  strandparasol: {
    keywords: ['vejret', 'sommer', 'solskin', 'sand', 'mojito'],
    char: '\ud83c\udfd6',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  øde_ø: {
    keywords: ['foto', 'tropisk', 'mojito'],
    char: '\ud83c\udfdd',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  by_solopgang: {
    keywords: ['foto', 'godmorgen', 'daggry'],
    char: '\ud83c\udf07',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  by_solnedgang: {
    keywords: ['foto', 'aften', 'sky', 'bygninger'],
    char: '\ud83c\udf06',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bylandskab: {
    keywords: ['foto', 'natteliv', 'urban'],
    char: '\ud83c\udfd9',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  nat_med_stjerner: {
    keywords: ['aften', 'by', 'centrum'],
    char: '\ud83c\udf03',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bro_om_natten: {
    keywords: ['foto', 'sanfrancisco'],
    char: '\ud83c\udf09',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  mælkevejen: {
    keywords: ['foto', 'rummet', 'stjerner'],
    char: '\ud83c\udf0c',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  stjerneskud: {
    keywords: ['nat', 'foto'],
    char: '\ud83c\udf20',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  sparkler: {
    keywords: ['stjerner', 'nat', 'skinne'],
    char: '\ud83c\udf87',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  fyrværkeri: {
    keywords: ['foto', 'festival', 'karneval', 'tillykke'],
    char: '\ud83c\udf86',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  regnbue: {
    keywords: ['natur', 'glad', 'enhjørning_ansigt', 'foto', 'sky', 'forår'],
    char: '\ud83c\udf08',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  huse: {
    keywords: ['bygninger', 'foto'],
    char: '\ud83c\udfd8',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  europæisk_slot: {
    keywords: ['bygning', 'royal', 'borg', 'historie'],
    char: '\ud83c\udff0',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  japansk_slot: {
    keywords: ['foto', 'bygning'],
    char: '\ud83c\udfef',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  stadium: {
    keywords: ['foto', 'sted', 'sport', 'koncert', 'spillested'],
    char: '\ud83c\udfdf',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  frihedsgudinden: {
    keywords: ['amerikansk', 'newyork'],
    char: '\ud83d\uddfd',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  hus: {
    keywords: ['bygning', 'hjem'],
    char: '\ud83c\udfe0',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  hus_med_have: {
    keywords: ['hjem', 'plante', 'natur'],
    char: '\ud83c\udfe1',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  faldefærdigt_hus: {
    keywords: ['forladt', 'udvist', 'gået_i_stykker', 'bygning'],
    char: '\ud83c\udfda',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kontor: {
    keywords: ['bygning', 'bureau', 'arbejde'],
    char: '\ud83c\udfe2',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  supermarked: {
    keywords: ['bygning', 'butik', 'shopping', 'mall'],
    char: '\ud83c\udfec',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  postkontor: {
    keywords: ['bygning', 'brev', 'kommunikation'],
    char: '\ud83c\udfe3',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  europæisk_postkontor: {
    keywords: ['bygning', 'email'],
    char: '\ud83c\udfe4',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  hospital: {
    keywords: ['bygning', 'sundhed', 'opperation', 'doktor'],
    char: '\ud83c\udfe5',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bank: {
    keywords: ['bygning', 'penge', 'salg', 'kontanter', 'business', 'enterprise'],
    char: '\ud83c\udfe6',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  hotel: {
    keywords: ['bygning', 'indkvartering', 'checkin'],
    char: '\ud83c\udfe8',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  butik: {
    keywords: ['bygning', 'shopping', 'dagligvarer'],
    char: '\ud83c\udfea',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  skole: {
    keywords: ['bygning', 'student', 'uddannelse', 'lære', 'undervise'],
    char: '\ud83c\udfeb',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kærlighedshotel: {
    keywords: ['like', 'bodel', 'affektion', 'dating'],
    char: '\ud83c\udfe9',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  bryllup: {
    keywords: ['kærlighed', 'like', 'affektion', 'par', 'ægteskab', 'brud', 'brudgom'],
    char: '\ud83d\udc92',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  klassisk_bygning: {
    keywords: ['kunst', 'kultur', 'historie'],
    char: '\ud83c\udfdb',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kirke: {
    keywords: ['bygning', 'religion', 'kristus'],
    char: '\u26ea',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  moske: {
    keywords: ['islam', 'tilbede', 'minaret'],
    char: '\ud83d\udd4c',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  synagoge: {
    keywords: ['jødedom', 'tilbede', 'tempel', 'jødisk'],
    char: '\ud83d\udd4d',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  kaaba: {
    keywords: ['mecca', 'moske', 'islam'],
    char: '\ud83d\udd4b',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  shinto_skrin: {
    keywords: ['tempel', 'japan', 'kyoto'],
    char: '\u26e9',
    fitzpatrick_scale: false,
    category: 'travel_and_places',
  },
  ur: {
    keywords: ['tid', 'tilbehør'],
    char: '\u231a',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  iphone: {
    keywords: ['teknologi', 'apple', 'gadgets', 'telefon'],
    char: '\ud83d\udcf1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  ringer_op: {
    keywords: ['iphone', 'indkomne'],
    char: '\ud83d\udcf2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  computer: {
    keywords: ['teknologi', 'laptop', 'screen', 'display', 'monitor'],
    char: '\ud83d\udcbb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  tastatur: {
    keywords: ['teknologi', 'computer', 'taste', 'input', 'text'],
    char: '\u2328',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  stationær_computer: {
    keywords: ['teknologi', 'computer', 'skærm'],
    char: '\ud83d\udda5',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  printer: {
    keywords: ['papir', 'blæk'],
    char: '\ud83d\udda8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  computermus: {
    keywords: ['click'],
    char: '\ud83d\uddb1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  trackball: {
    keywords: ['teknologi', 'trackpad'],
    char: '\ud83d\uddb2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  joystick: {
    keywords: ['spil', 'sjov'],
    char: '\ud83d\udd79',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  klemme: {
    keywords: ['værktøj'],
    char: '\ud83d\udddc',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  minidisc: {
    keywords: ['teknologi', 'optage', 'data', 'disk', '90s'],
    char: '\ud83d\udcbd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  floppy_disk: {
    keywords: ['oldschool', 'teknologi', 'gemme', '90s', '80s'],
    char: '\ud83d\udcbe',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  cd: {
    keywords: ['teknologi', 'dvd', 'disk', 'disc', '90s'],
    char: '\ud83d\udcbf',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dvd: {
    keywords: ['cd', 'disk', 'disc'],
    char: '\ud83d\udcc0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  vhs: {
    keywords: ['optagelse', 'video', 'oldschool', '90s', '80s'],
    char: '\ud83d\udcfc',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kamera: {
    keywords: ['gadgets', 'fotografi'],
    char: '\ud83d\udcf7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kamera_blitz: {
    keywords: ['fotografi', 'gadgets'],
    char: '\ud83d\udcf8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  videokamera: {
    keywords: ['film', 'optage'],
    char: '\ud83d\udcf9',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  film_kamera: {
    keywords: ['film', 'optage'],
    char: '\ud83c\udfa5',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  film_projektor: {
    keywords: ['video', 'tape', 'optage', 'film'],
    char: '\ud83d\udcfd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  filmstribe: {
    keywords: ['film'],
    char: '\ud83c\udf9e',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  telefon: {
    keywords: ['teknologi', 'kommunikation', 'urskive'],
    char: '\ud83d\udcde',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  phone: {
    keywords: ['teknologi', 'kommunikation', 'urskive', 'telefon'],
    char: '\u260e\ufe0f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  alarm: {
    keywords: ['bbcall', 'oldschool', 'pager', '90s'],
    char: '\ud83d\udcdf',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  fax: {
    keywords: ['kommunikation', 'teknologi'],
    char: '\ud83d\udce0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  tv: {
    keywords: ['teknologi', 'program', 'oldschool', 'show', 'fjernsyn'],
    char: '\ud83d\udcfa',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  radio: {
    keywords: ['kommunikation', 'musik', 'podcast', 'program'],
    char: '\ud83d\udcfb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  studiemikrofon: {
    keywords: ['synge', 'recording', 'kunstner', 'talkshow'],
    char: '\ud83c\udf99',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  justerbar_kontakt: {
    keywords: ['vægt'],
    char: '\ud83c\udf9a',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kontrolpanel: {
    keywords: ['dial', 'knapper'],
    char: '\ud83c\udf9b',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kompas: {
    keywords: ['magnetisk', 'navigation', 'orienteering'],
    char: '\ud83e\udded',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  stopur: {
    keywords: ['tid', 'deadline'],
    char: '\u23f1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  tidstager: {
    keywords: ['alarm'],
    char: '\u23f2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  alarmur: {
    keywords: ['tid', 'vågn_op'],
    char: '\u23f0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kaminur: {
    keywords: ['tid'],
    char: '\ud83d\udd70',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  timeglas_flydende_sand: {
    keywords: ['oldschool', 'tid', 'tælle'],
    char: '\u23f3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  timeglas: {
    keywords: ['tid', 'ur', 'oldschool', 'grænse', 'eksamen', 'quiz', 'test'],
    char: '\u231b',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  parabolantenne: {
    keywords: ['kommunikation', 'fremtid', 'radio', 'rummet'],
    char: '\ud83d\udce1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  batteri: {
    keywords: ['strøm', 'energi', 'sustain'],
    char: '\ud83d\udd0b',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  elektrisk_stik: {
    keywords: ['oplader', 'strøm'],
    char: '\ud83d\udd0c',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lyspære: {
    keywords: ['lys', 'elektricitet', 'ide'],
    char: '\ud83d\udca1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lysglimt: {
    keywords: ['mørk', 'camping', 'se', 'nat'],
    char: '\ud83d\udd26',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  stearinlys: {
    keywords: ['ild', 'voks'],
    char: '\ud83d\udd6f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  ildslukker: {
    keywords: ['slukke'],
    char: '\ud83e\uddef',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  papirkurv: {
    keywords: ['skraldespand', 'skrald', 'skrammel', 'affald', 'smide_ud'],
    char: '\ud83d\uddd1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  olietønde: {
    keywords: ['tønde'],
    char: '\ud83d\udee2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  penge_med_vinger: {
    keywords: ['dollar', 'regninger', 'betaling', 'salg'],
    char: '\ud83d\udcb8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dollar: {
    keywords: ['penge', 'salg', 'penge', 'pengeseddel', 'valuta'],
    char: '\ud83d\udcb5',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  yen: {
    keywords: ['penge', 'salg', 'penge', 'japansk', 'dollar', 'valuta'],
    char: '\ud83d\udcb4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  euro: {
    keywords: ['penge', 'pengeseddel', 'salg', 'dollar', 'valuta'],
    char: '\ud83d\udcb6',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  pound: {
    keywords: ['britisk', 'sterling', 'penge', 'salg', 'pengeseddel', 'uk', 'england', 'valuta'],
    char: '\ud83d\udcb7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  pengesæk: {
    keywords: ['dollar', 'betaling', 'mønter', 'sælge'],
    char: '\ud83d\udcb0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kreditkort: {
    keywords: ['penge', 'dankort', 'salg', 'dollar', 'pengeseddel', 'betaling', 'shopping'],
    char: '\ud83d\udcb3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  ædelsten: {
    keywords: ['blå', 'rubin', 'diamant', 'smykker'],
    char: '\ud83d\udc8e',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  vægt: {
    keywords: ['loven', 'retfærdighed', 'vægt'],
    char: '\u2696',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  værktøjskasse: {
    keywords: ['værktøj', 'diy', 'fix', 'vedligehold', 'mekaniker'],
    char: '\ud83e\uddf0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  skruenøgle: {
    keywords: ['værktøj', 'diy', 'ikea', 'fix', 'arbejde'],
    char: '\ud83d\udd27',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hammer: {
    keywords: ['værktøj', 'bygge', 'skabe'],
    char: '\ud83d\udd28',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hammer_og_mejsel: {
    keywords: ['værktøj', 'bygge', 'skabe'],
    char: '\u2692',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hammer_og_skruenøgle: {
    keywords: ['værktøj', 'bygge', 'skabe'],
    char: '\ud83d\udee0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hakke: {
    keywords: ['værktøj', 'grave'],
    char: '\u26cf',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  skrue_og_bolt: {
    keywords: ['handy', 'værktøj', 'fix'],
    char: '\ud83d\udd29',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  gear: {
    keywords: ['mekanisk'],
    char: '\u2699',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  mursten: {
    keywords: ['bricks'],
    char: '\ud83e\uddf1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kæder: {
    keywords: ['lås', 'arresteret'],
    char: '\u26d3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  magnet: {
    keywords: ['tiltrækning', 'magnetisk'],
    char: '\ud83e\uddf2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  skyder: {
    keywords: ['vold', 'våben', 'pistol', 'revolver'],
    char: '\ud83d\udd2b',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bombe: {
    keywords: ['boom', 'eksplodere', 'eksplosion', 'terror'],
    char: '\ud83d\udca3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  fyrværkeri2: {
    keywords: ['dynamit', 'boom', 'eksplodere', 'eksplosion', 'eksplosivt'],
    char: '\ud83e\udde8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  køkkenkniv: {
    keywords: ['kniv', 'knivblad', 'bestik', 'køkken', 'våben'],
    char: '\ud83d\udd2a',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dolk: {
    keywords: ['våben', 'kniv'],
    char: '\ud83d\udde1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  krydsede_sværd: {
    keywords: ['våben'],
    char: '\u2694',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  skjold: {
    keywords: ['beskyttelse', 'sikkerhed'],
    char: '\ud83d\udee1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  rygning: {
    keywords: ['dræber', 'tobak', 'cigaret', 'joint', 'røg'],
    char: '\ud83d\udeac',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kranie_og_krydsede_ben: {
    keywords: ['gift', 'fare', 'dødelig', 'uhyggelig', 'død', 'dødningehoved', 'pirat', 'ond'],
    char: '\u2620',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kiste: {
    keywords: ['vampyr', 'død', 'død', 'rip', 'gravplads', 'kirkegård', 'begravelse', 'kasse'],
    char: '\u26b0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  urne: {
    keywords: ['død', 'die', 'død', 'rip', 'asker'],
    char: '\u26b1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  amfora: {
    keywords: ['vase', 'jar'],
    char: '\ud83c\udffa',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  krystalkugle: {
    keywords: ['disco', 'fest', 'magi', 'cirkus', 'heks'],
    char: '\ud83d\udd2e',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bedekrans: {
    keywords: ['dhikr', 'religiøs'],
    char: '\ud83d\udcff',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  nazar_amulet: {
    keywords: ['bead', 'amulet'],
    char: '\ud83e\uddff',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  frisør: {
    keywords: ['hår', 'salon', 'stil'],
    char: '\ud83d\udc88',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  alembic: {
    keywords: ['destillering', 'kolbe', 'videnskab', 'eksperiment', 'kemi'],
    char: '\u2697',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  teleskop: {
    keywords: ['stjerner', 'rummet', 'zoom', 'videnskab', 'astronomi'],
    char: '\ud83d\udd2d',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  mikroskop: {
    keywords: ['laboratorie', 'eksperiment', 'zoomin', 'videnskab', 'studere'],
    char: '\ud83d\udd2c',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hul: {
    keywords: ['pinligt'],
    char: '\ud83d\udd73',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  pille: {
    keywords: ['sundhed', 'medicin', 'doktor', 'farmaci', 'drug'],
    char: '\ud83d\udc8a',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kanyle: {
    keywords: ['sundheds', 'sprøjte', 'hospital', 'drugs', 'blod', 'medicin', 'nål', 'doktor', 'sundhedsplejerske'],
    char: '\ud83d\udc89',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dna: {
    keywords: ['biolog', 'genetik', 'livet'],
    char: '\ud83e\uddec',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  mikrobe: {
    keywords: ['amoeba', 'sygdom', 'bakterie', 'corona'],
    char: '\ud83e\udda0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  petriskål: {
    keywords: ['bakterie', 'biologi', 'kultur', 'laboratorie'],
    char: '\ud83e\uddeb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  reagensglas: {
    keywords: ['kemi', 'eksperiment', 'laboratorie', 'videnskab'],
    char: '\ud83e\uddea',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  termometer: {
    keywords: ['vejret', 'temperatur', 'varm', 'kold'],
    char: '\ud83c\udf21',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kost: {
    keywords: ['flyvende', 'magi', 'heks'],
    char: '\ud83e\uddf9',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kurv: {
    keywords: ['vasketøj'],
    char: '\ud83e\uddfa',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  toiletpapir: {
    keywords: ['rulle'],
    char: '\ud83e\uddfb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  mærke: {
    keywords: ['salg', 'tag'],
    char: '\ud83c\udff7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bogmærke: {
    keywords: ['favorit', 'label', 'gemme'],
    char: '\ud83d\udd16',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  toilet: {
    keywords: ['badeværelse', 'wc', 'vaskerum', 'badeværelse', 'kummen'],
    char: '\ud83d\udebd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  brusebad: {
    keywords: ['ren', 'vand', 'badeværelse'],
    char: '\ud83d\udebf',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  karbad: {
    keywords: ['ren', 'bad', 'badeværelse'],
    char: '\ud83d\udec1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  sæbe: {
    keywords: ['bar', 'badning', 'ren', 'sæbeblok'],
    char: '\ud83e\uddfc',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  vaskesvamp: {
    keywords: ['absorbere', 'rengøring', 'porøs'],
    char: '\ud83e\uddfd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  creme: {
    keywords: ['fugtighedscreme', 'solcreme'],
    char: '\ud83e\uddf4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  nøgle: {
    keywords: ['lås', 'dør', 'kodeord'],
    char: '\ud83d\udd11',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  gammel_nøgle: {
    keywords: ['lås', 'dør', 'kodeord'],
    char: '\ud83d\udddd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  sofa_og_lampe: {
    keywords: ['læs', 'hygge'],
    char: '\ud83d\udecb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  sovende_seng: {
    keywords: ['seng', 'slappe_af'],
    char: '\ud83d\udecc',
    fitzpatrick_scale: true,
    category: 'objects',
  },
  seng: {
    keywords: ['sove', 'slappe_af'],
    char: '\ud83d\udecf',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dør: {
    keywords: ['hus', 'indgang', 'udgang'],
    char: '\ud83d\udeaa',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  receptionsklokke: {
    keywords: ['service'],
    char: '\ud83d\udece',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bamse: {
    keywords: ['plysset', 'fyldt'],
    char: '\ud83e\uddf8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  billede_i_ramme: {
    keywords: ['fotografi'],
    char: '\ud83d\uddbc',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  verdenskort: {
    keywords: ['lokation', 'sted', 'vejledning'],
    char: '\ud83d\uddfa',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  parasol_på_jorden: {
    keywords: ['vejret', 'sommer'],
    char: '\u26f1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  moyai_hoved: {
    keywords: ['sten', 'øst', 'moai'],
    char: '\ud83d\uddff',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indkøb: {
    keywords: ['indkøbscenter', 'køb', 'indkøb'],
    char: '\ud83d\udecd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indkøbsvogn: {
    keywords: ['vogn'],
    char: '\ud83d\uded2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  ballon: {
    keywords: ['fest', 'fejring', 'fødselsdag', 'cirkus'],
    char: '\ud83c\udf88',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  flag: {
    keywords: ['fisk', 'japansk', 'koinobori', 'karpe', 'banner'],
    char: '\ud83c\udf8f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bånd: {
    keywords: ['dekoration', 'pink', 'pige', 'buetie'],
    char: '\ud83c\udf80',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  gave: {
    keywords: ['gave', 'fødselsdag', 'jul', 'xmas'],
    char: '\ud83c\udf81',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  konfetti_bold: {
    keywords: ['festival', 'fest', 'fødselsdag', 'cirkus'],
    char: '\ud83c\udf8a',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  konfetti: {
    keywords: ['fest', 'tillykke', 'fødselsdag', 'magi', 'cirkus', 'fejring'],
    char: '\ud83c\udf89',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dukker: {
    keywords: ['japansk', 'legetøj', 'kimono'],
    char: '\ud83c\udf8e',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  vindklokke: {
    keywords: ['natur', 'ding', 'forår', 'klokke'],
    char: '\ud83c\udf90',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  krydsede_flag: {
    keywords: ['japansk', 'nation', 'land', 'grænse'],
    char: '\ud83c\udf8c',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  izakaya_lanterne: {
    keywords: ['lys', 'papir', 'halloween', 'spooky'],
    char: '\ud83c\udfee',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  rødt_brev: {
    keywords: ['gift'],
    char: '\ud83e\udde7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  email: {
    keywords: ['brev', 'post', 'inbox', 'kommunikation'],
    char: '\u2709\ufe0f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  brev_med_pil: {
    keywords: ['email', 'kommunikation'],
    char: '\ud83d\udce9',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indgående_mail: {
    keywords: ['email', 'inbox'],
    char: '\ud83d\udce8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  'e-mail': {
    keywords: ['kommunikation', 'inbox'],
    char: '\ud83d\udce7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kærlighedsbrev: {
    keywords: ['email', 'like', 'affektion', 'brev', 'valentines'],
    char: '\ud83d\udc8c',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  postkasse: {
    keywords: ['email', 'brev', 'brev'],
    char: '\ud83d\udcee',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  postkasse_lukket_sænket_flag: {
    keywords: ['email', 'kommunikation', 'inbox'],
    char: '\ud83d\udcea',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  postkasse_lukket_hævet_flag: {
    keywords: ['email', 'inbox', 'kommunikation'],
    char: '\ud83d\udceb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  postkasse_med_post: {
    keywords: ['email', 'inbox', 'kommunikation'],
    char: '\ud83d\udcec',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  postkasse_uden_post: {
    keywords: ['email', 'inbox'],
    char: '\ud83d\udced',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  pakke: {
    keywords: ['mail', 'gave', 'papkasse', 'kasse', 'flytte'],
    char: '\ud83d\udce6',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  posthorn: {
    keywords: ['instrument', 'musik'],
    char: '\ud83d\udcef',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indbakke: {
    keywords: ['email', 'dokumenter'],
    char: '\ud83d\udce5',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  udbakke: {
    keywords: ['inbox', 'email'],
    char: '\ud83d\udce4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  rulle: {
    keywords: ['dokumenter', 'gammel', 'historie', 'papir'],
    char: '\ud83d\udcdc',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  papir_med_fold: {
    keywords: ['dokumenter', 'kontor', 'papir'],
    char: '\ud83d\udcc3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bogmærke_markering: {
    keywords: ['favorit', 'gemme', 'orden', 'ryddelig'],
    char: '\ud83d\udcd1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kvittering: {
    keywords: ['regnskab', 'udgifter'],
    char: '\ud83e\uddfe',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  infograf: {
    keywords: ['graf', 'presentation', 'stats'],
    char: '\ud83d\udcca',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  graf_med_opadgående_trend: {
    keywords: ['graf', 'presentation', 'stats', 'opad', 'business', 'økonomi', 'penge', 'salg', 'godt', 'succes'],
    char: '\ud83d\udcc8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  graf_med_nedadgående_trend: {
    keywords: ['graf', 'presentation', 'stats', 'nedad', 'business', 'økonomi', 'penge', 'salg', 'dårlig', 'fejl'],
    char: '\ud83d\udcc9',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  side_der_vender_opad: {
    keywords: ['dokumenter', 'kontor', 'papir', 'information'],
    char: '\ud83d\udcc4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  dato: {
    keywords: ['calendar', 'skema'],
    char: '\ud83d\udcc5',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kalender: {
    keywords: ['skema', 'dato', 'planlægning'],
    char: '\ud83d\udcc6',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  spiralkalender: {
    keywords: ['dato', 'skema', 'planlægning'],
    char: '\ud83d\uddd3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indekskort: {
    keywords: ['business', 'gammeldags'],
    char: '\ud83d\udcc7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indekskasse: {
    keywords: ['business', 'dokumenter', 'papirvarer'],
    char: '\ud83d\uddc3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  stemmeboks: {
    keywords: ['valg', 'stemme'],
    char: '\ud83d\uddf3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  arkivskab: {
    keywords: ['arkivering', 'organisering'],
    char: '\ud83d\uddc4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  udklipsholder: {
    keywords: ['papirvarer', 'dokumenter'],
    char: '\ud83d\udccb',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  spiral_notesblok: {
    keywords: ['memo', 'papirvarer'],
    char: '\ud83d\uddd2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  filmappe: {
    keywords: ['dokumenter', 'business', 'kontor'],
    char: '\ud83d\udcc1',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  åben_filmappe: {
    keywords: ['dokumenter', 'indlæse'],
    char: '\ud83d\udcc2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  indekserings_inddelere: {
    keywords: ['organisering', 'business', 'gammeldags'],
    char: '\ud83d\uddc2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  avisrulle: {
    keywords: ['pressen', 'overskrift'],
    char: '\ud83d\uddde',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  avis: {
    keywords: ['pressen', 'overskrift'],
    char: '\ud83d\udcf0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  noteshæfte: {
    keywords: ['gammeldags', 'nedskrive', 'noter', 'papir', 'studere'],
    char: '\ud83d\udcd3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lukket_bog: {
    keywords: ['læs', 'bibliotek', 'viden', 'textbook', 'lære'],
    char: '\ud83d\udcd5',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  grøn_bog: {
    keywords: ['læs', 'bibliotek', 'viden', 'studere'],
    char: '\ud83d\udcd7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  blå_bog: {
    keywords: ['læs', 'bibliotek', 'viden', 'lære', 'studere'],
    char: '\ud83d\udcd8',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  orange_bog: {
    keywords: ['læs', 'bibliotek', 'viden', 'textbook', 'studere'],
    char: '\ud83d\udcd9',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  notesbog_med_dekorativ_forside: {
    keywords: ['klasseværelse', 'noter', 'optage', 'papir', 'studere'],
    char: '\ud83d\udcd4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  ringbind: {
    keywords: ['noter', 'mappe', 'papir'],
    char: '\ud83d\udcd2',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  bøger: {
    keywords: ['litteratur', 'bibliotek', 'studere'],
    char: '\ud83d\udcda',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  åben_bog: {
    keywords: ['bog', 'læs', 'bibliotek', 'viden', 'litteratur', 'lære', 'studere'],
    char: '\ud83d\udcd6',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  sikkerhedsnål: {
    keywords: ['ble'],
    char: '\ud83e\uddf7',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  link: {
    keywords: ['ringe', 'url'],
    char: '\ud83d\udd17',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  papirklips: {
    keywords: ['dokumenter', 'gammeldags'],
    char: '\ud83d\udcce',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  papirklips2: {
    keywords: ['dokumenter', 'papirvarer'],
    char: '\ud83d\udd87',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  sakse: {
    keywords: ['papirvarer', 'klippe'],
    char: '\u2702\ufe0f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  trekantet_lineal: {
    keywords: ['papirvarer', 'matematik', 'arkitekt', 'tegne'],
    char: '\ud83d\udcd0',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lineal: {
    keywords: ['papirvarer', 'udregne', 'længde', 'matematik', 'skole', 'tegne', 'arkitekt', 'sketch'],
    char: '\ud83d\udccf',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kugleramme: {
    keywords: ['udregne'],
    char: '\ud83e\uddee',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  knappenål: {
    keywords: ['papirvarer', 'markør', 'her'],
    char: '\ud83d\udccc',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  rund_knappenål: {
    keywords: ['papirvarer', 'lokation', 'kort', 'her'],
    char: '\ud83d\udccd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  trekantet_flag_på_post: {
    keywords: ['markør', 'milesten', 'sted'],
    char: '\ud83d\udea9',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hvidt_flag: {
    keywords: ['taber', 'nederlag', 'tabt', 'opgive', 'opgive', 'fejle'],
    char: '\ud83c\udff3',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  sort_flag: {
    keywords: ['pirat'],
    char: '\ud83c\udff4',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  regnbueflag: {
    keywords: [
      'flag',
      'regnbue',
      'pride',
      'gay',
      'lgbt',
      'glbt',
      'homo',
      'homoseksuel',
      'lesbisk',
      'biseksuel',
      'transkønnet',
    ],
    char: '\ud83c\udff3\ufe0f\u200d\ud83c\udf08',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lukket_lås_med_nøgle: {
    keywords: ['sikkerhed', 'privat'],
    char: '\ud83d\udd10',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lås: {
    keywords: ['sikkerhed', 'kodeord', 'hængelås'],
    char: '\ud83d\udd12',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  ulåst: {
    keywords: ['privat', 'sikkerhed'],
    char: '\ud83d\udd13',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  lås_med_kuglepen: {
    keywords: ['sikkerhed', 'hemmelig'],
    char: '\ud83d\udd0f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  kuglepen: {
    keywords: ['papirvarer', 'skrive', 'skriv'],
    char: '\ud83d\udd8a',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  fyldepen: {
    keywords: ['papirvarer', 'skrive', 'skriv'],
    char: '\ud83d\udd8b',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  fjerpen: {
    keywords: ['pen', 'papirvarer', 'skrive', 'skriv'],
    char: '\u2712\ufe0f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  memo: {
    keywords: [
      'skriv',
      'dokumenter',
      'papirvarer',
      'blyant',
      'papir',
      'skrive',
      'lov',
      'eksamen',
      'quiz',
      'test',
      'studere',
      'udforme',
    ],
    char: '\ud83d\udcdd',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  blyant2: {
    keywords: ['papirvarer', 'skriv', 'papir', 'skrive', 'skole', 'studere'],
    char: '\u270f\ufe0f',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  farveblyant: {
    keywords: ['tegne', 'kreativitet'],
    char: '\ud83d\udd8d',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  malerpensel: {
    keywords: ['tegne', 'kreativitet', 'kunst'],
    char: '\ud83d\udd8c',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  forstørrelsesglas: {
    keywords: ['søge', 'zoom', 'find', 'detektiv'],
    char: '\ud83d\udd0d',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  forstørrelsesglas2: {
    keywords: ['søge', 'zoom', 'find', 'detektiv'],
    char: '\ud83d\udd0e',
    fitzpatrick_scale: false,
    category: 'objects',
  },
  hjerte: {
    keywords: ['kærlighed', 'like', 'valentines'],
    char: '\u2764\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  orange_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83e\udde1',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  gult_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc9b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  grønt_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc9a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  blåt_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc99',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  lilla_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc9c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_hjerte: {
    keywords: ['ond'],
    char: '\ud83d\udda4',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  knust_hjerte: {
    keywords: ['trist', 'undskyld', 'kærestesorg', 'hjerte', 'sønderknust'],
    char: '\ud83d\udc94',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hjerte_som_udråbstegn: {
    keywords: ['dekoration', 'kærlighed'],
    char: '\u2763',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  to_hjerter: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines', 'hjerte'],
    char: '\ud83d\udc95',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  snurrende_hjerter: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc9e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pulserende_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines', 'pink', 'hjerte'],
    char: '\ud83d\udc93',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  voksende_hjerte: {
    keywords: ['like', 'kærlighed', 'affektion', 'valentines', 'pink'],
    char: '\ud83d\udc97',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  funklende_hjerte: {
    keywords: ['kærlighed', 'like', 'affektion', 'valentines'],
    char: '\ud83d\udc96',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  amor: {
    keywords: ['kærlighed', 'cupid', 'hjerte_med_pil_igennem', 'like', 'hjerte', 'affektion', 'valentines'],
    char: '\ud83d\udc98',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  indpakket_hjerte: {
    keywords: ['kærlighed', 'valentines'],
    char: '\ud83d\udc9d',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hjerte_dekoration: {
    keywords: ['_halvmåne', 'kærlighed', 'like'],
    char: '\ud83d\udc9f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  fredssymbol: {
    keywords: ['hippie'],
    char: '\u262e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  latin_kors: {
    keywords: ['kristendom'],
    char: '\u271d',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stjerne_og_halvmåne: {
    keywords: ['islam'],
    char: '\u262a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  om: {
    keywords: ['hinduism', 'buddhism', 'sikhism', 'jainism'],
    char: '\ud83d\udd49',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  dharmas_hjul: {
    keywords: ['hinduism', 'buddhism', 'sikhism', 'jainism'],
    char: '\u2638',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  davids_stjerne: {
    keywords: ['jødedom'],
    char: '\u2721',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sekskantet_stjerne: {
    keywords: ['lilla-firkant', 'religion', 'jødisk', 'hexagram'],
    char: '\ud83d\udd2f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  menorah: {
    keywords: ['hanukkah', 'stearinlys', 'jødisk'],
    char: '\ud83d\udd4e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  yin_yang: {
    keywords: ['balance'],
    char: '\u262f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ortodoks_kors: {
    keywords: ['suppedaneum', 'religion'],
    char: '\u2626',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  bederum: {
    keywords: ['religion', 'kirke', 'tempel', 'bøn'],
    char: '\ud83d\uded0',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  slangebæreren: {
    keywords: ['tegn', 'lilla-firkant', 'konstellation', 'astrologi'],
    char: '\u26ce',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vædderen: {
    keywords: ['tegn', 'lilla-firkant', 'zodiac', 'astrologi'],
    char: '\u2648',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  taurus: {
    keywords: ['lilla-firkant', 'tegn', 'zodiac', 'astrologi'],
    char: '\u2649',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tvillingen: {
    keywords: ['tegn', 'zodiac', 'lilla-firkant', 'astrologi'],
    char: '\u264a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  krebsen: {
    keywords: ['tegn', 'zodiac', 'lilla-firkant', 'astrologi'],
    char: '\u264b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  løven: {
    keywords: ['tegn', 'lilla-firkant', 'zodiac', 'astrologi'],
    char: '\u264c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  jomfruen: {
    keywords: ['tegn', 'zodiac', 'lilla-firkant', 'astrologi'],
    char: '\u264d',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vægten: {
    keywords: ['tegn', 'lilla-firkant', 'zodiac', 'astrologi'],
    char: '\u264e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  skorpionen: {
    keywords: ['tegn', 'zodiac', 'lilla-firkant', 'astrologi', 'scorpio'],
    char: '\u264f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  skytten: {
    keywords: ['tegn', 'zodiac', 'lilla-firkant', 'astrologi'],
    char: '\u2650',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stenbukken: {
    keywords: ['tegn', 'zodiac', 'lilla-firkant', 'astrologi'],
    char: '\u2651',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vandmanden: {
    keywords: ['tegn', 'lilla-firkant', 'zodiac', 'astrologi'],
    char: '\u2652',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  fisken: {
    keywords: ['lilla-firkant', 'tegn', 'zodiac', 'astrologi'],
    char: '\u2653',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  id: {
    keywords: ['lilla-firkant', 'ord'],
    char: '\ud83c\udd94',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  atom_symbol: {
    keywords: ['videnskab', 'fysik', 'kemi'],
    char: '\u269b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u7a7a: {
    keywords: ['kanji', 'japansk', 'kinesisk', 'empty', 'sky', 'blå-firkant'],
    char: '\ud83c\ude33',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u5272: {
    keywords: ['cut', 'divide', 'kinesisk', 'kanji', 'pink-firkant'],
    char: '\ud83c\ude39',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  radioaktivt: {
    keywords: ['atomisk', 'fare'],
    char: '\u2622',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  biologisk_fare: {
    keywords: ['fare'],
    char: '\u2623',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  mobiltelefon_slukket: {
    keywords: ['forstyr_ikke', 'orange-firkant', 'stille', 'rolig'],
    char: '\ud83d\udcf4',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vibrations_indstilling: {
    keywords: ['orange-firkant', 'telefon'],
    char: '\ud83d\udcf3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u6709: {
    keywords: ['orange-firkant', 'kinesisk', 'have', 'kanji'],
    char: '\ud83c\ude36',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u7121: {
    keywords: ['nothing', 'kinesisk', 'kanji', 'japansk', 'orange-firkant'],
    char: '\ud83c\ude1a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u7533: {
    keywords: ['kinesisk', 'japansk', 'kanji', 'orange-firkant'],
    char: '\ud83c\ude38',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u55b6: {
    keywords: ['japansk', 'åbningstider', 'orange-firkant'],
    char: '\ud83c\ude3a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u6708: {
    keywords: ['kinesisk', 'måned', 'måne', 'japansk', 'orange-firkant', 'kanji'],
    char: '\ud83c\ude37\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ottekantet_sort_stjerne: {
    keywords: ['orange-firkant', 'form', 'polygon'],
    char: '\u2734\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vs: {
    keywords: ['ord', 'orange-firkant'],
    char: '\ud83c\udd9a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  accepter: {
    keywords: ['ok', 'godt', 'kinesisk', 'kanji', 'enig', 'ja', 'orange-cirkel'],
    char: '\ud83c\ude51',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_blomst: {
    keywords: ['japansk', 'forår'],
    char: '\ud83d\udcae',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  cirkulært_ideogram: {
    keywords: ['kinesisk', 'kanji', 'tilegne', 'få', 'cirkel'],
    char: '\ud83c\ude50',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hemmelig: {
    keywords: ['privat', 'kinesisk', 'sshh', 'kanji', 'rød-cirkel'],
    char: '\u3299\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tillykke: {
    keywords: ['kinesisk', 'kanji', 'japansk', 'rød-cirkel'],
    char: '\u3297\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u5408: {
    keywords: ['japansk', 'kinesisk', 'join', 'kanji', 'rød-firkant'],
    char: '\ud83c\ude34',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u6e80: {
    keywords: ['fuld', 'kinesisk', 'japansk', 'rød-firkant', 'kanji'],
    char: '\ud83c\ude35',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u7981: {
    keywords: ['kanji', 'japansk', 'kinesisk', 'forbidden', 'grænse', 'begrænset', 'rød-firkant'],
    char: '\ud83c\ude32',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  a: {
    keywords: ['rød-firkant', 'alfabet', 'brev'],
    char: '\ud83c\udd70\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  b: {
    keywords: ['rød-firkant', 'alfabet', 'brev'],
    char: '\ud83c\udd71\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ab: {
    keywords: ['rød-firkant', 'alfabet'],
    char: '\ud83c\udd8e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  cl: {
    keywords: ['alfabet', 'ord', 'rød-firkant'],
    char: '\ud83c\udd91',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  o2: {
    keywords: ['alfabet', 'rød-firkant', 'brev'],
    char: '\ud83c\udd7e\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sos: {
    keywords: ['hjælp', 'rød-firkant', 'ord', 'nødsituation', '112'],
    char: '\ud83c\udd98',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_adgang: {
    keywords: ['grænse', 'sikkerhed', 'privat', 'dårlig', 'afvist', 'stop', 'cirkel'],
    char: '\u26d4',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  navneskilt: {
    keywords: ['ild', 'forbyd'],
    char: '\ud83d\udcdb',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_adgang_skilt: {
    keywords: ['forbudt', 'stop', 'grænse', 'afvist', 'afvise', 'cirkel'],
    char: '\ud83d\udeab',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  x: {
    keywords: ['nej', 'delete', 'fjerne', 'afvise', 'rød'],
    char: '\u274c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  o: {
    keywords: ['cirkel', 'rund'],
    char: '\u2b55',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stop_skilt: {
    keywords: ['stop'],
    char: '\ud83d\uded1',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vrede: {
    keywords: ['vred', 'sur'],
    char: '\ud83d\udca2',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  dampende: {
    keywords: ['bad', 'varmt', 'afslapning'],
    char: '\u2668\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_forgængere: {
    keywords: ['regler', 'krydsende', 'gående', 'cirkel'],
    char: '\ud83d\udeb7',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_affald: {
    keywords: ['skrald', 'skraldespand', 'affald', 'cirkel'],
    char: '\ud83d\udeaf',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_cykler: {
    keywords: ['cyklist', 'forbudt', 'cirkel'],
    char: '\ud83d\udeb3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  intet_vand: {
    keywords: ['drik', 'vandhane', 'hane', 'cirkel'],
    char: '\ud83d\udeb1',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  mindreårig: {
    keywords: ['18', 'drik', 'bar', 'nat', 'mindreårig', 'cirkel'],
    char: '\ud83d\udd1e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_mobiltelefoner: {
    keywords: ['iphone', 'lydløs', 'cirkel'],
    char: '\ud83d\udcf5',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  udråbstegn: {
    keywords: ['tungt_udråbstegn_mark', 'fare', 'overraskelse', 'tegnsætning', 'wow', 'advarsel'],
    char: '\u2757',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  gråt_udråbstegn: {
    keywords: ['overraskelse', 'tegnsætning', 'gråt', 'wow', 'advarsel'],
    char: '\u2755',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  spørgsmål: {
    keywords: ['tvivl', 'forvirret'],
    char: '\u2753',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  gråt_spørgsmålstegn: {
    keywords: ['tvivls', 'gråt', 'hvad', 'forvirret'],
    char: '\u2754',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  dobbelt_udråbstegn: {
    keywords: ['udråbstegn', 'overraskelse'],
    char: '\u203c\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  udråbstegn_og_spørgsmålstegn: {
    keywords: ['hvad?!', 'tegnsætning', 'overraskelse'],
    char: '\u2049\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  100: {
    keywords: ['fedt_mand', 'perfekt', 'tal', 'århundrede', 'eksamen', 'quiz', 'test', 'pass', 'hundrede'],
    char: '\ud83d\udcaf',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  svagt_lys: {
    keywords: ['sol', 'eftermiddag', 'varmt', 'sommer'],
    char: '\ud83d\udd05',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stærkt_lys: {
    keywords: ['sol', 'lys'],
    char: '\ud83d\udd06',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  trefork: {
    keywords: ['våben', 'spyd'],
    char: '\ud83d\udd31',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  fransk_lilje: {
    keywords: ['dekorativt', 'spejder'],
    char: '\u269c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sangen_begynder_tegn: {
    keywords: [
      'graf',
      'presentation',
      'stats',
      'business',
      'roskilde',
      'festival',
      'japansk',
      'musik',
      'økonomi',
      'dårlig',
    ],
    char: '\u303d\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  advarsel: {
    keywords: ['udråbstegn', 'wip', 'advarsel', 'fejl', 'problem', 'problem'],
    char: '\u26a0\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  børn_krydser: {
    keywords: ['skole', 'advarsel', 'fare', 'tegn', 'køre', 'gul-diamant'],
    char: '\ud83d\udeb8',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  beginner: {
    keywords: ['skilt', 'skjold'],
    char: '\ud83d\udd30',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  genbruge: {
    keywords: ['pil', 'miljø', 'affald', 'skrald'],
    char: '\u267b\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  u6307: {
    keywords: ['kinesisk', 'pege', 'grøn-firkant', 'kanji'],
    char: '\ud83c\ude2f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  graf: {
    keywords: ['grøn-firkant', 'graf', 'presentation', 'stats'],
    char: '\ud83d\udcb9',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stjernestøv: {
    keywords: ['stjerner', 'grøn-firkant', 'fantastisk', 'godt', 'fyrværkeri'],
    char: '\u2747\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  otte_spidser_stjerne: {
    keywords: ['stjerne', 'sparkle', 'grøn-firkant'],
    char: '\u2733\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  negativ_firkantet_kors_markør: {
    keywords: ['x', 'grøn-firkant', 'nej', 'afvise'],
    char: '\u274e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_afkrydsning: {
    keywords: ['grøn-firkant', 'ok', 'enig', 'stemme', 'valg', 'answer', 'checke_af'],
    char: '\u2705',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  diamant_form_med_en_prik_indeni: {
    keywords: ['juvel', 'blå', 'ædelsten', 'krystal', 'fornem'],
    char: '\ud83d\udca0',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  cyklon: {
    keywords: ['vejret', 'swirl', 'blå', 'sky', 'vortex', 'spiral', 'hvirvelvind', 'spin', 'tornado', 'orkan', 'tyfon'],
    char: '\ud83c\udf00',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  loop: {
    keywords: ['bånd', 'kassette'],
    char: '\u27bf',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  globe_med_meridianer: {
    keywords: ['jorden', 'international', 'verden', 'internet', 'interweb', 'i18n'],
    char: '\ud83c\udf10',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  m: {
    keywords: ['alfabet', 'blå-cirkel', 'brev'],
    char: '\u24c2\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hæveautomat: {
    keywords: ['penge', 'salg', 'kontanter', 'blå-firkant', 'betaling', 'bank'],
    char: '\ud83c\udfe7',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sa: {
    keywords: ['japansk', 'blå-firkant', 'katakana'],
    char: '\ud83c\ude02\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pas_kontrol: {
    keywords: ['told', 'blå-firkant'],
    char: '\ud83d\udec2',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  told: {
    keywords: ['pas', 'grænse', 'blå-firkant'],
    char: '\ud83d\udec3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  bagageudlevering: {
    keywords: ['blå-firkant', 'lufthavn', 'transport'],
    char: '\ud83d\udec4',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  efterladt_bagage: {
    keywords: ['blå-firkant', 'rejse'],
    char: '\ud83d\udec5',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  kørestol: {
    keywords: ['blå-firkant', 'handicappet', 'a11y', 'tilgængelighed'],
    char: '\u267f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_rygning: {
    keywords: ['cigaret', 'blå-firkant', 'lugte', 'røg'],
    char: '\ud83d\udead',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  wc: {
    keywords: ['toilet', 'badeværelse', 'blå-firkant'],
    char: '\ud83d\udebe',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  parkonge: {
    keywords: ['biler', 'blå-firkant', 'alfabet', 'brev'],
    char: '\ud83c\udd7f\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  vandhane: {
    keywords: ['blå-firkant', 'væske', 'badeværelse', 'rengøring', 'vandhane'],
    char: '\ud83d\udeb0',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  herre: {
    keywords: ['toilet', 'badeværelse', 'wc', 'blå-firkant', 'køn', 'mand'],
    char: '\ud83d\udeb9',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  damer: {
    keywords: ['lilla-firkant', 'kvinde', 'dame', 'toilet', 'toilet', 'badeværelse', 'køn'],
    char: '\ud83d\udeba',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  baby_symbol: {
    keywords: ['orange-firkant', 'barn'],
    char: '\ud83d\udebc',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  toiletskilt: {
    keywords: ['blå-firkant', 'toilet', 'genopfriske', 'wc', 'køn'],
    char: '\ud83d\udebb',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  smide_skrald_ud: {
    keywords: ['blå-firkant', 'tegn', 'menneske', 'info'],
    char: '\ud83d\udeae',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  biograf: {
    keywords: ['blå-firkant', 'optage', 'film', 'film', 'forhæng', 'stage', 'teater'],
    char: '\ud83c\udfa6',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  signal_styrke: {
    keywords: ['blå-firkant', 'reception', 'telefon', 'internet', 'forbindelse', 'wifi', 'bluetooth', 'streger'],
    char: '\ud83d\udcf6',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  koko: {
    keywords: ['blå-firkant', 'her', 'katakana', 'japansk', 'destination'],
    char: '\ud83c\ude01',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ng: {
    keywords: ['blå-firkant', 'ord', 'form', 'ikon'],
    char: '\ud83c\udd96',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ok: {
    keywords: ['godt', 'enig', 'ja', 'blå-firkant'],
    char: '\ud83c\udd97',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  op: {
    keywords: ['blå-firkant', 'ovenover', 'højt'],
    char: '\ud83c\udd99',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  cool: {
    keywords: ['ord', 'blå-firkant'],
    char: '\ud83c\udd92',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ny: {
    keywords: ['blå-firkant', 'ord', 'start'],
    char: '\ud83c\udd95',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ledig: {
    keywords: ['blå-firkant', 'free', 'ord'],
    char: '\ud83c\udd93',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  zero: {
    keywords: ['0', 'tal', 'blå-firkant', 'nul'],
    char: '0\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  et: {
    keywords: ['blå-firkant', 'tal', '1'],
    char: '1\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  to: {
    keywords: ['tal', '2', 'primær', 'blå-firkant'],
    char: '2\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tre: {
    keywords: ['3', 'tal', 'primær', 'blå-firkant'],
    char: '3\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  fire: {
    keywords: ['4', 'tal', 'blå-firkant'],
    char: '4\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  fem: {
    keywords: ['5', 'tal', 'blå-firkant', 'primær'],
    char: '5\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  seks: {
    keywords: ['6', 'tal', 'blå-firkant'],
    char: '6\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  syv: {
    keywords: ['7', 'tal', 'blå-firkant', 'primær'],
    char: '7\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  otte: {
    keywords: ['8', 'blå-firkant', 'tal'],
    char: '8\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ni: {
    keywords: ['blå-firkant', 'tal', '9'],
    char: '9\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ti: {
    keywords: ['tal', '10', 'blå-firkant'],
    char: '\ud83d\udd1f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  asterisk: {
    keywords: ['stjerne', 'symbol', 'keycap'],
    char: '*\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  1234: {
    keywords: ['tal', 'blå-firkant'],
    char: '\ud83d\udd22',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  udstøde_knap: {
    keywords: ['blå-firkant'],
    char: '\u23cf\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_fremad: {
    keywords: ['blå-firkant', 'højre', 'vejledning', 'afspil'],
    char: '\u25b6\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pause_knap: {
    keywords: ['pause', 'blå-firkant'],
    char: '\u23f8',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  næste_sang_knap: {
    keywords: ['fremad', 'næste', 'blå-firkant'],
    char: '\u23ed',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stop_knap: {
    keywords: ['blå-firkant'],
    char: '\u23f9',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  optage_knap: {
    keywords: ['blå-firkant'],
    char: '\u23fa',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  afspil_eller_pause_knap: {
    keywords: ['blå-firkant', 'spil', 'pause'],
    char: '\u23ef',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  forrige_nummer_knap: {
    keywords: ['backward'],
    char: '\u23ee',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hurtig_fremad: {
    keywords: ['blå-firkant', 'spil', 'fart', 'fortsæt'],
    char: '\u23e9',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  spole_tilbage: {
    keywords: ['spil', 'blå-firkant'],
    char: '\u23ea',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  krydsede_pile: {
    keywords: ['blå-firkant', 'blande', 'musik', 'tilfældig'],
    char: '\ud83d\udd00',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  gentage: {
    keywords: ['loop', 'optage'],
    char: '\ud83d\udd01',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  gentage_en: {
    keywords: ['blå-firkant', 'loop'],
    char: '\ud83d\udd02',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_tilbage: {
    keywords: ['blå-firkant', 'venstre', 'vejledning'],
    char: '\u25c0\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_op_lille: {
    keywords: ['blå-firkant', 'triangle', 'vejledning', 'peg', 'fremad', 'top'],
    char: '\ud83d\udd3c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_ned_lille: {
    keywords: ['blå-firkant', 'vejledning', 'bund'],
    char: '\ud83d\udd3d',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_double_op: {
    keywords: ['blå-firkant', 'vejledning', 'top'],
    char: '\u23eb',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_double_ned: {
    keywords: ['blå-firkant', 'vejledning', 'bund'],
    char: '\u23ec',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_højre: {
    keywords: ['blå-firkant', 'næste'],
    char: '\u27a1\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_venstre: {
    keywords: ['blå-firkant', 'forrige', 'tilbage'],
    char: '\u2b05\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_op: {
    keywords: ['blå-firkant', 'fortsæt', 'top', 'vejledning'],
    char: '\u2b06\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_ned: {
    keywords: ['blå-firkant', 'vejledning', 'bund'],
    char: '\u2b07\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_øvre_højre: {
    keywords: ['blå-firkant', 'peg', 'vejledning', 'diagonal', 'nordøst'],
    char: '\u2197\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_nederste_højre: {
    keywords: ['blå-firkant', 'vejledning', 'diagonal', 'sydøst'],
    char: '\u2198\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_nedre_venstre: {
    keywords: ['blå-firkant', 'vejledning', 'diagonal', 'sydvest'],
    char: '\u2199\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_opper_venstre: {
    keywords: ['blå-firkant', 'peg', 'vejledning', 'diagonal', 'nordvest'],
    char: '\u2196\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_op_ned: {
    keywords: ['blå-firkant', 'vejledning', 'vej', 'vertikal'],
    char: '\u2195\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  venstre_højre_pil: {
    keywords: ['form', 'vejledning', 'horizontal', 'sidelæns'],
    char: '\u2194\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pile_modsat_urets_retning: {
    keywords: ['blå-firkant', 'sync', 'cyklus'],
    char: '\ud83d\udd04',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_højre_krog: {
    keywords: ['blå-firkant', 'returnere', 'rotere', 'vejledning'],
    char: '\u21aa\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  venstre_pil_med_krog: {
    keywords: ['back', 'returnere', 'blå-firkant', 'fortryde', 'enter'],
    char: '\u21a9\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_på_vej_op: {
    keywords: ['blå-firkant', 'vejledning', 'top'],
    char: '\u2934\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pil_på_vej_ned: {
    keywords: ['blå-firkant', 'vejledning', 'bund'],
    char: '\u2935\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hash_tag: {
    keywords: ['symbol', 'blå-firkant', 'twitter'],
    char: '#\ufe0f\u20e3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  information_kilde: {
    keywords: ['blå-firkant', 'alfabet', 'brev'],
    char: '\u2139\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  abc: {
    keywords: ['blå-firkant', 'alfabet'],
    char: '\ud83d\udd24',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  abcd: {
    keywords: ['blå-firkant', 'alfabet'],
    char: '\ud83d\udd21',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  med_store_bogstaver_abcd: {
    keywords: ['alfabet', 'ord', 'blå-firkant'],
    char: '\ud83d\udd20',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  symboler: {
    keywords: ['blå-firkant', 'musik', 'note', 'ampersand', 'procent', 'glyphs', 'karakterer'],
    char: '\ud83d\udd23',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  musikalsk_node: {
    keywords: ['partitur', 'tone', 'lyd'],
    char: '\ud83c\udfb5',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  noder: {
    keywords: ['musik', 'partitur'],
    char: '\ud83c\udfb6',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  bølgende_streg: {
    keywords: ['tegne', 'line', 'moustache', 'mustache', 'squiggle', 'scribble'],
    char: '\u3030\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  krøllet_loop: {
    keywords: ['scribble', 'tegne', 'form', 'squiggle'],
    char: '\u27b0',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tungt_afkrydsning: {
    keywords: ['ok', 'nike', 'answer', 'ja', 'krydse_af'],
    char: '\u2714\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  pile_med_urets_retning: {
    keywords: ['sync', 'cyklus', 'rund', 'gentage'],
    char: '\ud83d\udd03',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tungt_plus_skilt: {
    keywords: ['matematik', 'udregning', 'addition', 'mere', 'tiltage'],
    char: '\u2795',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tungt_minus_skilt: {
    keywords: ['matematik', 'udregning', 'fratrække', 'mindre'],
    char: '\u2796',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tungt_divisions_skilt: {
    keywords: ['dividere', 'matematik', 'udregning'],
    char: '\u2797',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tungt_multiplikation_x: {
    keywords: ['matematik', 'udregning'],
    char: '\u2716\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  uendelighed: {
    keywords: ['forevigt'],
    char: '\u267e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tungt_dollar_skilt: {
    keywords: ['penge', 'salg', 'betaling', 'valuta', 'skejs'],
    char: '\ud83d\udcb2',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  valuta_veksling: {
    keywords: ['penge', 'salg', 'dollar', 'rejse'],
    char: '\ud83d\udcb1',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  copyright: {
    keywords: ['ip', 'licens', 'cirkel', 'loven', 'lov'],
    char: '\xa9\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  registreret: {
    keywords: ['alfabet', 'R', 'cirkel'],
    char: '\xae\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tm: {
    keywords: ['trademark', 'varemærke', 'brand', 'loven', 'lov'],
    char: '\u2122\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  end: {
    keywords: ['ord', 'slut', 'pil'],
    char: '\ud83d\udd1a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  back: {
    keywords: ['pil', 'ord', 'tilbage', 'returnere'],
    char: '\ud83d\udd19',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  on: {
    keywords: ['pil', 'på', 'ord'],
    char: '\ud83d\udd1b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  top: {
    keywords: ['ord', 'blå-firkant'],
    char: '\ud83d\udd1d',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  soon: {
    keywords: ['pil', 'snart', 'ord'],
    char: '\ud83d\udd1c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  kasse_med_kryds: {
    keywords: ['ok', 'enig', 'confirm', 'sort-firkant', 'stemme', 'valg', 'ja', 'krydse_af'],
    char: '\u2611\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  radio_knap: {
    keywords: ['input', 'gammel', 'musik', 'cirkel'],
    char: '\ud83d\udd18',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_cirkel: {
    keywords: ['form', 'rund'],
    char: '\u26aa',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_cirkel: {
    keywords: ['form', 'knap', 'rund'],
    char: '\u26ab',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  rød_cirkel: {
    keywords: ['form', 'fejl', 'fare'],
    char: '\ud83d\udd34',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stor_blå_cirkel: {
    keywords: ['form', 'ikon', 'knap'],
    char: '\ud83d\udd35',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  lille_orange_diamant: {
    keywords: ['form', 'juvel', 'ædelsten'],
    char: '\ud83d\udd38',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  lille_blå_diamant: {
    keywords: ['form', 'juvel', 'ædelsten'],
    char: '\ud83d\udd39',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stor_orange_diamant: {
    keywords: ['form', 'juvel', 'ædelsten'],
    char: '\ud83d\udd36',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  stor_blå_diamant: {
    keywords: ['form', 'juvel', 'ædelsten'],
    char: '\ud83d\udd37',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  lille_rød_trekant: {
    keywords: ['form', 'vejledning', 'op', 'top'],
    char: '\ud83d\udd3a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_lille_firkant: {
    keywords: ['form', 'ikon'],
    char: '\u25aa\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_lille_firkant: {
    keywords: ['form', 'ikon'],
    char: '\u25ab\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_stor_firkant: {
    keywords: ['form', 'ikon', 'knap'],
    char: '\u2b1b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_stor_firkant: {
    keywords: ['form', 'ikon', 'sten', 'knap'],
    char: '\u2b1c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  lille_rød_trekant_nedadvendt: {
    keywords: ['form', 'vejledning', 'bund'],
    char: '\ud83d\udd3b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_medium_firkant: {
    keywords: ['form', 'knap', 'ikon'],
    char: '\u25fc\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_medium_firkant: {
    keywords: ['form', 'sten', 'ikon'],
    char: '\u25fb\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_medium_lille_firkant: {
    keywords: ['ikon', 'form', 'knap'],
    char: '\u25fe',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_medium_lille_firkant: {
    keywords: ['form', 'sten', 'ikon', 'knap'],
    char: '\u25fd',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_firkant_knap: {
    keywords: ['form', 'input', 'ramme'],
    char: '\ud83d\udd32',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hvid_firkant_knap: {
    keywords: ['form', 'input'],
    char: '\ud83d\udd33',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  højtaler: {
    keywords: ['lyd', 'lydstyrke', 'stille', 'broadcast'],
    char: '\ud83d\udd08',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  lyd: {
    keywords: ['lydstyrke', 'højtaler', 'broadcast'],
    char: '\ud83d\udd09',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  høj_lyd: {
    keywords: ['lydstyrke', 'støj', 'noisy', 'højtaler', 'broadcast'],
    char: '\ud83d\udd0a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  mute: {
    keywords: ['lyd', 'lydstyrke', 'stille', 'stum'],
    char: '\ud83d\udd07',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  mega: {
    keywords: ['lyd', 'højtaler', 'lydstyrke'],
    char: '\ud83d\udce3',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  højlydt_højtaler: {
    keywords: ['lydstyrke', 'lyd'],
    char: '\ud83d\udce2',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  klokke: {
    keywords: ['lyd', 'notifikation', 'jul', 'xmas', 'kime'],
    char: '\ud83d\udd14',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ingen_klokke: {
    keywords: ['lyd', 'lydstyrke', 'lydløs', 'notifikation', 'quiet', 'silent'],
    char: '\ud83d\udd15',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  sort_joker: {
    keywords: ['poker', 'spillekort', 'spil', 'spil', 'magi'],
    char: '\ud83c\udccf',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  mahjong: {
    keywords: ['spil', 'spil', 'kinesisk', 'kanji'],
    char: '\ud83c\udc04',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  spar: {
    keywords: ['poker', 'spillekort', 'suits', 'magi'],
    char: '\u2660\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  klør: {
    keywords: ['poker', 'spillekort', 'magi', 'suits'],
    char: '\u2663\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  hjerter: {
    keywords: ['poker', 'spillekort', 'magi', 'suits'],
    char: '\u2665\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  diamanter: {
    keywords: ['poker', 'spillekort', 'magi', 'suits'],
    char: '\u2666\ufe0f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  blomster_spillekort: {
    keywords: ['spil', 'solnedgang', 'rød'],
    char: '\ud83c\udfb4',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  tankeboble: {
    keywords: ['boble', 'skyen', 'tale', 'tænker', 'drøm'],
    char: '\ud83d\udcad',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  højre_taleboks_boble: {
    keywords: ['tale', 'tale', 'tænker', 'sur'],
    char: '\ud83d\uddef',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  taleboks: {
    keywords: ['boble', 'ord', 'besked', 'tale', 'samtale'],
    char: '\ud83d\udcac',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  venstre_tale_boble: {
    keywords: ['ord', 'message', 'tale', 'samtale'],
    char: '\ud83d\udde8',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur1: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd50',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur2: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd51',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur3: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd52',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur4: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd53',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur5: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd54',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur6: {
    keywords: ['tid', 'sen', 'tidlig', 'skema', 'daggry', 'gry'],
    char: '\ud83d\udd55',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur7: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd56',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur8: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd57',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur9: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd58',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur10: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd59',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur11: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd5a',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur12: {
    keywords: ['tid', 'middag', 'midnat', 'midday', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd5b',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur130: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd5c',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur230: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd5d',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur330: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd5e',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur430: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd5f',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur530: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd60',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur630: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd61',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur730: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd62',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur830: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd63',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur930: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd64',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur1030: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd65',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur1130: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd66',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  ur1230: {
    keywords: ['tid', 'sen', 'tidlig', 'skema'],
    char: '\ud83d\udd67',
    fitzpatrick_scale: false,
    category: 'symbols',
  },
  afghanistan: {
    keywords: ['af', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  aland_øerne: {
    keywords: ['\xc5land', 'island', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddfd',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  albanien: {
    keywords: ['al', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  algeriet: {
    keywords: ['dz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde9\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  amerikansk_samoa: {
    keywords: ['amerikansk', 'ws', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  andorra: {
    keywords: ['ad', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  angola: {
    keywords: ['ao', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  anguilla: {
    keywords: ['ai', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  antarktis: {
    keywords: ['aq', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  antigua_bearded: {
    keywords: ['antigua', 'barbuda', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  argentina: {
    keywords: ['ar', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  armenien: {
    keywords: ['am', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  aruba: {
    keywords: ['aw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  australien: {
    keywords: ['au', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  østrig: {
    keywords: ['at', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  aserbajdsjan: {
    keywords: ['az', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bahamas: {
    keywords: ['bs', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bahrain: {
    keywords: ['bh', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bangladesh: {
    keywords: ['bd', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  barbados: {
    keywords: ['bb', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\udde7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  belarus: {
    keywords: ['by', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  belgium: {
    keywords: ['be', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  belize: {
    keywords: ['bz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  benin: {
    keywords: ['bj', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddef',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bermuda: {
    keywords: ['bm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bhutan: {
    keywords: ['bt', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bolivia: {
    keywords: ['bo', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bilibbean_holland: {
    keywords: ['bonaire', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bosnien_hercegovina: {
    keywords: ['bosnien', 'herzegovina', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  botswana: {
    keywords: ['bw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  brasilien: {
    keywords: ['br', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  det_britiske_territorium_i_det_indiske_ocean: {
    keywords: ['britisk', 'indisk', 'havet', 'territory', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  de_britiske_jomfruøer: {
    keywords: ['britisk', 'jomfru', 'øer', 'bvi', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  brunei: {
    keywords: ['bn', 'darussalam', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  bulgarien: {
    keywords: ['bg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  burkina_faso: {
    keywords: ['burkina', 'faso', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  burundi: {
    keywords: ['bi', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cape_verde: {
    keywords: ['cabo', 'verde', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddfb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cambodia: {
    keywords: ['kh', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cameroun: {
    keywords: ['cm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  canada: {
    keywords: ['ca', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  de_kanariske_øer: {
    keywords: ['canary', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cayman_øerne: {
    keywords: ['cayman', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  den_centralafrikanske_republik: {
    keywords: ['central', 'afrika', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tchad: {
    keywords: ['td', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  chile: {
    keywords: ['flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cn: {
    keywords: ['kina', 'kinesisk', 'prc', 'flag', 'land', 'nation', 'banner'],
    char: '\ud83c\udde8\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  juleøerne: {
    keywords: ['jul', 'island', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddfd',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  isal_del_coco: {
    keywords: ['cocos', 'keeling', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  colombia: {
    keywords: ['co', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  comorerne: {
    keywords: ['km', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  congo_brazzaville: {
    keywords: ['congo', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  congo_kinshasa: {
    keywords: ['congo', 'democratic', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  Cook_øerne: {
    keywords: ['kok', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  costa_rica: {
    keywords: ['costa', 'rica', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kroatien: {
    keywords: ['hr', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udded\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cuba: {
    keywords: ['cu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  curacao: {
    keywords: ['cura\xe7ao', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  cypern: {
    keywords: ['cy', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tjekkiet: {
    keywords: ['cz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  danmark: {
    keywords: ['dk', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde9\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  djibouti: {
    keywords: ['dj', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde9\ud83c\uddef',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  dominica: {
    keywords: ['dm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde9\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  den_dominikanske_republik: {
    keywords: ['dominican', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde9\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  ecuador: {
    keywords: ['ec', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  egypten: {
    keywords: ['eg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  el_salvador: {
    keywords: ['el', 'salvador', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddfb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  ækvatorial_guinea: {
    keywords: ['equatorial', 'gn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  eritrea: {
    keywords: ['er', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  estland: {
    keywords: ['ee', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  etiopien: {
    keywords: ['et', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  eu: {
    keywords: ['europæisk', 'union', 'flag', 'banner'],
    char: '\ud83c\uddea\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  falkland_øerne: {
    keywords: ['falkland', 'øer', 'malvinas', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddeb\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  færøerne: {
    keywords: ['faroe', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddeb\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  fiji: {
    keywords: ['fj', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddeb\ud83c\uddef',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  finland: {
    keywords: ['fi', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddeb\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  frankrig: {
    keywords: ['banner', 'flag', 'nation', 'france', 'fransk', 'land'],
    char: '\ud83c\uddeb\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  fransk_guyana: {
    keywords: ['fransk', 'guiana', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  fransk_polynesien: {
    keywords: ['fransk', 'polynesia', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  de_franske_besiddelser_i_det_sydlige_indiske_ocean: {
    keywords: ['fransk', 'sydlig', 'territories', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  gabon: {
    keywords: ['ga', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  gambia: {
    keywords: ['gm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  georgien: {
    keywords: ['ge', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tyskland: {
    keywords: ['german', 'nation', 'flag', 'land', 'banner'],
    char: '\ud83c\udde9\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  ghana: {
    keywords: ['gh', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  gibraltar: {
    keywords: ['gi', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  grækenland: {
    keywords: ['gr', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  grønland: {
    keywords: ['gl', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  grenada: {
    keywords: ['gd', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guadeloupe: {
    keywords: ['gp', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf5',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guam: {
    keywords: ['gu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guatemala: {
    keywords: ['gt', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guernsey: {
    keywords: ['gg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guinea: {
    keywords: ['gn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guinea_bissau: {
    keywords: ['gw', 'bissau', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  guyana: {
    keywords: ['gy', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  haiti: {
    keywords: ['ht', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udded\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  honduras: {
    keywords: ['hn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udded\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  hong_kong: {
    keywords: ['hong', 'kong', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udded\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  ungarn: {
    keywords: ['hu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udded\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  island: {
    keywords: ['is', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  indien: {
    keywords: ['in', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  indonesien: {
    keywords: ['flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  iran: {
    keywords: ['iran,', 'islamic', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  irak: {
    keywords: ['iq', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  irland: {
    keywords: ['ie', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  isle_of_man: {
    keywords: ['isle', 'mand', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  isral: {
    keywords: ['il', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  italien: {
    keywords: ['italy', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddee\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  elfenbenskysten: {
    keywords: ['ivory', 'coast', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  jamaica: {
    keywords: ['jm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddef\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  japan: {
    keywords: ['japansk', 'nation', 'flag', 'land', 'banner'],
    char: '\ud83c\uddef\ud83c\uddf5',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  jersey: {
    keywords: ['je', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddef\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  jordan: {
    keywords: ['jo', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddef\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kasakhstan: {
    keywords: ['kz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kenya: {
    keywords: ['ke', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kiribati: {
    keywords: ['ki', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kosovo: {
    keywords: ['xk', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfd\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kuwait: {
    keywords: ['kw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  kirgisistan: {
    keywords: ['kg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  laos: {
    keywords: ['lao', 'democratic', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  letland: {
    keywords: ['lv', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddfb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  libanon: {
    keywords: ['lb', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\udde7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  lesotho: {
    keywords: ['ls', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  liberia: {
    keywords: ['lr', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  libyen: {
    keywords: ['ly', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  liechtenstein: {
    keywords: ['li', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  litauen: {
    keywords: ['lt', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  luxembourg: {
    keywords: ['lu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  macau: {
    keywords: ['macao', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  makedonia: {
    keywords: ['macedonia,', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  madagaskar: {
    keywords: ['mg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  malawi: {
    keywords: ['mw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  malaysia: {
    keywords: ['my', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  maldiverne: {
    keywords: ['mv', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddfb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mali: {
    keywords: ['ml', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  malta: {
    keywords: ['mt', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  marshall_øerne: {
    keywords: ['marshall', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  martinique: {
    keywords: ['mq', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mauritania: {
    keywords: ['mr', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mauritius: {
    keywords: ['mu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mayotte: {
    keywords: ['yt', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfe\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mexico: {
    keywords: ['mx', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddfd',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mikronesia: {
    keywords: ['micronesia,', 'federated', 'states', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddeb\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  moldova: {
    keywords: ['moldova,', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  monaco: {
    keywords: ['mc', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mongoliet: {
    keywords: ['mn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  montenegro: {
    keywords: ['me', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  montserrat: {
    keywords: ['ms', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  marokko: {
    keywords: ['ma', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  mozambique: {
    keywords: ['mz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  myanmar: {
    keywords: ['mm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  namibia: {
    keywords: ['na', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  nauru: {
    keywords: ['nr', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  nepal: {
    keywords: ['np', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddf5',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  holland: {
    keywords: ['nl', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  ny_kaledonien: {
    keywords: ['new', 'caledonia', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  new_zealand: {
    keywords: ['new', 'zealand', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  nibilagua: {
    keywords: ['ni', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  niger: {
    keywords: ['ne', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  nigeria: {
    keywords: ['flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  niue: {
    keywords: ['nu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  norfolk: {
    keywords: ['norfolk', 'island', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  nordmarianerne: {
    keywords: ['northern', 'mariana', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf2\ud83c\uddf5',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  nordkorea: {
    keywords: ['north', 'korea', 'nation', 'flag', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddf5',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  norge: {
    keywords: ['nej', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf3\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  oman: {
    keywords: ['om_symbol', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf4\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  pakistan: {
    keywords: ['pk', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  palau: {
    keywords: ['pw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  palæstina: {
    keywords: ['palestine', 'palestinian', 'territories', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  panama: {
    keywords: ['pa', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  papua_ny_guinea: {
    keywords: ['papua', 'new', 'guinea', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  paraguay: {
    keywords: ['py', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  peru: {
    keywords: ['pe', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  filippinerne: {
    keywords: ['ph', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  pitcairn_øerne: {
    keywords: ['pitcairn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  polen: {
    keywords: ['pl', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  portugal: {
    keywords: ['pt', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  puerto_rico: {
    keywords: ['puerto', 'rico', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  qatar: {
    keywords: ['qa', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf6\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  reunion: {
    keywords: ['r\xe9union', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf7\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  romanien: {
    keywords: ['ro', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf7\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  rusland: {
    keywords: ['russian', 'federation', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf7\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  rwanda: {
    keywords: ['rw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf7\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  saint_barthelemy: {
    keywords: ['saint', 'barth\xe9lemy', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde7\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sankt_helena: {
    keywords: ['saint', 'helena', 'ascension', 'tristan', 'cunha', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  saint_kitts_nevis: {
    keywords: ['saint', 'kitts', 'nevis', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  saint_lucia: {
    keywords: ['saint', 'lucia', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  saint_pierre_miquelon: {
    keywords: ['saint', 'pierre', 'miquelon', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf5\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  saint_vincent_grenadines: {
    keywords: ['saint', 'vincent', 'grenadines', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  samoa: {
    keywords: ['ws', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfc\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  san_marino: {
    keywords: ['san', 'marino', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sao_tome_principe: {
    keywords: ['sao', 'tome', 'principe', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  saudi_arabien: {
    keywords: ['flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  senegal: {
    keywords: ['sn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  serbien: {
    keywords: ['rs', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf7\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  seychellerne: {
    keywords: ['sc', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sierra_leone: {
    keywords: ['sierra', 'leone', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  singapore: {
    keywords: ['sg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sankt_martin: {
    keywords: ['sint', 'maarten', 'dutch', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddfd',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  slovakiet: {
    keywords: ['sk', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  slovenien: {
    keywords: ['si', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  solomon_øerne: {
    keywords: ['solomon', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\udde7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  somalien: {
    keywords: ['so', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sydafrika: {
    keywords: ['syd', 'afrika', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddff\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  det_sydlige_georgien_og_de_sydlige_sandwich_øer: {
    keywords: ['syd', 'georgia', 'sandwich', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddec\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sydkorea: {
    keywords: ['syd', 'korea', 'nation', 'flag', 'land', 'banner'],
    char: '\ud83c\uddf0\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sydsudan: {
    keywords: ['syd', 'sd', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  spanien: {
    keywords: ['spain', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sri_lanka: {
    keywords: ['sri', 'lanka', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf1\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sudan: {
    keywords: ['sd', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\udde9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  surinam: {
    keywords: ['sr', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  swaziland: {
    keywords: ['sz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  sverige: {
    keywords: ['se', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  schweiz: {
    keywords: ['ch', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde8\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  syrien: {
    keywords: ['syrian', 'arab', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf8\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  taiwan: {
    keywords: ['tw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tadsjikistan: {
    keywords: ['tj', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddef',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tanzania: {
    keywords: ['tanzania,', 'united', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  thailand: {
    keywords: ['th', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  Østtimor: {
    keywords: ['timor', 'leste', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf1',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  togo: {
    keywords: ['tg', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tokelau: {
    keywords: ['tk', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf0',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tonga: {
    keywords: ['to', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf4',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  trinidad_og_tobago: {
    keywords: ['trinifar', 'tobago', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf9',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tunesien: {
    keywords: ['tn', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tyrkiet: {
    keywords: ['turkey', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  turkmenistan: {
    keywords: ['flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  turks_og_Caicosøerne_øerne: {
    keywords: ['turks', 'caicos', 'øer', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\udde8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  tuvalu: {
    keywords: ['flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddf9\ud83c\uddfb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  uganda: {
    keywords: ['ug', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfa\ud83c\uddec',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  ukraine: {
    keywords: ['ua', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfa\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  forenede_arabiske_emirater: {
    keywords: ['united', 'arab', 'emirates', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\udde6\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  storbritannien: {
    keywords: [
      'united',
      'kingdom',
      'great',
      'britain',
      'northern',
      'irland',
      'flag',
      'nation',
      'land',
      'banner',
      'britisk',
      'UK',
      'english',
      'england',
      'union jack',
    ],
    char: '\ud83c\uddec\ud83c\udde7',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  england: {
    keywords: ['flag', 'english'],
    char: '\ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc65\udb40\udc6e\udb40\udc67\udb40\udc7f',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  skotland: {
    keywords: ['flag', 'scottish'],
    char: '\ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc73\udb40\udc63\udb40\udc74\udb40\udc7f',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  wales: {
    keywords: ['flag', 'welsh'],
    char: '\ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc77\udb40\udc6c\udb40\udc73\udb40\udc7f',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  usa: {
    keywords: ['united', 'states', 'america', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfa\ud83c\uddf8',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  jomfruøerne: {
    keywords: ['jomfru', 'øer', 'us', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\uddee',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  uruguay: {
    keywords: ['uy', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfa\ud83c\uddfe',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  uzbekistan: {
    keywords: ['uz', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfa\ud83c\uddff',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  vanuatu: {
    keywords: ['vu', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\uddfa',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  vatikanstaten: {
    keywords: ['vatican', 'by', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\udde6',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  venezuela: {
    keywords: ['ve', 'bolivarian', 'republik', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  vietnam: {
    keywords: ['viet', 'nam', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfb\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  wallis_og_futuna: {
    keywords: ['wallis', 'futuna', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfc\ud83c\uddeb',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  vestsahara: {
    keywords: ['vest', 'sahara', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddea\ud83c\udded',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  yemen: {
    keywords: ['ye', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddfe\ud83c\uddea',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  zambia: {
    keywords: ['zm', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddff\ud83c\uddf2',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  zimbabwe: {
    keywords: ['zw', 'flag', 'nation', 'land', 'banner'],
    char: '\ud83c\uddff\ud83c\uddfc',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  forenede_nationer: {
    keywords: ['fn', 'flag', 'banner'],
    char: '\ud83c\uddfa\ud83c\uddf3',
    fitzpatrick_scale: false,
    category: 'flags',
  },
  piratflag: {
    keywords: ['skull', 'crossbones', 'flag', 'banner'],
    char: '\ud83c\udff4\u200d\u2620\ufe0f',
    fitzpatrick_scale: false,
    category: 'flags',
  },
});
