















































import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import NewDocument from './NewDocument.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { documentTypes } from '../../../shared/enums/documentTypes';

export default {
  components: { AulaButton, NewDocument, Icon },
  beforeRouteLeave(to, from, next) {
    const canGoNextRoute = this.isEditing === false;
    if (!canGoNextRoute) {
      this.showWarningModal();
    }
    next(canGoNextRoute);
  },
  props: {
    type: { type: String, default: null },
    id: { type: Number, default: null },
    isEditMode: { type: Boolean, default: false },
    includesDeleted: { type: Boolean, default: false },
    isRevision: { type: Boolean, default: false },
  },
  emits: ['save', 'hidden', 'toggleEdit'],
  data() {
    return {
      isShown: false,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      document: types.GET_DOCUMENTS_LIVE,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    title() {
      if (this.isRevision) {
        return this.$options.filters.fromTextKey('DOCUMENTS_REVISION_TITLE');
      }
      if (this.isEditMode) {
        return this.$options.filters.fromTextKey('DOCUMENTS_EDIT_TITLE');
      }
      if (!this.isCreating) {
        return this.$options.filters.fromTextKey('DOCUMENTS_FILE_IN_READ_ONLY_MODE_TITLE');
      }
      switch (this.type) {
        case documentTypes.EXTERNAL:
          return this.$options.filters.fromTextKey('DOCUMENTS_IMPORT_FILE_TITLE');
        case documentTypes.NOTE:
          return this.$options.filters.fromTextKey('DOCUMENTS_NEW_NOTE_TITLE');
        default:
          return this.$options.filters.fromTextKey('DOCUMENTS_NEW_TITLE');
      }
    },
    canEdit() {
      if (this.includesDeleted) {
        return false;
      }
      return !this.isEditMode && this.id && this.document.canEdit;
    },
    isCreating() {
      return !this.id;
    },
    canSelectTemplate() {
      return this.isCreating && this.type === documentTypes.RICHDOCUMENT;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      discardDocument: types.ACTION_DISCARD_DOCUMENT,
    }),
    initialize() {
      this.isShown = true;
    },
    onChangeShown(isShown) {
      if (isShown) {
        return;
      }
      if (this.isEditing) {
        this.showWarningModal();
        return;
      }
      this.discard();
    },
    discard() {
      this.isShown = false;
      this.isEditing = false;
      this.discardDocument();
      this.$refs.warningModal.hide();
    },
    onHidden() {
      this.$emit('hidden');
    },
    onSave() {
      this.isEditing = false;
      this.$emit('save');
    },
    toggleEdit() {
      this.$emit('toggleEdit', this.document);
    },
    handleDocumentChanged() {
      this.isEditing = true;
    },
    showWarningModal() {
      this.$refs.warningModal.show();
    },
  },
};
