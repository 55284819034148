










import { PropType } from 'vue';
import { MediaModel } from '../models/media.model';
import { mediaTypes } from '../enums/mediaTypes';
import VideoThumbnail from '../PageFragments/Media/VideoThumbnail.vue';
import ImageThumbnail from '../PageFragments/Media/ImageThumbnail.vue';
import SoundThumbnail from '../PageFragments/Media/SoundThumbnail.vue';

export default {
  components: { SoundThumbnail, ImageThumbnail, VideoThumbnail },
  props: {
    media: { type: Object as PropType<MediaModel>, required: true },
    overlayText: { type: String, default: '' },
  },
  computed: {
    mediaTypes() {
      return mediaTypes;
    },
    hasOverlay() {
      return this.overlayText.length > 0;
    },
  },
};
