




























import AulaButton from '../../../shared/components/AulaButton.vue';
import debounce from 'lodash/debounce';
import { mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';

export default {
  components: { AulaButton },
  props: {
    value: { type: String, required: true },
    maxLength: { type: Number, default: 10000 },
    placeholder: { type: String, default: '' },
    loading: { type: Boolean, default: false },
  },
  emits: ['input', 'submit'],
  computed: {
    isDisabled() {
      return this.value.length === 0 || this.loading;
    },
    updateIsWriting() {
      return debounce(function (content) {
        this.updateWritingComment(content != null);
      }, 300);
    },
  },
  methods: {
    ...mapMutations({
      updateWritingComment: types.MUTATE_WRITING_COMMENT,
    }),
    handleInput(content) {
      this.$emit('input', content);
      this.updateIsWriting(content);
    },
    emitSubmit() {
      if (this.isDisabled) {
        return;
      }
      this.updateIsWriting.cancel();
      this.updateWritingComment(false);
      this.$emit('submit');
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.commentInput.focus();
      });
    },
    handleKeyboardSubmit(event: KeyboardEvent) {
      if (event.shiftKey) {
        return;
      }
      event.preventDefault();
      this.emitSubmit();
    },
  },
};
