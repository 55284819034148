









































import { PropType } from 'vue';
import { SimpleGroupModel } from '../../../shared/models/simpleGroup.model';
import AulaPopover from '../../../shared/components/AulaPopover.vue';
import uniqueId from 'lodash/uniqueId';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { permissionEnum } from '../../../shared/enums/permissionEnum';

export default {
  components: { AulaPopover },
  props: {
    group: { type: Object as PropType<SimpleGroupModel>, required: true },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      groupMemberships: types.GET_GROUP_MEMBERSHIPS_LIGHT,
      userGroups: types.GET_USER_GROUPS,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      isMobile: types.GET_IS_MOBILE,
    }),
    groupPopoverContainer() {
      return `groupPopover${this.buttonId}`;
    },
    label() {
      if (this.group.portalRoles == null) {
        return this.group.name;
      }
      const roles = this.group.portalRoles.map(role => {
        switch (role) {
          case portalRoles.EMPLOYEE:
            return this.$options.filters.fromTextKey('GROUP_LABEL_TYPE_EMPLOYEE');
          case portalRoles.GUARDIAN:
            return this.$options.filters.fromTextKey('GROUP_LABEL_TYPE_GUARDIAN');
          case portalRoles.CHILD:
            return this.$options.filters.fromTextKey('GROUP_LABEL_TYPE_CHILD');
        }
      });
      return `${this.group.name} (${roles.join(' - ')})`;
    },
    canViewGroupMembers() {
      if (!this.group.allowMembersToBeShown) {
        return false;
      }

      const isMember = this.userGroups.some(userGroup => userGroup.id === this.group.id);
      const hasGroupDistribution = this.hasPermissionOnInstitution(
        permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
        this.group.institutionCode
      );
      return isMember || hasGroupDistribution;
    },
    buttonId() {
      return `popover-button-${uniqueId()}`;
    },
    children() {
      const children = this.groupMemberships.filter(membership => membership.portalRole == portalRoles.CHILD);
      return children.length > 0 ? children[0].institutionProfiles : [];
    },
    guardians() {
      const guardians = this.groupMemberships.filter(membership => membership.portalRole == portalRoles.GUARDIAN);
      return guardians.length > 0 ? guardians[0].institutionProfiles : [];
    },
    employees() {
      const employees = this.groupMemberships.filter(membership => membership.portalRole == portalRoles.EMPLOYEE);
      return employees.length > 0 ? employees[0].institutionProfiles : [];
    },
  },
  methods: {
    ...mapActions({
      loadGroupMemberships: types.LOAD_GROUP_MEMBERSHIPS_LIGHT,
    }),
    async loadGroupMembers() {
      this.isLoading = true;
      const params = {
        groupId: this.group.id,
        portalRoles: this.group.portalRoles,
      };
      this.$forceUpdate();
      await this.loadGroupMemberships(params);
      this.$forceUpdate();
      this.isLoading = false;
    },
  },
};
