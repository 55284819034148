<template>
  <div class="timepicker-container">
    <vue-timepicker
      v-bind="{ ...$attrs, ...$props }"
      ref="vueTimepicker"
      :value="timepickerValue"
      :hour-range="hourRange"
      :minute-range="minuteRange"
      @open="handlePickerOpen"
      @close="emitSelectedTime"
      @input="checkForStartAndEnd"
    />
    <Icon :name="iconClassEnum.WATCH" class="icon" :class="{ disabled: disabled }" />
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Icon from './Icon';
import { iconClassEnum } from '../enums/iconClassEnum';

export default {
  components: {
    Icon,
    VueTimepicker,
  },
  inheritAttrs: false,
  props: {
    value: {
      required: true,
      type: null,
    },
    start: { type: String, default: '' },
    end: { type: String, default: '' },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    scrollToHour: { type: Number, default: null },
  },
  data() {
    return {
      timepickerValue: '',
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    hourRange() {
      if (this.start && this.end) {
        return [[this.startHour, this.endHour]];
      }
      return undefined;
    },
    minuteRange() {
      if (this.start && this.end && this.timepickerValue) {
        const selectedTimeArr = this.timepickerValue.split(':');
        const hour = selectedTimeArr[0];
        if (hour === this.startHour) {
          return [[this.startMinute, 59]];
        } else if (hour === this.endHour) {
          return [[0, this.endMinute]];
        }
      }
      return undefined;
    },
    startHour() {
      if (this.start) {
        const startArr = this.start.split(':');
        return startArr[0];
      }
      return '';
    },
    startMinute() {
      if (this.start) {
        const startArr = this.start.split(':');
        return startArr[1];
      }
      return '';
    },
    endHour() {
      if (this.end) {
        const endArr = this.end.split(':');
        return endArr[0];
      }
      return '';
    },
    endMinute() {
      if (this.end) {
        const endArr = this.end.split(':');
        return endArr[1];
      }
      return '';
    },
  },
  watch: {
    value() {
      if (this.value != null) {
        this.timepickerValue = this.value;
      }

      if (this.timepickerValue === '') {
        this.$refs.vueTimepicker.clearTime();
      }
    },
  },
  mounted() {
    this.timepickerValue = this.value || '';
  },
  methods: {
    emitSelectedTime(selectedTime) {
      this.$emit('change', selectedTime);
    },
    checkForStartAndEnd(selectedTime) {
      if (selectedTime.includes('mm')) {
        selectedTime = selectedTime.replace('mm', '00');
      }
      this.timepickerValue = selectedTime;
      if (!selectedTime.includes('HH') && !selectedTime.includes('mm')) {
        this.checkIfSelectedTimeIsOutsideOpeningHours(selectedTime);
      }
      this.$emit('input', this.timepickerValue);
    },
    checkIfSelectedTimeIsOutsideOpeningHours(selectedTime) {
      if (this.start && this.end) {
        const selectedTimeArr = selectedTime.split(':');
        const hour = selectedTimeArr[0];
        const minute = selectedTimeArr[1];
        if (hour === this.endHour && minute > this.endMinute) {
          this.timepickerValue = this.endHour + ':' + this.endMinute;
        } else if (hour === this.startHour && minute < this.startMinute) {
          this.timepickerValue = this.startHour + ':' + this.startMinute;
        }
      }
    },
    handlePickerOpen() {
      this.$nextTick(() => {
        this.scrollToDefaultTime();
      });
    },
    scrollToDefaultTime() {
      if (!this.scrollToHour || this.value) {
        return;
      }
      const defaultHour = this.scrollToHour.toString().padStart(2, '0');
      const hourContainerElement = this.$el.querySelector('.select-list ul.hours');
      const defaultHourElement = this.$el.querySelector(`.select-list ul.hours li[data-key="${defaultHour}"]`);
      hourContainerElement.scrollTop = defaultHourElement?.offsetTop || 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.timepicker-container {
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &:focus-within {
    outline: 1px solid var(--color-primary-dark);
  }
  /deep/ .vue__time-picker .dropdown ul li:not([disabled]).active {
    background-color: var(--active-color, var(--color-primary-darker));
    &:hover,
    &:focus {
      background-color: var(--active-color, var(--color-primary-darker));
    }
  }
  .time-picker {
    width: 100%;
    border-radius: 5px;
  }
}

.icon {
  position: absolute;
  right: 16px;
}

.disabled {
  color: var(--color-dark-grey);
}
</style>
