







































































import { PropType } from 'vue';
import { CommentModel } from '../../../shared/models/comment.model';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import * as dateUtil from '../../../shared/utils/dateUtil.js';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import capitalize from 'lodash/capitalize';
import { mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';
import CommentTextarea from './CommentTextarea.vue';
import { commentService } from '../../../shared/services/api/comment.service';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { DeleteCommentRequest } from '../../../shared/models/requests/deleteComment.request';
import { ReportCommentRequest } from '../../../shared/models/requests/reportComment.request';
import ContactAvatar from '../contacts/ContactAvatar.vue';

export default {
  components: { ContactAvatar, AulaButton, CommentTextarea, Icon },
  props: {
    comment: { type: Object as PropType<CommentModel>, required: true },
    showReportedContent: { type: Boolean, default: false },
    highlighted: { type: Boolean, default: false },
  },
  data() {
    return {
      isEditing: false,
      isDeleted: false,
      isReported: false,
      originalContent: '',
      content: '',
      reportReason: '',
      reportedContentWarning: null,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
    }),
    canViewProfile() {
      return !this.showReportedContent;
    },
    creator() {
      if (this.isDeleted || (this.isReported && !this.showReportedContent)) {
        return null;
      }
      return this.comment.creator;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    commentDateTime() {
      const commentDate = this.comment.updatedAt || this.comment.createdAt;
      const date = dateUtil.getRelativeDateTime(commentDate);
      let suffix = '';
      if (this.comment.updatedAt) {
        suffix = `(${this.$options.filters.fromTextKey('EDITED')})`;
      }
      if (this.comment.isReported) {
        suffix = `(${this.$options.filters.fromTextKey('REPORTED')})`;
      }
      return capitalize(`${date} ${suffix}`);
    },
    commentDateTimeTooltip() {
      const commentDate = this.comment.updatedAt || this.comment.createdAt;
      return capitalize(dateUtil.format(commentDate, dateFormatEnum.FULL_DATE));
    },
    canEdit() {
      return this.institutions.some(
        institution => institution.institutionProfileId === this.comment.creator?.id && !institution.communicationBlock
      );
    },
    canPerformActions() {
      return !this.isDeleted && !this.isReported && (this.comment.canDelete || this.comment.canReport);
    },
    canViewContent() {
      return !this.isDeleted && (!this.isReported || this.showReportedContent) && !this.isEditing;
    },
    isValidReport() {
      return this.reportedContentWarning == null;
    },
  },
  mounted() {
    this.originalContent = this.comment.content;
    this.isDeleted = this.comment.isDeleted;
    this.isReported = this.comment.isReported;
  },
  methods: {
    ...mapMutations({
      setSuccessText: types.MUTATE_SUCCESS_TEXT,
    }),
    handleEditClicked() {
      this.isEditing = true;
      this.content = this.originalContent;
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    handleDeleteClicked() {
      this.$refs.deletionModal.show();
    },
    handleReportClicked() {
      this.$refs.reportContentModal.show();
    },
    confirmDeletion() {
      this.$refs.deletionModal.hide();
      this.deleteComment();
      this.isDeleted = true;
    },
    confirmReporting() {
      this.$refs.reportContentModal.hide();
      this.reportComment();
      this.isReported = true;
    },
    handleSubmitClicked() {
      this.updateComment();
      this.originalContent = this.content;
      this.isEditing = false;
    },
    async updateComment() {
      const params = {
        commentId: this.comment.id,
        content: this.content,
      };
      await commentService.updateComment(params);
      this.setSuccessText('COMMENTS_COMMENT_IS_UPDATED');
    },
    async deleteComment() {
      const payload: DeleteCommentRequest = {
        commentId: this.comment.id,
      };
      await commentService.deleteComment(payload);
      this.setSuccessText('COMMENTS_COMMENT_IS_DELETED');
    },
    async reportComment() {
      const payload: ReportCommentRequest = {
        commentId: this.comment.id,
        reportReason: this.reportReason,
      };
      await commentService.reportComment(payload);
      this.setSuccessText('COMMENTS_COMMENT_IS_REPORTED');
    },
    reset() {
      this.isEditing = false;
      this.content = this.originalContent;
    },
    handleReportedContentInput() {
      switch (true) {
        case this.reportReason.length > 0 && this.reportReason.length < 10:
          this.reportedContentWarning = this.$options.filters.fromTextKey(
            'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_SHORT'
          );
          break;
        case this.reportReason.length > 255:
          this.reportedContentWarning = this.$options.filters.fromTextKey(
            'REPORTED_CONTENT_REASON_LENGTH_WARNING_TOO_LONG'
          );
          break;
        default:
          this.reportedContentWarning = null;
      }
    },
  },
};
