import tinymce from 'tinymce';

const maxTimeout = 2000;
const intervalCount = 50;
const waitForContainer = editor => {
  let waited = 0;
  let returnValue = false;
  return new Promise(resolve => {
    const intervalKey = setInterval(() => {
      returnValue = editor.container != null;

      if (maxTimeout < waited || returnValue) {
        resolve(returnValue);
        clearInterval(intervalKey);
      }

      waited += intervalCount;
    }, intervalCount);
  });
};

tinymce.PluginManager.add('foldFonts', function (editor) {
  editor.ui.registry.addButton('foldFonts', {
    icon: 'letters',
    tooltip: 'Skrift',
    onAction: function () {
      editor.execCommand('foldFonts');
    },
    async onSetup() {
      const isContainerRendered = await waitForContainer(editor);
      if (isContainerRendered) {
        const foldFontsButton = editor.container.querySelector(`button[aria-label="Skrift"]`);
        foldFontsButton.classList.add('fold-fonts');
      }
    },
  });
});
