import { store } from '../../store/store';
import { permissionEnum } from '../../../shared/enums/permissionEnum';

export abstract class Validator {
  /**
   * validates all passed rules (validators)
   * @param rules
   *    array of specific validator functions,
   *    each function have model as a parameter and returns TRUE (is valid) or FALSE
   *    @param {{content: *}} parameters - object which should contain all properties which will be validated -if not taken from model
   *    this should be temprary until model will be moved to component model class (like newPost)
   * @returns {boolean} - TRUE - if model isValid for all validators
   */
  // eslint-disable-next-line no-unused-vars
  public validateRules(rules: ((parameters?: any) => boolean)[], parameters?: any): boolean {
    return rules.reduce((isValid, rule) => rule(parameters) && isValid, true);
  }

  /**
   * checks if string or array is empty
   * @param  {string | Array} value
   * @returns {boolean} true - if value is empty
   */
  public static isEmpty(value: string | any[]) {
    if (!value) {
      return true;
    }
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !value.trim();
  }

  /**
   * checks if two arrays have equal sizes
   * @param  {Array} array1
   * @param  {Array} array2
   * @returns {boolean} true - if arrays' sizes are equal
   */
  public static arraysHaveEqualSizes(array1: any[], array2: any[]) {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      return false;
    }

    if (Validator.isEmpty(array1) && Validator.isEmpty(array2)) {
      return true;
    }

    if (Validator.isEmpty(array1) || Validator.isEmpty(array2)) {
      return false;
    }

    return array1.length === array2.length;
  }

  /**
   * checks if string exceeds maxLength
   * @param  {string } text
   * @returns {boolean} true - if text has more than maxLength chars
   */
  public static exceedsMaxLength(text: string, maxLength: number) {
    return text && text.length > maxLength;
  }

  /**
   * checks if proper permissions are set
   * @param  {permissionEnum[] } permissions
   * @returns {boolean} true - if at least one accesscontrol permission is set
   */
  public static hasAnyPermission(permissions: permissionEnum[]): boolean {
    const hasPermission = store.getters['accesscontrol/HAS_PERMISSION'];
    return permissions.some(permission => hasPermission(permission));
  }

  public static isValidEmail(email: string): boolean {
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
