<template>
  <b-btn v-bind="{ ...$props, ...$attrs }" variant="link" :aria-label="ariaLabel" v-on="$listeners">
    <i class="p-1" :class="iconClass" />
  </b-btn>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
      validator(value) {
        return ['delete', 'edit'].includes(value);
      },
    },
  },
  computed: {
    iconClass() {
      switch (this.icon) {
        case 'delete':
          return 'icon-Aula_bin';
        case 'edit':
          return 'icon-Aula_edit_pen';
        default:
          return '';
      }
    },
    ariaLabel() {
      switch (this.icon) {
        case 'delete':
          return this.$options.filters.fromTextKey('ARIA_LABEL_DELETE');
        case 'edit':
          return this.$options.filters.fromTextKey('ARIA_LABEL_EDIT');
        default:
          return '';
      }
    },
  },
};
</script>
